import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AuthContext } from '../../Context/AuthContext';
import {
  getDatabase,
  onValue,
  ref,
  serverTimestamp,
  set,
} from 'firebase/database';
import LoadingModal from '../../Common/LoadingModal';

const PayToBankModal = ({
  showPayToBankModal,
  setShowPayToBankModal,
  availableBalance,
}) => {
  const [toWithdraw, setToWithdraw] = useState(0);
  const { currentUser } = useContext(AuthContext);
  const [accountInfo, setAccountInfo] = useState({});
  const [show, setShow] = useState(false);

  const handleWithdraw = async () => {
    const db = await getDatabase();
    await setShow(true);
    const id = currentUser.userNumber.toString() + new Date().getTime();
    await set(ref(db, 'withdraw/' + id), {
      id: id,
      account: accountInfo,
      amount: parseInt(toWithdraw),
      shopTag: currentUser.shopTag,
      status: 0,
      timeStamp: serverTimestamp(Date.now()),
      userId: currentUser.id,
    });
    await setToWithdraw(0);
    await setShow(false);
    await setShowPayToBankModal(false);
  };

  useEffect(() => {
    const db = getDatabase();
    const userRef = ref(db, 'bank_account/' + currentUser.id);
    onValue(userRef, async (snapshot) => {
      const data = await snapshot.val();
      if (data) {
        await setAccountInfo(data);
        await console.log(accountInfo);
      } else {
        // await alert('Please add your bank account first');
      }
    });
  }, []);
  return (
    <Modal show={showPayToBankModal} size="lg">
      <Modal.Body>
        <div>PayToBankModal</div>
        <h4>Available Balance: {availableBalance}</h4>
        <label>Withdraw amount (Min:100)</label>
        <input
          type="number"
          className="d-block form-control mb-3"
          placeholder="Withdraw Amount (Min: 1000)"
          value={toWithdraw}
          onChange={(e) => setToWithdraw(e.target.value)}
        ></input>
        <div style={{ float: 'right' }}>
          <Button
            className="btn btn-danger"
            onClick={() => setShowPayToBankModal(false)}
          >
            Close
          </Button>
          {(toWithdraw < 100 ||
            availableBalance < 100 ||
            toWithdraw > availableBalance) && (
            <Button className="btn btn-secondary ms-3">Withdraw</Button>
          )}
          {toWithdraw >= 100 &&
            availableBalance >= 100 &&
            toWithdraw <= availableBalance && (
              <Button onClick={handleWithdraw} className="btn btn-primary ms-3">
                Withdraw
              </Button>
            )}
        </div>
      </Modal.Body>
      <LoadingModal show={show}></LoadingModal>
    </Modal>
  );
};

export default PayToBankModal;
