import React, { useContext, useEffect, useState } from 'react';
import './CommonDrawer.css';
import {
  ref as dbRef,
  getDatabase,
  set,
  onValue,
  get,
  query,
  orderByChild,
  equalTo,
  limitToFirst,
  limitToLast,
} from 'firebase/database';
import { AuthContext } from '../../Context/AuthContext';
import ProductCard from '../ProductCard';

function Favourites() {
  const { currentUser } = useContext(AuthContext);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const lovedRef = dbRef(db, '/loved/' + currentUser.id);
    onValue(lovedRef, (snapshot) => {
      const data = snapshot.val();
      let itArr = [];
      if (data) {
        Object.values(data).map(async (it) => {
          const itemRef = dbRef(db, '/regular_item/' + it);
          const snapshot = await get(itemRef);
          const itemData = snapshot.val();
          itArr = [...itArr, itemData];
          setItems(itArr);
        });
      }
    });
  }, []);

  return (
    <div className="container mt-3">
      <div>
        {items && (
          <div className="row">
            {items.map((item) => (
              <div className="col-6 col-md-4 col-lg-2">
                <ProductCard className="" item={item} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Favourites;

// const setLovedArray = async (data) => {
//   const db = getDatabase();
//   //setLoved(Object.values(data));
//   const dt = Object.values(data);
//   let itArr = [];
//   dt.map(async (it) => {
//     const itemRef = await dbRef(db, '/regular_item/' + it);
//     console.log('check1');
//     await onValue(itemRef, async (snapshot) => {
//       console.log('check2');
//       const itemData = await snapshot.val();
//       itArr.push(Object.values(itemData));
//     });
//   });
//   await setItems(itArr);
//   await console.log(items);
// };
