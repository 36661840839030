import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useNavigate } from 'react-router-dom';
import './NavBar.css';
import more from '../../assets/images/more (2).png';
import login from '../../assets/images/login.png';
import signup from '../../assets/images/add-user.png';
import { useContext } from 'react';
import { AuthContext } from '../Context/AuthContext';
const Nav2Bar = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <Navbar className="bg-common">
      <ul className="nav2bar-list">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">
            <img
              className="nav-logo mx-1"
              src={require('../../assets/images/icon/icon.png')}
              alt="Im"
            />
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/items">
            Items
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/shops">
            Shops
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/auctions">
            Auctions
          </Link>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
          >
            <img
              className=""
              style={{ width: '20px' }}
              src={more}
              alt="manage"
            />
          </a>
        </li>
        {currentUser ? (
          <img
            src={currentUser.photoUrl}
            alt="user"
            className="nav-user-img ms-5"
          />
        ) : (
          <>
            <Link className="ms-3" to="/login" type="submit">
              <img
                style={{ width: '20px' }}
                src={login}
                alt="user"
                className=""
              />
            </Link>
            <Link className="ms-2" to="/signup" type="submit">
              <img
                style={{ width: '20px' }}
                src={signup}
                alt="user"
                className=""
              />
            </Link>
          </>
        )}
      </ul>
    </Navbar>
  );
};

export default Nav2Bar;
