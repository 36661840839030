import React, { useContext, useEffect, useState } from 'react';

import toys from '../../assets/svgs/toys.svg';
import location from '../../assets/images/location.png';
import card from '../../assets/svgs/card.svg';
import box from '../../assets/images/box.png';
import note from '../../assets/images/note.png';
import $ from 'jquery';
import { AuthContext } from '../Context/AuthContext';
import {
  getDatabase,
  ref,
  serverTimestamp,
  set,
  update,
} from 'firebase/database';
import Modal from 'react-bootstrap/Modal';
//Paystack
import { PaystackButton } from 'react-paystack';
import { usePaystackPayment } from 'react-paystack';
import './OrderProductPage.css';
import { useNavigate } from 'react-router-dom';
import { AuctionDetailsContext } from '../Context/AuctionDetailsContext';
// { showSnipItPage, setShowSnipItPage, updatedAmount }
function SnipItPage() {
  const [auctionDetails, setAuctionDetails] = useContext(AuctionDetailsContext);
  const { currentUser } = useContext(AuthContext);
  const [deliveryOpt, setDeliveryOpt] = useState({});
  // const [pepPaxiOpt, setPepPaxiOpt] = useState('option_1');
  const [pepPaxiPoint, setPepPaxiPoint] = useState('');
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [subMitButtonStatus, setSubMitButtonStatus] = useState(false);
  const [paystackReference, setPaystackReference] = useState('');
  const navigate = useNavigate();

  //Variables
  const dOpt = {
    option_1: 0,
    option_2: 1,
    option_3: 2,
    option_4: 3,
    option_5: 4,
  };
  //Paystack
  const publicKey = 'pk_test_e68d9ee6b01bd36414214d13e6490cec0f815245';
  const amount = 1000000;

  const config = {
    reference: new Date().getTime().toString(),
    email: 'user@example.com',
    currency: 'ZAR',
    amount:
      deliveryOpt.fee +
      auctionDetails.currentPrice +
      Math.floor((auctionDetails.currentPrice * 15) / 100) * 100,

    publicKey: 'pk_test_e68d9ee6b01bd36414214d13e6490cec0f815245',
  };
  //-----------------------Yoco Payment-------------------------------------------------------------------------------------

  var yoco = new window.YocoSDK({
    publicKey: 'pk_test_09aeb28bkwr39jjbd634',
  });
  const payWithYoco = function () {
    yoco.showPopup({
      amountInCents:
        (deliveryOpt.fee +
          auctionDetails.currentPrice +
          Math.floor((auctionDetails.currentPrice * 15) / 100)) *
        100,
      currency: 'ZAR',
      name: 'Your Store or Product',
      description: 'Awesome description',
      callback: function (result) {
        // This function returns a token that your server can use to capture a payment
        if (result.error) {
          const errorMessage = result.error.message;
          alert('error occured: ' + errorMessage);
          onClose();
        } else {
          onSuccess(result.id);
          // alert('card successfully tokenised: ' + result.id);
        }
        // In a real integration - you would now pass this chargeToken back to your
        // server along with the order/basket that the customer has purchased.
      },
    });
  };
  //---------------------------Yoco Payment---------------------------------------------------------------------------------

  const handleOrderCompletion = async (ordDt) => {
    const db = getDatabase();
    await set(ref(db, 'order/' + ordDt.id), {
      ...ordDt,
      timeStamp: serverTimestamp(Date.now()),
    });
    await update(ref(db, 'auction_item/' + auctionDetails.id), {
      forceOver: true,
    });
    await setAuctionDetails({ ...auctionDetails, forceOver: true });
    await navigate('/myorders');
  };
  const onSuccess = (reference) => {
    // setPaystackReference(reference.reference);
    const ordAdd = {
      ...orderAddress,
      fullAddress: pepPaxiPoint,
    };
    const ordDt = {
      address: ordAdd,
      ...orderData,
      deliveryOption: deliveryOpt,
      // pexiOption: pepPaxiOpt,
      paymentReference: reference,
    };

    handleOrderCompletion(ordDt);
  };
  const onClose = () => {
    alert('Payment was not completed, window closed.');
  };
  useEffect(() => {
    console.log(auctionDetails);
    if (deliveryOpt.optionName === 'Aramex') {
      setPepPaxiPoint('');
      // setPepPaxiOpt('');
      setOrderAddress({
        ...orderAddress,
        name: '',
        area: '',
        email: '',
        postCode: '',
        storeName: '',
        street: '',
      });
    } else if (deliveryOpt.optionName === 'PepPaxi') {
      // setPepPaxiOpt('');
      setOrderAddress({
        ...orderAddress,
        storeName: '',
      });
    } else if (deliveryOpt.optionName === 'PostNet') {
      setOrderAddress({
        ...orderAddress,
        name: '',
        area: '',
        email: '',
        postCode: '',
        storeName: '',
        street: '',
      });
    } else if (deliveryOpt.optionName === 'PUDO') {
      setPepPaxiPoint('');
      // setPepPaxiOpt('');
      setOrderAddress({
        ...orderAddress,
        area: '',
        email: '',
        postCode: '',
        storeName: '',
      });
    }

    // setOrderAddress({ ...orderAddress, fullAddress: pepPaxiPoint });
    // setOrderData({ ...orderData, deliveryOption: deliveryOpt });
    // setOrderData({ ...orderData, pexiOption: pepPaxiOpt });
  }, [deliveryOpt, pepPaxiPoint]);
  const [orderAddress, setOrderAddress] = useState({
    area: '',
    email: '',
    fullAddress: pepPaxiPoint,
    name: '',
    option: '',
    optionalPhoneNumber: '',
    phoneNumber: '',
    postCode: '',
    storeName: '',
    street: '',
  });
  const [orderData, setOrderData] = useState({
    // deliveryFee: 0,
    deliveryOption: deliveryOpt,
    id: currentUser.userNumber.toString() + new Date().getTime(),
    imageUrl: auctionDetails.imageUrls[0],
    isAuction: true,
    itemId: auctionDetails.id,
    itemName: auctionDetails.itemName,
    itemPrice:
      (deliveryOpt.fee ? deliveryOpt.fee : 0) +
      auctionDetails.currentPrice +
      Math.floor((auctionDetails.currentPrice * 15) / 100),
    paymentMethod: 'yoco',
    paymentReference: paystackReference,
    // pexiOption: pepPaxiOpt,
    sellerId: auctionDetails.userId,
    shopTag: auctionDetails.shopTag,
    status: 0,
    userEmail: currentUser.email,
    userId: currentUser.id,
    userName: currentUser.name,
  });
  const postOrderData = (e) => {
    setOrderData({ ...orderData, [e.target.name]: e.target.value });
  };
  const postOrderAddress = (e) => {
    setOrderAddress({ ...orderAddress, [e.target.name]: e.target.value });
  };
  const checkForOptionOne = () => {
    if (orderAddress.phoneNumber === '') {
      alert('Please fill in all the fields');
      return 0;
    }
    return 1;
  };
  const checkForOptionTwo = () => {
    if (
      orderAddress.phoneNumber === '' ||
      orderAddress.street === '' ||
      orderAddress.area === '' ||
      orderAddress.postCode === '' ||
      orderAddress.name === '' ||
      orderAddress.email === ''
    ) {
      alert('Please fill in all the fields');
      return 0;
    }
    return 1;
  };
  const checkForOptionThree = () => {
    if (pepPaxiPoint === '' || orderAddress.phoneNumber === '') {
      alert('Please select a Paxi Point && fill in all the fields');
      return 0;
    }
    return 1;
  };
  const checkForOptionFour = () => {
    if (
      orderAddress.phoneNumber === ''

      // orderAddress.storeName === '' ||
      // orderAddress.name === ''
    ) {
      alert('Please fill in all the fields');
      return 0;
    }
    return 1;
  };
  const checkForOptionFive = () => {
    if (orderAddress.phoneNumber === '') {
      alert('Please fill in all the fields');
      return 0;
    }
    return 1;
  };
  // const initializePayment = usePaystackPayment(config);
  const handlePayment = () => {
    let checked = 1;
    if (deliveryOpt.optionName === 'Collect From Seller') {
      checked = checkForOptionOne();
    } else if (deliveryOpt.optionName === 'Aramex') {
      checked = checkForOptionTwo();
    } else if (deliveryOpt.optionName === 'PepPaxi') {
      checked = checkForOptionThree();
    } else if (deliveryOpt.optionName === 'PostNet') {
      checked = checkForOptionFour();
    } else if (deliveryOpt.optionName === 'PUDO') {
      checked = checkForOptionFive();
    } else {
      checked = 0;
      alert('Please select a delivery option');
    }
    if (checked == 1) {
      //checkbox
      if (document.getElementById('flexCheckDefault').checked == false) {
        alert('Please accept the terms and conditions');
        return;
      } else {
        // initializePayment(onSuccess, onClose);
        payWithYoco();
      }
    }
  };
  //Paystack
  const componentProps = {
    email: currentUser.email,
    amount,
    metadata: {
      name: currentUser.name,
      phone: orderAddress.phoneNumber,
    },
    publicKey,
    text: 'Pay Now',
    onSuccess: () =>
      alert('Thanks for doing business with us! Come back soon!!'),
    onClose: () => alert("Wait! Don't leave :("),
  };
  // PeoPaxi Api integration---------------------
  useEffect(() => {
    // console.log(auctionDetails.deliveryOptions[dOpt[deliveryOpt]]);
    function receiveMessage(message) {
      if (
        message.origin === 'https://map.paxi.co.za' &&
        message.data &&
        message.data.trg === 'paxi'
      ) {
        const point = message.data.point;
        /* Modify the code below for your application */

        setPepPaxiPoint(point.shortName + ' ' + point.nodeCode);

        document.getElementById('exampleModal').disabled = true;
        $('.btn-close').trigger({ type: 'click' });
      }
    }
    if (window.addEventListener) {
      window.addEventListener('message', receiveMessage, false);
    } else {
      /* support for older browsers (IE 8) */
      window.attachEvent('onmessage', receiveMessage);
    }
  }, []);
  //----------------------------------------------------------

  return (
    <div className="container">
      <div className="d-flex justify-content-between">
        <h2 className="mt-3">Checkout</h2>
      </div>
      <span>
        <img className="od-icon-control" src={toys} />
        <p className="od-delivery">Delivery Option</p>
      </span>

      {/*----Delivery Options------------------------ */}

      <div style={{ fontSize: '18px' }} className="od-delivery-options mb-3">
        {/* {auctionDetails.deliveryOptions[0].optionName} */}
        {auctionDetails.deliveryOptions[0] &&
          auctionDetails.deliveryOptions.map((opt) => {
            return (
              <div
                className="form-check"
                onClick={() => {
                  setDeliveryOpt(opt);
                  console.log(opt);
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id={opt?.optionName}
                />
                <label className="form-check-label" htmlFor={opt?.optionName}>
                  {opt?.optionName}
                  <br></br>
                  {opt?.detailsText}
                </label>
              </div>
            );
          })}
      </div>
      {/* ------------------Address Details Aditional Forms-------------------------- */}

      {/* ----For Aramex ---- */}
      <span className="">
        <img className="od-icon-control" src={location} />
        <p className="od-delivery">Address</p>
      </span>
      {deliveryOpt.optionName === 'Aramex' && (
        <div>
          <div className="Aramex mb-3">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter Name"
              className="form-control"
              required
              name="name"
              onChange={postOrderAddress}
              value={orderAddress.name}
            ></input>
            <br></br>
            <label>Email</label>
            <input
              type="text"
              className="form-control"
              required
              placeholder="Enter Your Email Address"
              name="email"
              onChange={postOrderAddress}
              value={orderAddress.email}
            ></input>
            <br></br>
            <label>Enter Street Name & Number</label>
            <input
              type="text"
              className="form-control"
              required
              placeholder="Enter Street Name & Number"
              name="street"
              onChange={postOrderAddress}
              value={orderAddress.street}
            ></input>
            <br></br>
            <div className="row">
              <div className="col-6">
                <label>Suburb/Area</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  placeholder="Enter Suburb/Area"
                  name="area"
                  onChange={postOrderAddress}
                  value={orderAddress.area}
                ></input>
              </div>
              <div className="col-6">
                <label>Postal Code</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  placeholder="Enter Postal Code"
                  name="postCode"
                  onChange={postOrderAddress}
                  value={orderAddress.postCode}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* ----For PepPaxi ---- */}
      {deliveryOpt.optionName === 'PepPaxi' && (
        <>
          <br></br>
          <label>Enter Address Here</label>

          <button
            style={{ width: '100%', textAlign: 'left' }}
            type="button"
            className="form-control btn btn-outline-secondary"
            required
            placeholder="Enter Address Here"
            defaultValue={pepPaxiPoint}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            {pepPaxiPoint === '' ? 'Enter Address Here' : pepPaxiPoint}
          </button>
          <br></br>
          <br></br>
        </>
      )}

      {/* The Modal  */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl ">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Choose Address Point
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <iframe
                title="Paxi Map"
                width="100%"
                height="800"
                src="https://map.paxi.co.za?size=l,m,s&status=1,3,4&maxordervalue=1000&output=nc,sn&select=true"
                frameBorder="0"
                allow="geolocation"
              ></iframe>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* //==========================May need for future======================================================================== */}
      {/* ----For PostNet ---- */}
      {/* {deliveryOpt.optionName === 'PUDO' && (
        <div className="">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            required
            placeholder="Enter Name"
            name="name"
            onChange={postOrderAddress}
            value={orderAddress.name}
          ></input>
          <br></br>
          <label>Name of the Store</label>
          <input
            type="text"
            className="form-control"
            required
            placeholder="Enter name of the store"
            name="storeName"
            onChange={postOrderAddress}
            value={orderAddress.storeName}
          ></input>
          <br></br>
        </div>
      )} */}

      {/*-------------------Address Details-------------------------------- */}

      <div>
        <div className="Aramex mb-3">
          <label>Enter Phone Number</label>
          <input
            type="text"
            placeholder="Enter Phone Number"
            className="form-control"
            required
            name="phoneNumber"
            onChange={postOrderAddress}
            value={orderAddress.phoneNumber}
          ></input>
          <br></br>
          <label>Secondary Phone Number</label>
          <input
            type="text"
            className="form-control"
            required
            placeholder="Enter Secondary Phone Number"
            name="optionalPhoneNumber"
            onChange={postOrderAddress}
            value={orderAddress.optionalPhoneNumber}
          ></input>
        </div>
      </div>

      {/*-------------------Payment Options-------------------------------- */}
      <span className="">
        <img className="od-icon-control" src={card} alt="cardIcon" />
        <p className="od-delivery">Payment Options</p>
      </span>
      <div style={{ fontSize: '18px' }} className="od-delivery-options mb-3">
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="ps"
            id="flexRadioDefault1"
            defaultChecked
          />
          <label className="form-check-label" htmlFor="ps">
            YOCO
          </label>
        </div>
      </div>

      {/* ---------------Details--------------------------------------------  */}
      <span className="">
        <img className="od-icon-control" src={box} alt="boxIcon" />
        <p className="od-delivery">{auctionDetails.itemName}</p>
      </span>
      <br></br>
      <span className="">
        <img className="od-icon-control" src={note} alt="noteIcon" />
        <p className="od-delivery">
          Price: R{auctionDetails.currentPrice} + 15% ={' '}
          {auctionDetails.currentPrice +
            Math.floor((auctionDetails.currentPrice * 15) / 100)}
        </p>
      </span>

      <h5>
        Total with delivery fee:{' '}
        {(deliveryOpt.fee ? deliveryOpt.fee : 0) +
          auctionDetails.currentPrice +
          Math.floor((auctionDetails.currentPrice * 15) / 100)}
      </h5>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          I agree to terms & conditions
        </label>
      </div>
      <br></br>
      <div className="d-grid gap-2 mb-5">
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={handlePayment}
        >
          Confirm & Make Payment
        </button>
        <div id="payId">
          {/* <PaystackButton className="btn-pay" {...componentProps} /> */}
          <button id="checkout-button">Pay</button>
        </div>
      </div>
    </div>
  );
}
export default SnipItPage;
