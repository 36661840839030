import React from 'react';
import AllUser from './AdminPages/AllUser';

function AdminPanel() {
  return (
    <div className="container">
      <h1>Admin Panel</h1>

      <AllUser />
    </div>
  );
}

export default AdminPanel;
