import React from 'react';

const TermsConditions = () => {
  return (
    <div className="container">
      <>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              TERMS AND CONDITIONS, TERMS OF SERVICE &amp; TERMS OF USE
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Last updated
            </span>
          </strong>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;<strong>July 16, 2023</strong>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            lineHeight: '12.1pt',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            lineHeight: '12.1pt',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              AGREEMENT TO OUR LEGAL TERMS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'center',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            We are&nbsp;SAKA PTY(LTD), doing business as&nbsp;SAKA&nbsp;('
            <strong>Company</strong>', '<strong>we</strong>', '
            <strong>us</strong>', or '<strong>our</strong>'), a company
            registered in&nbsp;South Africa&nbsp;at&nbsp;20 Churchill
            Avenue,&nbsp;Germiston
          </span>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            ,&nbsp;Gauteng&nbsp;1401
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            .
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'center',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            We operate the website&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a href="http://www.saka.co.za">
              <span style={{ fontFamily: '"Arial",sans-serif' }}>
                www.saka.co.za
              </span>
            </a>
          </span>
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            &nbsp;(the '<strong>Site</strong>'), as well as any other related
            products and services that refer or link to these legal terms
            (the&nbsp;'
            <strong>Legal Terms</strong>') (collectively, the&nbsp;'
            <strong>Services</strong>').
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            We offer a diverse marketplace with a wide range of products from
            various vendors, supporting local businesses and providing a
            seamless &amp; stable shopping experience.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            You can contact us by&nbsp;phone at&nbsp;074-567-4520, email
            at&nbsp;sakasouthafrica23@gmail.com,&nbsp;or by mail to&nbsp;20
            Churchill Avenue,&nbsp;Germiston,&nbsp;Gauteng&nbsp;1401,&nbsp;South
            Africa.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            These Legal Terms constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity ('
            <strong>you</strong>'), and&nbsp;SAKA PTY(LTD), concerning your
            access to and use of the Services. You agree that by accessing the
            Services, you have read, understood, and agreed to be bound by all
            of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL
            TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND
            YOU MUST DISCONTINUE USE IMMEDIATELY.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            We will provide you with prior notice of any scheduled changes to
            the Services you are using. Changes to Legal Terms will become
            effective&nbsp;three (3)&nbsp;days after the notice is given, except
            if the changes apply to&nbsp;new functionality,&nbsp;bug
            fixes,&nbsp;security updates,&nbsp;shop notifications,
            and&nbsp;alerts, in which case the changes will be effective
            immediately. By continuing to use the Services after the effective
            date of any changes, you agree to be bound by the modified terms. If
            you disagree with such changes, you may terminate Services as per
            the section&nbsp;'
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#terms">
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: '#595959',
                  textDecoration: 'none',
                }}
              >
                TERM AND TERMINATION
              </span>
            </a>
          </span>
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            '.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'center',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            The Services are intended for users who are at least 13 years of
            age. All users who are minors in the jurisdiction in which they
            reside (generally under the age of 18) must have the permission of,
            and be directly supervised by, their parent or guardian to use the
            Services. If you are a minor, you must have your parent or guardian
            read and agree to these Legal Terms prior to you using the Services.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            We recommend that you print a copy of these Legal Terms for your
            records.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              TABLE OF CONTENTS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#services">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                1. OUR SERVICES
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#ip">
              <span
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                2. INTELLECTUAL PROPERTY RIGHTS
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#userreps">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                3. USER REPRESENTATIONS
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#userreg">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                4. USER REGISTRATION
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#products">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                5. PRODUCTS
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#purchases">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                6. PURCHASES AND PAYMENT
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#returnyes">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                7.&nbsp;RETURN/REFUNDS&nbsp;POLICY
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#prohibited">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                8. PROHIBITED ACTIVITIES
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#ugc">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                9. USER GENERATED CONTRIBUTIONS
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#license">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                10. CONTRIBUTION&nbsp;LICENCE
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#socialmedia">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                11. SOCIAL MEDIA
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#sitemanage">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                12. SERVICES MANAGEMENT
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#ppno">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                13. PRIVACY POLICY
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#copyrightno">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                14. COPYRIGHT INFRINGEMENTS
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#terms">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                15. TERM AND TERMINATION
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#modifications">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                16. MODIFICATIONS AND INTERRUPTIONS
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#law">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                17. GOVERNING LAW
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#disputes">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                18. DISPUTE RESOLUTION
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#corrections">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                19. CORRECTIONS
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#disclaimer">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                20. DISCLAIMER
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#liability">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                21. LIMITATIONS OF LIABILITY
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#indemnification">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                22. INDEMNIFICATION
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#userdata">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                23. USER DATA
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#electronic">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#misc">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                25. MISCELLANEOUS
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#addclause">
              <span
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                26.&nbsp;RETURN &amp; REFUNDS
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#addclauseb">
              <span
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                27.&nbsp;COMMISSION
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#addclausec">
              <span
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                28.&nbsp;PAYMENTS/PAYOUTS
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#addclaused">
              <span
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                29.&nbsp;IN APP PURCHASES
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#addclausee">
              <span
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                30.&nbsp;LIABILITY
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#contact">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                31. CONTACT US
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              1. OUR SERVICES
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            The information provided when using the Services is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Services from other locations do so
            on their own initiative and are solely responsible for compliance
            with local laws, if and to the extent local laws are applicable.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Times New Roman",serif',
                color: 'black',
              }}
            >
              2. INTELLECTUAL PROPERTY RIGHTS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'center',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Our intellectual property
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            We are the owner or the licensee of all intellectual property rights
            in our Services, including all source code, databases,
            functionality, software, website designs, audio, video, text,
            photographs, and graphics in the Services (collectively,
            the&nbsp;'Content'), as well as the trademarks, service marks, and
            logos contained therein (the&nbsp;'Marks').
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            Our Content and Marks are protected by copyright and trademark laws
            (and various other intellectual property rights and unfair
            competition laws) and treaties in the United States and around the
            world.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            The Content and Marks are provided in or through the
            Services&nbsp;'AS IS'&nbsp;for your&nbsp;personal, non-commercial
            use or internal business purpose&nbsp;only.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Your use of our Services
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            Subject to your compliance with these Legal Terms, including
            the&nbsp;'
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#prohibited">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                PROHIBITED ACTIVITIES
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            '&nbsp;section below, we grant you a non-exclusive,
            non-transferable, revocable&nbsp;licence&nbsp;to:
          </span>
        </p>
        <ul type="square" style={{ marginBottom: '0in' }}>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              access the Services; and
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              download or print a copy of any portion of the Content to which
              you have properly gained access.
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            solely for your&nbsp;personal, non-commercial use or internal
            business purpose.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            Except as set out in this section or elsewhere in our Legal Terms,
            no part of the Services and no Content or Marks may be copied,
            reproduced, aggregated, republished, uploaded, posted, publicly
            displayed, encoded, translated, transmitted, distributed, sold,
            licensed, or otherwise exploited for any commercial purpose
            whatsoever, without our express prior written permission.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            If you wish to make any use of the Services, Content, or Marks other
            than as set out in this section or elsewhere in our Legal Terms,
            please address your request to:&nbsp;sakasouthafrica23@gmail.com. If
            we ever grant you the permission to post, reproduce, or publicly
            display any part of our Services or Content, you must identify us as
            the owners or licensors of the Services, Content, or Marks and
            ensure that any copyright or proprietary notice appears or is
            visible on posting, reproducing, or displaying our Content.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'center',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            We reserve all rights not expressly granted to you in and to the
            Services, Content, and Marks.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            Any breach of these Intellectual Property Rights will constitute a
            material breach of our Legal Terms and your right to use our
            Services will terminate immediately.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}
            >
              Your submissions&nbsp;and contributions
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            Please review this section and the&nbsp;'
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#prohibited">
              <span
                style={{
                  fontSize: 15,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                PROHIBITED ACTIVITIES
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            '&nbsp;section carefully prior to using our Services to understand
            the (a) rights you give us and (b) obligations you have when you
            post or upload any content through the Services.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Submissions:
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;By directly sending us any question, comment, suggestion,
            idea, feedback, or other information about the Services
            ('Submissions'), you agree to assign to us all intellectual property
            rights in such Submission. You agree that we shall own this
            Submission and be entitled to its unrestricted use and dissemination
            for any lawful purpose, commercial or otherwise, without
            acknowledgment or compensation to you.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Contributions:
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;The Services may invite you to chat, contribute to, or
            participate in blogs, message boards, online forums, and other
            functionality during which you may create, submit, post, display,
            transmit, publish, distribute, or broadcast content and materials to
            us or through the Services, including but not limited to text,
            writings, video, audio, photographs, music, graphics, comments,
            reviews, rating suggestions, personal information, or other material
            ('Contributions'). Any Submission that is publicly posted shall also
            be treated as a Contribution.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            You understand that Contributions may be viewable by other users of
            the Services.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              When you post Contributions, you grant us a licence (including use
              of your name, trademarks, and logos):&nbsp;
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            By posting any Contributions, you grant us an unrestricted,
            unlimited, irrevocable, perpetual, non-exclusive, transferable,
            royalty-free, fully-paid, worldwide right, and&nbsp;licence&nbsp;to:
            use, copy, reproduce, distribute, sell, resell, publish, broadcast,
            retitle, store, publicly perform, publicly display, reformat,
            translate, excerpt (in whole or in part), and exploit your
            Contributions (including, without limitation, your image, name, and
            voice) for any purpose, commercial, advertising, or otherwise, to
            prepare derivative works of, or incorporate into other works, your
            Contributions, and to&nbsp;sublicence the licences&nbsp;granted in
            this section. Our use and distribution may occur in any media
            formats and through any media channels.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            This&nbsp;licence&nbsp;includes our use of your name, company name,
            and franchise name, as applicable, and any of the trademarks,
            service marks, trade names, logos, and personal and commercial
            images you provide.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              You are responsible for what you post or upload:
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;By sending us Submissions and/or posting Contributions through
            any part of the Services or making Contributions accessible through
            the Services by linking your account through the Services to any of
            your social networking accounts, you:
          </span>
        </p>
        <ul type="square" style={{ marginBottom: '0in' }}>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              confirm that you have read and agree with our&nbsp;'
            </span>
            <span style={{ color: 'black' }}>
              <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#prohibited">
                <span
                  style={{
                    fontSize: 15,
                    fontFamily: 'Roboto',
                    color: 'blue',
                    textDecoration: 'none',
                  }}
                >
                  PROHIBITED ACTIVITIES
                </span>
              </a>
            </span>
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              '&nbsp;and will not post, send, publish, upload, or transmit
              through the Services any Submission&nbsp;nor post any
              Contribution&nbsp;that is illegal, harassing, hateful, harmful,
              defamatory, obscene, bullying, abusive, discriminatory,
              threatening to any person or group, sexually explicit, false,
              inaccurate, deceitful, or misleading;
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              to the extent permissible by applicable law, waive any and all
              moral rights to any such Submission&nbsp;and/or Contribution;
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              warrant that any such Submission&nbsp;and/or
              Contributions&nbsp;are original to you or that you have the
              necessary rights and&nbsp;licences&nbsp;to submit such
              Submissions&nbsp;and/or Contributions&nbsp;and that you have full
              authority to grant us the above-mentioned rights in relation to
              your Submissions&nbsp;and/or Contributions; and
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              warrant and represent that your Submissions&nbsp;and/or
              Contributions&nbsp;do not constitute confidential information.
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            You are solely responsible for your Submissions&nbsp;and/or
            Contributions&nbsp;and you expressly agree to reimburse us for any
            and all losses that we may suffer because of your breach of (a) this
            section, (b) any third party’s intellectual property rights, or (c)
            applicable law.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              We may remove or edit your Content:
            </span>
          </strong>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;Although we have no obligation to monitor any Contributions,
            we shall have the right to remove or edit any Contributions at any
            time without notice if in our reasonable opinion we consider such
            Contributions harmful or in breach of these Legal Terms. If we
            remove or edit any such Contributions, we may also suspend or
            disable your account and report you to the authorities.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Copyright infringement
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the Services
            infringes upon any copyright you own or control, please immediately
            refer to the&nbsp;'
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/terms-of-service#copyrightno">
              <span
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                COPYRIGHT INFRINGEMENTS
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            '&nbsp;section below.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              3.&nbsp;USER REPRESENTATIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'center',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            By using the Services, you represent and warrant that:
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            (1) all registration information you submit will be true, accurate,
            current, and complete; (2) you will maintain the accuracy of such
            information and promptly update such registration information as
            necessary;
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            (3) you have the legal capacity and you agree to comply with these
            Legal Terms;
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            (4) you are not under the age of 13;
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            (5) you are not a minor in the jurisdiction in which you reside, or
            if a minor, you have received parental permission to use the
            Services; (6) you will not access the Services through automated or
            non-human means, whether through a bot, script or otherwise; (7) you
            will not use the Services for any illegal
            or&nbsp;unauthorised&nbsp;purpose; and (8) your use of the Services
            will not violate any applicable law or regulation.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'center',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Services (or any portion thereof).
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              4.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;USER REGISTRATION
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            You may be required to register to use the Services. You agree to
            keep your password confidential and will be responsible for all use
            of your account and password. We reserve the right to remove,
            reclaim, or change a username you select if we determine, in our
            sole discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              5. PRODUCTS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            We make every effort to display as accurately as possible
            the&nbsp;colours, features, specifications, and details of the
            products available on the Services. However, we do not guarantee
            that the&nbsp;colours, features, specifications, and details of the
            products will be accurate, complete, reliable, current, or free of
            other errors, and your electronic display may not accurately reflect
            the actual&nbsp;colours&nbsp;and details of the products.&nbsp;All
            products are subject to availability, and we cannot guarantee that
            items will be in stock. We reserve the right to discontinue any
            products at any time for any reason. Prices for all products are
            subject to change.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              6.&nbsp;PURCHASES AND PAYMENT
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'center',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            We accept the following forms of payment:
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            - &nbsp;Visa
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            - &nbsp;Mastercard
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            - &nbsp;American Express
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            <br />
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            You agree to provide current, complete, and accurate purchase and
            account information for all purchases made via the Services. You
            further agree to promptly update account and payment information,
            including email address, payment method, and payment card expiration
            date, so that we can complete your transactions and contact you as
            needed. Sales tax will be added to the price of purchases as deemed
            required by us. We may change prices at any time. All payments shall
            be&nbsp;
          </span>
          <span
            style={{
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
              color: '#595959',
            }}
          >
            in&nbsp;South African Rand (ZAR).
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'center',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            You agree to pay all charges at the prices then in effect for your
            purchases and any applicable shipping fees, and
            you&nbsp;authorise&nbsp;us to charge your chosen payment provider
            for any such amounts upon placing your order.&nbsp;We reserve the
            right to correct any errors or mistakes in pricing, even if we have
            already requested or received payment.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'center',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            We reserve the right to refuse any order placed through the
            Services. We may, in our sole discretion, limit or cancel quantities
            purchased per person, per household, or per order. These
            restrictions may include orders placed by or under the same customer
            account, the same payment method, and/or orders that use the same
            billing or shipping address. We reserve the right to limit or
            prohibit orders that, in our sole&nbsp;judgement, appear to be
            placed by dealers, resellers, or distributors.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              7.&nbsp;RETURN/REFUNDS&nbsp;POLICY
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            Please review our Return Policy posted on the Services prior to
            making any purchases.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'justify',
            background: 'white',
          }}
        >
          <span
            style={{
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
              color: '#595959',
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              8.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;PROHIBITED ACTIVITIES
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            You may not access or use the Services for any purpose other than
            that for which we make the Services available. The Services may not
            be used in connection with any
            commercial&nbsp;endeavours&nbsp;except those that are specifically
            endorsed or approved by us.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            As a user of the Services, you agree not to:
          </span>
        </p>
        <ul type="square" style={{ marginBottom: '0in' }}>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Systematically retrieve data or other content from the Services to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user
              passwords.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Circumvent, disable, or otherwise interfere with security-related
              features of the Services, including features that prevent or
              restrict the use or copying of any Content or enforce limitations
              on the use of the Services and/or the Content contained therein.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Disparage, tarnish, or otherwise harm, in our opinion, us and/or
              the Services.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Use any information obtained from the Services in order to harass,
              abuse, or harm another person.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Make improper use of our support services or submit false reports
              of abuse or misconduct.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Use the Services in a manner inconsistent with any applicable laws
              or regulations.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Engage in&nbsp;unauthorised&nbsp;framing of or linking to the
              Services.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Upload or transmit (or attempt to upload or to transmit) viruses,
              Trojan horses, or other material, including excessive use of
              capital letters and spamming (continuous posting of repetitive
              text), that interferes with any party’s uninterrupted use and
              enjoyment of the Services or modifies, impairs, disrupts, alters,
              or interferes with the use, features, functions, operation, or
              maintenance of the Services.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Engage in any automated use of the system, such as using scripts
              to send comments or messages, or using any data mining, robots, or
              similar data gathering and extraction tools.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Delete the copyright or other proprietary rights notice from any
              Content.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Attempt to impersonate another user or person or use the username
              of another user.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Upload or transmit (or attempt to upload or to transmit) any
              material that acts as a passive or active information collection
              or transmission mechanism, including without limitation, clear
              graphics interchange formats ('gifs'), 1×1 pixels, web bugs,
              cookies, or other similar devices (sometimes referred to
              as&nbsp;'spyware' or 'passive collection mechanisms' or 'pcms').
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Interfere with, disrupt, or create an undue burden on the Services
              or the networks or services connected to the Services.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Services to you.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Attempt to bypass any measures of the Services designed to prevent
              or restrict access to the Services, or any portion of the
              Services.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Copy or adapt the Services' software, including but not limited to
              Flash, PHP, HTML, JavaScript, or other code.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Except as permitted by applicable law, decipher, decompile,
              disassemble, or reverse engineer any of the software comprising or
              in any way making up a part of the Services.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Except as may be the result of standard search engine or Internet
              browser usage, use, launch, develop, or distribute any automated
              system, including without limitation, any spider, robot, cheat
              utility, scraper, or offline reader that accesses the Services, or
              use or launch any&nbsp;unauthorised&nbsp;script or other software.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Use a buying agent or purchasing agent to make purchases on the
              Services.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Make any&nbsp;unauthorised&nbsp;use of the Services, including
              collecting usernames and/or email addresses of users by electronic
              or other means for the purpose of sending unsolicited email, or
              creating user accounts by automated means or under
              false&nbsp;pretences.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
                background: 'white',
              }}
            >
              Use the Services as part of any effort to compete with us or
              otherwise use the Services and/or the Content for any
              revenue-generating&nbsp;endeavour&nbsp;or commercial enterprise.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
              Sell or otherwise transfer your profile.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
              Send prohibited/unknown links to other users
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
              Selling products outside of the website
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
              Bullying, mimicking or spamming another users account
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'justify',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              9.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;USER GENERATED CONTRIBUTIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'justify',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            The Services may invite you to chat, contribute to, or participate
            in blogs, message boards, online forums, and other functionality,
            and may provide you with the opportunity to create, submit, post,
            display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or on the Services, including but not
            limited to text, writings, video, audio, photographs, graphics,
            comments, suggestions, or personal information or other material
            (collectively,&nbsp;'Contributions'). Contributions may be viewable
            by other users of the Services and through third-party websites. As
            such, any Contributions you transmit may be treated as
            non-confidential and non-proprietary. When you create or make
            available any Contributions, you thereby represent and warrant that:
          </span>
        </p>
        <ul type="square" style={{ marginBottom: '0in' }}>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              The creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary rights,
              including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third party.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              You are the creator and owner of or have the
              necessary&nbsp;licences, rights, consents, releases, and
              permissions to use and to&nbsp;authorise&nbsp;us, the Services,
              and other users of the Services to use your Contributions in any
              manner contemplated by the Services and these Legal Terms.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              You have the written consent, release, and/or permission of each
              and every identifiable individual person in your Contributions to
              use the name or likeness of each and every such identifiable
              individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Services and these
              Legal Terms.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              Your Contributions are not false, inaccurate, or misleading.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              Your Contributions are not unsolicited
              or&nbsp;unauthorised&nbsp;advertising, promotional materials,
              pyramid schemes, chain letters, spam, mass mailings, or other
              forms of solicitation.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              Your Contributions are not obscene, lewd, lascivious, filthy,
              violent, harassing,&nbsp;libellous, slanderous, or otherwise
              objectionable (as determined by us).
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              Your Contributions do not ridicule, mock, disparage, intimidate,
              or abuse anyone.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              Your Contributions are not used to harass or threaten (in the
              legal sense of those terms) any other person and to promote
              violence against a specific person or class of people.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              Your Contributions do not violate any applicable law, regulation,
              or rule.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              Your Contributions do not violate the privacy or publicity rights
              of any third party.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              Your Contributions do not violate any applicable law concerning
              child pornography, or otherwise intended to protect the health or
              well-being of minors.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              Your Contributions do not include any offensive comments that are
              connected to race, national origin, gender, sexual preference, or
              physical handicap.
            </span>
          </li>
          <li
            style={{
              margin: '0in',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
            >
              Your Contributions do not otherwise violate, or link to material
              that violates, any provision of these Legal Terms, or any
              applicable law or regulation.
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'justify',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            Any use of the Services in violation of the foregoing violates these
            Legal Terms and may result in, among other things, termination or
            suspension of your rights to use the Services.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'justify',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              10.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;CONTRIBUTION LICENCE
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            By posting your Contributions to any part of the Services&nbsp;or
            making Contributions accessible to the Services by linking your
            account from the Services to any of your social networking accounts,
            you automatically grant, and you represent and warrant that you have
            the right to grant, to us an unrestricted, unlimited, irrevocable,
            perpetual, non-exclusive, transferable, royalty-free, fully-paid,
            worldwide right, and&nbsp;licence&nbsp;to host, use, copy,
            reproduce, disclose, sell, resell, publish, broadcast, retitle,
            archive, store, cache, publicly perform, publicly display, reformat,
            translate, transmit, excerpt (in whole or in part), and distribute
            such Contributions (including, without limitation, your image and
            voice) for any purpose, commercial, advertising, or otherwise, and
            to prepare derivative works of, or incorporate into other works,
            such Contributions, and grant and&nbsp;authorise sublicences&nbsp;of
            the foregoing. The use and distribution may occur in any media
            formats and through any media channels.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'justify',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            This&nbsp;licence&nbsp;will apply to any form, media, or technology
            now known or hereafter developed, and includes our use of your name,
            company name, and franchise name, as applicable, and any of the
            trademarks, service marks, trade names, logos, and personal and
            commercial images you provide. You waive all moral rights in your
            Contributions, and you warrant that moral rights have not otherwise
            been asserted in your Contributions.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'justify',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area on
            the Services. You are solely responsible for your Contributions to
            the Services and you expressly agree to exonerate us from any and
            all responsibility and to refrain from any legal action against us
            regarding your Contributions.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            textAlign: 'justify',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            We have the right, in our sole and absolute discretion, (1) to edit,
            redact, or otherwise change any Contributions; (2)
            to&nbsp;re-categorise&nbsp;any Contributions to place them in more
            appropriate locations on the Services; and (3) to pre-screen or
            delete any Contributions at any time and for any reason, without
            notice. We have no obligation to monitor your Contributions.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              11.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              SOCIAL MEDIA
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            <br />
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            As part of the functionality of the Services, you may link your
            account with online accounts you have with third-party service
            providers (each such account, a&nbsp;'Third-Party Account') by
            either: (1) providing your Third-Party Account login information
            through the Services; or (2) allowing us to access
            your&nbsp;Third-Party&nbsp;Account, as is permitted under the
            applicable terms and conditions that govern your use of
            each&nbsp;Third-Party&nbsp;Account. You represent and warrant that
            you are entitled to disclose your&nbsp;Third-Party&nbsp;Account
            login information to us and/or grant us access to
            your&nbsp;Third-Party&nbsp;Account, without breach by you of any of
            the terms and conditions that govern your use of the
            applicable&nbsp;Third-Party&nbsp;Account, and without obligating us
            to pay any fees or making us subject to any usage limitations
            imposed by the third-party service provider of
            the&nbsp;Third-Party&nbsp;Account. By granting us access to
            any&nbsp;Third-Party&nbsp;Accounts, you understand that (1) we may
            access, make available, and store (if applicable) any content that
            you have provided to and stored in
            your&nbsp;Third-Party&nbsp;Account (the&nbsp;'Social Network
            Content') so that it is available on and through the Services via
            your account, including without limitation any friend lists and (2)
            we may submit to and receive from your&nbsp;Third-Party&nbsp;Account
            additional information to the extent you are notified when you link
            your account with the&nbsp;Third-Party&nbsp;Account. Depending on
            the&nbsp;Third-Party&nbsp;Accounts you choose and subject to the
            privacy settings that you have set in
            such&nbsp;Third-Party&nbsp;Accounts, personally identifiable
            information that you post to your&nbsp;Third-Party&nbsp;Accounts may
            be available on and through your account on the Services. Please
            note that if a&nbsp;Third-Party&nbsp;Account or associated service
            becomes unavailable or our access to
            such&nbsp;Third-Party&nbsp;Account is terminated by the third-party
            service provider, then Social Network Content may no longer be
            available on and through the Services. You will have the ability to
            disable the connection between your account on the Services and
            your&nbsp;Third-Party&nbsp;Accounts at any time. PLEASE NOTE THAT
            YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED
            WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR
            AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We make no
            effort to review any Social Network Content for any purpose,
            including but not limited to, for accuracy, legality, or
            non-infringement, and we are not responsible for any Social Network
            Content. You acknowledge and agree that we may access your email
            address book associated with a&nbsp;Third-Party&nbsp;Account and
            your contacts list stored on your mobile device or tablet computer
            solely for purposes of identifying and informing you of those
            contacts who have also registered to use the Services. You can
            deactivate the connection between the Services and
            your&nbsp;Third-Party&nbsp;Account by contacting us using the
            contact information below or through your account settings (if
            applicable). We will attempt to delete any information stored on our
            servers that was obtained through
            such&nbsp;Third-Party&nbsp;Account, except the username and profile
            picture that become associated with your account.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              12.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;SERVICES MANAGEMENT
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            We reserve the right, but not the obligation, to: (1) monitor the
            Services for violations of these Legal Terms; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Legal Terms, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Services or otherwise disable all files and content
            that are excessive in size or are in any way burdensome to our
            systems; and (5) otherwise manage the Services in a manner designed
            to protect our rights and property and to facilitate the proper
            functioning of the Services.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              13.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;PRIVACY POLICY
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            We care about data privacy and security. By using the Services, you
            agree to be bound by our Privacy Policy posted on the Services,
            which is incorporated into these Legal Terms. Please be advised the
            Services are hosted in&nbsp;South Africa. If you access the Services
            from any other region of the world with laws or other requirements
            governing personal data collection, use, or disclosure that differ
            from applicable laws in&nbsp;South Africa, then through your
            continued use of the Services, you are transferring your data
            to&nbsp;South Africa, and you expressly consent to have your data
            transferred to and processed in&nbsp;South Africa.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              14.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;COPYRIGHT INFRINGEMENTS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the Services
            infringes upon any copyright you own or control, please immediately
            notify us using the contact information provided below
            (a&nbsp;'Notification'). A copy of your Notification will be sent to
            the person who posted or stored the material addressed in the
            Notification. Please be advised that pursuant to applicable law you
            may be held liable for damages if you make material
            misrepresentations in a Notification. Thus, if you are not sure that
            material located on or linked to by the Services infringes your
            copyright, you should consider first contacting an attorney.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              15.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;TERM AND TERMINATION
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            These Legal Terms shall remain in full force and effect while you
            use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
            LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
            WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
            (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
            REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
            ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL
            TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SERVICES OR DELETE&nbsp;YOUR ACCOUNT
            AND&nbsp;ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
            WITHOUT WARNING, IN OUR SOLE DISCRETION.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              16.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;MODIFICATIONS AND INTERRUPTIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            We reserve the right to change, modify, or remove the contents of
            the Services at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Services.&nbsp;We also reserve the right to
            modify or discontinue all or part of the Services without notice at
            any time.&nbsp;We will not be liable to you or any third party for
            any modification, price change, suspension, or discontinuance of the
            Services.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            We cannot guarantee the Services will be available at all times. We
            may experience hardware, software, or other problems or need to
            perform maintenance related to the Services, resulting in
            interruptions, delays, or errors. We reserve the right to change,
            revise, update, suspend, discontinue, or otherwise modify the
            Services at any time or for any reason without notice to you. You
            agree that we have no liability whatsoever for any loss, damage, or
            inconvenience caused by your inability to access or use the Services
            during any downtime or discontinuance of the Services. Nothing in
            these Legal Terms will be construed to obligate us to maintain and
            support the Services or to supply any corrections, updates, or
            releases in connection therewith.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              17.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;GOVERNING LAW
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: 'Roboto', color: '#595959' }}>
            These Legal Terms shall be governed by and defined following the
            laws of&nbsp;South Africa.&nbsp;SAKA PTY(LTD)&nbsp;and yourself
            irrevocably consent that the courts of&nbsp;South Africa&nbsp;shall
            have exclusive jurisdiction to resolve any dispute which may arise
            in connection with these Legal Terms.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              18.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;DISPUTE RESOLUTION
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Informal Negotiations
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            To expedite resolution and control the cost of any dispute,
            controversy, or claim related to these Legal Terms (each
            a&nbsp;'Dispute' and collectively, the 'Disputes') brought by either
            you or us (individually, a&nbsp;'Party' and collectively, the
            'Parties'), the Parties agree to first attempt to negotiate any
            Dispute (except those Disputes expressly provided below) informally
            for at least&nbsp;thirty (30)&nbsp;days before initiating
            arbitration. Such informal negotiations commence upon written notice
            from one Party to the other Party.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Binding Arbitration
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            Any dispute arising out of or in connection with these Legal Terms,
            including any question regarding its existence, validity, or
            termination, shall be referred to and finally resolved by the
            International Commercial Arbitration Court under the European
            Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146)
            according to the Rules of this ICAC, which, as a result of referring
            to it, is considered as the part of this clause. The number of
            arbitrators shall be&nbsp;two (2). The seat, or legal place, or
            arbitration shall be&nbsp;Johannesburg,&nbsp;South Africa. The
            language of the proceedings shall be&nbsp;English. The governing law
            of these Legal Terms shall be substantive law of&nbsp;South Africa.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Restrictions
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be
            arbitrated on a class-action basis or to&nbsp;utilise&nbsp;class
            action procedures; and (c) there is no right or authority for any
            Dispute to be brought in a purported representative capacity on
            behalf of the general public or any other persons.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Exceptions to Informal Negotiations and Arbitration
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            The Parties agree that the following Disputes are not subject to the
            above provisions concerning informal negotiations binding
            arbitration: (a) any Disputes seeking to enforce or protect, or
            concerning the validity of, any of the intellectual property rights
            of a Party; (b) any Dispute related to, or arising from, allegations
            of theft, piracy, invasion of privacy,
            or&nbsp;unauthorised&nbsp;use; and (c) any claim for injunctive
            relief. If this provision is found to be illegal or unenforceable,
            then neither Party will elect to arbitrate any Dispute falling
            within that portion of this provision found to be illegal or
            unenforceable and such Dispute shall be decided by a court of
            competent jurisdiction within the courts listed for jurisdiction
            above, and the Parties agree to submit to the personal jurisdiction
            of that court.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              19.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              &nbsp;CORRECTIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            There may be information on the Services that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Services at any time, without prior
            notice.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              20.
            </span>
          </strong>
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: 'black' }}
            >
              &nbsp;DISCLAIMER
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
            AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
            THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
            EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
            THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
            ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE
            WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
            MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
            INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
            YOUR ACCESS TO AND USE OF THE SERVICES, (3)
            ANY&nbsp;UNAUTHORISED&nbsp;ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
            OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
            LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
            THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
            MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT
            OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE
            AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE,
            OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
            OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED
            WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER
            OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST&nbsp;JUDGEMENT&nbsp;AND EXERCISE CAUTION
            WHERE APPROPRIATE.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              21.&nbsp;LIMITATIONS OF LIABILITY
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              22.&nbsp;INDEMNIFICATION
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out
            of:&nbsp;(1) your Contributions;&nbsp;(2) use of the Services; (3)
            breach of these Legal Terms; (4) any breach of your representations
            and warranties set forth in these Legal Terms; (5) your violation of
            the rights of a third party, including but not limited to
            intellectual property rights; or (6) any overt harmful act toward
            any other user of the Services with whom you connected via the
            Services. Notwithstanding the foregoing, we reserve the right, at
            your expense, to assume the exclusive&nbsp;defence&nbsp;and control
            of any matter for which you are required to indemnify us, and you
            agree to cooperate, at your expense, with our&nbsp;defence&nbsp;of
            such claims. We will use reasonable efforts to notify you of any
            such claim, action, or proceeding which is subject to this
            indemnification upon becoming aware of it.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              23.&nbsp;USER DATA
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            We will maintain certain data that you transmit to the Services for
            the purpose of managing the performance of the Services, as well as
            data relating to your use of the Services. Although we perform
            regular routine backups of data, you are solely responsible for all
            data that you transmit or that relates to any activity you have
            undertaken using the Services. You agree that we shall have no
            liability to you for any loss or corruption of any such data, and
            you hereby waive any right of action against us arising from any
            such loss or corruption of such data.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              24.&nbsp;ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            Visiting the Services, sending us emails, and completing online
            forms constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Services, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SERVICES. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              25.&nbsp;MISCELLANEOUS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            These Legal Terms and any policies or operating rules posted by us
            on the Services or in respect to the Services constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Legal Terms
            shall not operate as a waiver of such right or provision. These
            Legal Terms operate to the fullest extent permissible by law. We may
            assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Legal
            Terms is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Legal Terms and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Legal Terms or use of the Services. You agree that
            these Legal Terms will not be construed against us by virtue of
            having drafted them. You hereby waive any and
            all&nbsp;defences&nbsp;you may have based on the electronic form of
            these Legal Terms and the lack of signing by the parties hereto to
            execute these Legal Terms.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              26.&nbsp;RETURN &amp; REFUNDS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            Returns and Refunds will only be processed when both parties have
            come to an agreement. The buyer will still pay the (8% commission)
            from the total purchase amount.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              27.&nbsp;COMMISSION
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            The commission fee for using the SAKA platform will be 8% and will
            be charged after purchase and during refunds.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              28.&nbsp;PAYMENTS/PAYOUTS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            Payments/Payouts will be sent out between 3/5 working days
            (excluding weekends). SAKA will not accept any request to payout if
            the client has not filled in their account details.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              29.&nbsp;IN APP PURCHASES
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            In app purchases must be made through SAKA and not off the SAKA
            platform due to theft, loss or fraudulent activities.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 19, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              30.&nbsp;LIABILITY
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            SAKA shall not be held liable for any theft, loss or any fraudulent
            activities made on/off the SAKA platform. SAKA too shall not be held
            responsible for any altercations and incorrect parcels sent.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 19,
                fontFamily: '"Arial",sans-serif',
                color: '#6C7794',
              }}
            >
              31.&nbsp;CONTACT US
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}>
            In order to resolve a complaint regarding the Services or to receive
            further information regarding use of the Services, please contact us
            at:
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              SAKA PTY(LTD)
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 15,
                fontFamily: '"Arial",sans-serif',
                color: '#595959',
              }}
            >
              20 Churchill Avenue
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              Germiston,&nbsp;Gauteng&nbsp;1401
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              South Africa
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}
            >
              Phone:&nbsp;074-567-4520
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontFamily: '"Arial",sans-serif', color: '#595959' }}
            >
              sakasouthafrica23@gmail.com
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <span style={{ fontSize: 21 }}>&nbsp;</span>
        </p>
      </>
    </div>
  );
};

export default TermsConditions;
