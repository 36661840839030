import {
  getDatabase,
  ref,
  serverTimestamp,
  set,
  update,
} from 'firebase/database';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

function AlertModal({ showAlert, setShowAlert, alertText, workingOn }) {
  const navigate = useNavigate();

  const updateStatus = async () => {
    const db = await getDatabase();
    const wRef = await ref(db, 'withdraw/' + workingOn.id);
    await update(wRef, {
      status: alertText === 'Are you sure to accept the payment?' ? 1 : 2,
    });
    await setShowAlert(false);
  };
  return (
    <>
      <Modal show={showAlert} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div>
            <h5>{alertText}</h5>

            <div className="d-flex justify-content-between">
              <button
                onClick={() => updateStatus()}
                className="btn btn-warning"
              >
                Continue
              </button>
              <button
                className="btn btn-danger"
                onClick={() => setShowAlert(!showAlert)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
//This is a comment
export default AlertModal;
