import React, { useContext } from 'react';
import { AuthContext } from './Components/Context/AuthContext';
import { Navigate } from 'react-router-dom';

function reloadAndRedirect(newPath) {
  // Reload the page
  window.location.reload();

  // Redirect to the new path
  window.location.href = newPath;
}
const RequireAuth = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const usr = JSON.parse(localStorage.getItem('user'));
  if (usr === null) {
    reloadAndRedirect('/login');
    // return <Navigate to="/login" />;
  } else if (currentUser === null && usr !== null) {
    window.location.reload();
  } else if (currentUser.email !== usr.email) {
    window.location.reload();
  }
  return currentUser && currentUser.dactive === false ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default RequireAuth;
