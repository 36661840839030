import React, { createContext, useEffect, useState } from 'react';

export const ProductDetailsContext = createContext(
  JSON.parse(localStorage.getItem('currProduct')) || null
);

export const ProductDetailsContextProvider = ({ children }) => {
  const [productDetails, setProductDetails] = useState(
    JSON.parse(localStorage.getItem('currProduct')) || null
  );
  useEffect(() => {
    localStorage.setItem('currProduct', JSON.stringify(productDetails));
  }, [productDetails]);

  return (
    <ProductDetailsContext.Provider value={[productDetails, setProductDetails]}>
      {children}
    </ProductDetailsContext.Provider>
  );
};
// category: '',
//     id: '',
//     imageUrls: [],
//     isAramex: false,
//     isFreeDelivery: false,
//     isPepPaxi: false,
//     isPostNet: false,
//     isPublished: false,
//     isSelfCollect: false,
//     isUberConnect: false,
//     itemDescription: '',
//     itemName: '',
//     loveCount: 0,
//     newPrice: 0,
//     price: 0,
//     quantity: 1,
//     shopTag: '',
//     size: '',
//     soldCount: 0,
//     timeStamp: 0,
//     userId: '',
//     viewCount: 0,
//   }
