import React, { useEffect, useState } from 'react';
import {
  getDatabase,
  ref,
  onValue,
  equalTo,
  query,
  orderByChild,
  limitToLast,
} from 'firebase/database';
import { orderBy } from 'firebase/firestore';
// import '../CategoryWise.css';
import ProductCard from '../ProductCard';
function ShopWise({ shopTag }) {
  const [items, setItems] = useState([]);
  const db = getDatabase();
  useEffect(() => {
    const regItemRef = query(
      ref(db, 'regular_item/'),
      orderByChild('shopTag'),
      equalTo(shopTag),
      limitToLast(50)
    );
    onValue(regItemRef, (snapshot) => {
      const data = snapshot.val();
      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
          setItems(itArr);
        });
      }
    });
  }, []);
  return (
    <>
      <div>
        <div className="row">
          {items.map((item) => (
            <div key={item.id} className="col-6 col-md-4 col-lg-2">
              <ProductCard item={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ShopWise;
