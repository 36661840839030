import React, { useContext, useEffect, useState } from 'react';
import './CommonDrawer.css';

//Firebase Imports

import { storage } from '../../../Config/Config';
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';

import LoadingModal from '../../Common/LoadingModal';
import { AuthContext } from '../../Context/AuthContext';
import {
  getDatabase,
  set,
  ref as dbRef,
  serverTimestamp,
} from 'firebase/database';
import { useNavigate } from 'react-router-dom';

const AddRegularItem = () => {
  const navigate = useNavigate();
  //Context
  const { currentUser } = useContext(AuthContext);

  //States
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [imgUpLoading, setImgUpLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [productImages, setProductImages] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');

  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [category, setCategory] = useState('Clothes');
  const [clothSize, setClothSize] = useState('XXS');
  const [shoeSize, setShoeSize] = useState('4');
  const [productQuantity, setProductQuantity] = useState(1);
  const [productPrice, setProductPrice] = useState(0);

  //Delivery Options----------------------------------------------
  const [selfCollect, setSelfCollect] = useState(false);
  const [aramex, setAramex] = useState(false);
  const [pepPaxi, setPepPaxi] = useState(false);
  const [postNet, setPostNet] = useState(false);
  const [pudo, setPudo] = useState(false);

  //Selected Options----------------------------------------------------------
  const [aramexOption, setAramexOption] = useState({});
  const [pepPaxiOption, setPepPaxiOption] = useState({});
  const [postNetOption, setPostNetOption] = useState({});
  const [pudoOption, setPudoOption] = useState({});

  //Handle Select Change Functions ------------------------------------------------

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleClothSizeChange = (event) => {
    setClothSize(event.target.value);
  };
  const handleShoeSizeChange = (event) => {
    setShoeSize(event.target.value);
  };

  // Upload File
  const showUrls = () => {
    console.log(productImages);
  };
  const setProductImageUrls = async (downloadURL) => {
    try {
      const arr = [...productImages];
      arr.push(downloadURL);
      console.log(arr);
      await setProductImages(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadFile = async () => {
    if (image === null) return;
    setLoading(true);
    const name = new Date().getTime() + image.name;
    const storageRef = ref(storage, 'item_image/' + name);
    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        alert('Something Went Wrong, Failed to Upload Image');
        // setImgUpLoading(false);
        // setLoading(imgUpLoading);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          console.log('File available at', downloadURL);
          setLoading(false);
          setImgUpLoading(false);
          setProgress(0);
          await setProductImageUrls(downloadURL);
          await setImage(null);
          // setLoading(imgUpLoading);
          // setUserData({ ...userData, photoUrl: downloadURL });
        });
      }
    );
  };

  //Delete File

  const handleDelete = async (item) => {
    try {
      setLoading(true);
      const filename = item.substring(
        item.lastIndexOf('item_image%2F') + 13,
        item.lastIndexOf('?alt=media&token=')
      );
      console.log(filename);
      const storageRef = ref(storage, 'item_image/' + filename);
      await deleteObject(storageRef);
    } catch (error) {
      console.error('Error deleting image:', error);
      setLoading(false);
    } finally {
      let productIms = [...productImages];
      productIms = productIms.filter((it) => it !== item);
      setProductImages(productIms);
      setLoading(false);
    }
  };

  // Add To Database
  const addToDatabase = async (productData) => {
    const db = getDatabase();
    const pdId = currentUser.userNumber.toString() + new Date().getTime();
    await set(dbRef(db, 'regular_item/' + pdId), {
      ...productData,
      id: pdId,
      timeStamp: serverTimestamp(Date.now()),
    });
    await setLoading(false);
    await navigate('/myshop');
  };

  // Mangaing Product Data
  const manageProductData = () => {
    setLoading(true);
    let deliveryO = [];

    if (selfCollect === true)
      deliveryO.push({
        detailsText: '',
        fee: 0,
        optionName: 'Collect From Seller',
      });

    if (aramexOption.detailsText) deliveryO.push(aramexOption);
    if (pepPaxiOption.detailsText) deliveryO.push(pepPaxiOption);
    if (pudoOption.detailsText) deliveryO.push(pudoOption);
    if (postNetOption.detailsText) deliveryO.push(postNetOption);

    console.log(deliveryO);

    const productData = {
      category: category.toLowerCase(),
      size:
        category === 'Clothes' || category === 'Shoes'
          ? category === 'Clothes'
            ? clothSize
            : shoeSize
          : '',
      itemName: productName,
      itemDescription: productDescription,
      quantity: parseInt(productQuantity),
      price: parseInt(productPrice),
      newPrice: 0,
      imageUrls: productImages,
      loveCount: 0,
      viewCount: 0,
      shopTag: currentUser.shopTag,
      isPublished: true,
      deliveryOptions: deliveryO,
      userId: currentUser.id,
      soldCount: 0,
    };
    console.log(productData);
    addToDatabase(productData);
  };
  //Check Fields
  const checkFields = () => {
    if (productImages.length === 0) {
      alert('Please Upload Product Images');
    } else if (productName === '') {
      alert('Please Enter Product Name');
    } else if (productDescription.length < 20) {
      alert('Please Enter Product Description of at least 20 characters');
    } else if (productQuantity === 0) {
      alert('Please Enter Product Quantity');
    } else if (productPrice === 0) {
      alert('Please Enter Product Price');
    } else if (category === 'Clothes' && clothSize === '') {
      alert('Please Select Product Size');
    } else if (category === 'Shoes' && shoeSize === '') {
      alert('Please Select Product Size');
    } else if (
      selfCollect === false &&
      aramexOption.detailsText == null &&
      pepPaxiOption.detailsText == null &&
      postNetOption.detailsText == null &&
      pudoOption.detailsText == null
    ) {
      alert('Please Select Delivery Option');
    } else {
      // alert('All good');

      manageProductData();
    }
  };

  // handleAddProduct
  const handleAddProduct = async () => {
    checkFields();
  };

  //Get Data
  useEffect(() => {
    showUrls();
  }, [productImages]);

  useEffect(() => {
    uploadFile();
  }, [image]);
  useEffect(() => {
    console.log(aramexOption);
    console.log(pepPaxiOption);
    console.log(postNetOption);
    console.log(pudoOption);
    console.log(shoeSize);
    console.log(clothSize);
    console.log(productName);
    console.log(productDescription);
    console.log(category);
    console.log(productQuantity);
    console.log(productPrice);
  }, [
    aramexOption,
    pepPaxiOption,
    postNetOption,
    pudoOption,
    shoeSize,
    clothSize,
    productName,
    productDescription,
    category,
    productQuantity,
    productPrice,
  ]);

  return (
    <div className="container">
      <h3 className="mt-2">Add Regular Item</h3>
      {/* <img src={productImages[0]} alt="igd" /> */}
      <div className="imUploadSection card px-3">
        <div className="row">
          {productImages &&
            productImages.map((item) => {
              return (
                <div key={item} className="col-lg-2 col-md-3 col-sm-4">
                  <div className="card my-3 w-10 mr-2 ">
                    <img className="pdImgCrt" src={item} alt="cdi" />
                    <button
                      onClick={() => handleDelete(item)}
                      className="btn btn-outline-danger"
                    >
                      Remove Image
                    </button>
                  </div>
                </div>
              );
            })}

          <div className="col-lg-2 col-md-3 col-sm-4">
            <div className="">
              <div className="upload-btn-wrapper ">
                <button className="btn-product-image"></button>
                <input
                  className="thePdImgInput"
                  onChange={(e) => setImage(e.target.files[0])}
                  type="file"
                  name="myfile"
                />
              </div>
            </div>
          </div>
        </div>
        {progress > 0 && (
          <div className="progress my-2">
            <div
              className="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        )}
      </div>

      <div className="card px-3 mt-4">
        <label className="mt-3">Name</label>
        <input
          type="text"
          placeholder="Enter Product Name"
          className="form-control"
          required
          name="name"
          onChange={(e) => setProductName(e.target.value)}
        ></input>
        <br></br>
        <label>Product Description</label>
        <input
          type="text"
          className="form-control"
          required
          placeholder="Enter Description"
          name="description"
          onChange={(e) => setProductDescription(e.target.value)}
        ></input>
        <br></br>
        <label htmlFor="categoryId" className="form-label">
          Category
        </label>
        <select
          className="form-select"
          id="categoryId"
          value={category}
          onChange={handleCategoryChange}
        >
          <option defaultChecked>Clothes</option>
          <option value="Shoes">Shoes</option>
          <option value="Electronics">Electronics</option>
          <option value="Phones">Phones</option>
          <option value="Laptops">Laptops</option>
          <option value="Toys">Toys</option>
          <option value="Collectibles">Collectibles</option>
          <option value="Garage Sale">Garage Sale</option>
          <option value="Discounted">Discounted</option>
        </select>
        <br></br>
        {category === 'Clothes' && (
          <div>
            <label htmlFor="clothSizeId" className="form-label">
              Size
            </label>
            <select
              className="form-select"
              id="clothSizeId"
              value={clothSize}
              onChange={handleClothSizeChange}
            >
              <option defaultChecked>XXS</option>
              <option value="XS">XS</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="XXL">XXL</option>
              <option value="XXXL">XXXL</option>
            </select>
          </div>
        )}
        {category === 'Shoes' && (
          <div>
            <label htmlFor="dropdown" className="form-label">
              Size
            </label>
            <select
              className="form-select"
              id="shoeSizeId"
              value={shoeSize}
              onChange={handleShoeSizeChange}
            >
              <option defaultChecked>4</option>
              <option value="4,5">4,5</option>
              <option value="5">5</option>
              <option value="5,5">5,5</option>
              <option value="6">6</option>
              <option value="6,5">6,5</option>
              <option value="7">7</option>
              <option value="7,5">7,5</option>
              <option value="8">8</option>
              <option value="8,5">8,5</option>
              <option value="9">9</option>
              <option value="9,5">9,5</option>
              <option value="10">10</option>
              <option value="10,5">10,5</option>
              <option value="11">11</option>
              <option value="11,5">11,5</option>
            </select>
          </div>
        )}
        <br></br>
        <div className="row">
          <div className="col-6">
            <label>Quantity</label>
            <input
              type="number"
              className="form-control"
              required
              placeholder="Quantity"
              name="description"
              value={productQuantity}
              onChange={(e) => setProductQuantity(e.target.value)}
            ></input>
          </div>

          <div className="col-6">
            <label>Price</label>
            <input
              type="number"
              className="form-control"
              required
              placeholder="Enter Price"
              name="description"
              value={productPrice}
              onChange={(e) => setProductPrice(e.target.value)}
            ></input>
          </div>
        </div>
        <br></br>
      </div>
      <div className="card px-3 mt-4">
        <h5 className="mt-3">Delivery Details</h5>
        <div className="form-check form-switch">
          <label className="form-check-label" htmlFor="selfCollectSwitch">
            Self Collect
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="selfCollectSwitch"
            checked={selfCollect}
            onChange={() => setSelfCollect(!selfCollect)}
          />

          <br></br>
          <label className="form-check-label" htmlFor="aramexSwitch">
            Aramex
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="aramexSwitch"
            checked={aramex}
            onChange={() => {
              setAramex(!aramex);
              setAramexOption({});
            }}
          />

          {aramex && (
            <form>
              <div
                onClick={() =>
                  setAramexOption({
                    detailsText: 'Offer free shipping (0 ZAR)',
                    fee: 0,
                    optionName: 'Aramex',
                  })
                }
              >
                <label>
                  <input
                    type="radio"
                    name="color"
                    value="Offer free shipping (0 ZAR)"
                  />
                  &nbsp;&nbsp;Offer free shipping (0 ZAR)
                </label>
              </div>
              <div
                onClick={() =>
                  setAramexOption({
                    detailsText: 'Standard parcel (350x450 mm)(99 ZAR)',
                    fee: 99,
                    optionName: 'Aramex',
                  })
                }
              >
                <label>
                  <input
                    type="radio"
                    name="color"
                    value="Standard parcel (350x450 mm)(99 ZAR)"
                  />
                  &nbsp;&nbsp; Standard parcel (350x450 mm)(99 ZAR)
                </label>
              </div>
            </form>
          )}
          <br></br>
          <label className="form-check-label" htmlFor="pepPaxiSwitch">
            PepPaxi
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="pepPaxiSwitch"
            checked={pepPaxi}
            onChange={() => {
              setPepPaxi(!pepPaxi);
              setPepPaxiOption({});
            }}
          />

          {pepPaxi && (
            <form>
              <div
                onClick={() =>
                  setPepPaxiOption({
                    detailsText: 'Offer free shipping (0 ZAR)',
                    fee: 0,
                    optionName: 'PepPaxi',
                  })
                }
              >
                <label>
                  <input type="radio" name="color" value="red" />
                  &nbsp;&nbsp;Offer free shipping (0 ZAR)
                </label>
              </div>
              <div
                onClick={() =>
                  setPepPaxiOption({
                    detailsText: 'Standard parcel (350x450 mm)(59 ZAR)',
                    fee: 59,
                    optionName: 'PepPaxi',
                  })
                }
              >
                <label>
                  <input type="radio" name="color" value="green" />
                  &nbsp;&nbsp; Standard parcel (350x450 mm)(59 ZAR)
                </label>
              </div>
              <div
                onClick={() =>
                  setPepPaxiOption({
                    detailsText: 'Large parcel (640x510 mm)(99 ZAR)',
                    fee: 99,
                    optionName: 'PepPaxi',
                  })
                }
              >
                <label>
                  <input type="radio" name="color" value="green" />
                  &nbsp;&nbsp; Large parcel (640x510 mm)(99 ZAR)
                </label>
              </div>
            </form>
          )}
          <br></br>
          <label className="form-check-label" htmlFor="postNetSwitch">
            PostNet
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="postNetSwitch"
            checked={postNet}
            onChange={() => {
              setPostNet(!postNet);
              setPostNetOption({});
            }}
          />

          {postNet && (
            <form>
              <div
                onClick={() =>
                  setPostNetOption({
                    detailsText: 'Offer free shipping (0 ZAR)',
                    fee: 0,
                    optionName: 'PostNet',
                  })
                }
              >
                <label>
                  <input type="radio" name="color" value="red" />
                  &nbsp;&nbsp; Offer free shipping (0 ZAR)
                </label>
              </div>
              <div
                onClick={() =>
                  setPostNetOption({
                    detailsText: 'Standard Parcel (up to 5kg) (109 ZAR)',
                    fee: 109,
                    optionName: 'PostNet',
                  })
                }
              >
                <label>
                  <input type="radio" name="color" value="green" />
                  &nbsp;&nbsp; Standard Parcel (up to 5kg) (109 ZAR)
                </label>
              </div>
            </form>
          )}

          <br></br>
          <label className="form-check-label" htmlFor="pudoSwitch">
            PUDO
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="pudoSwitch"
            checked={pudo}
            onChange={() => {
              setPudo(!pudo);
              setPudoOption({});
            }}
          />

          {pudo && (
            <form>
              <div
                onClick={() =>
                  setPudoOption({
                    detailsText: 'Offer free shipping (0 ZAR)',
                    fee: 0,
                    optionName: 'PUDO',
                  })
                }
              >
                <label>
                  <input type="radio" name="color" value="red" />
                  &nbsp;&nbsp; Offer free shipping (0 ZAR)
                </label>
              </div>
              <div
                onClick={() =>
                  setPudoOption({
                    detailsText: 'Extra-Small (600x170x80mm) (50 ZAR)',
                    fee: 50,
                    optionName: 'PUDO',
                  })
                }
              >
                <label>
                  <input type="radio" name="color" value="green" />
                  &nbsp;&nbsp; Extra-Small (600x170x80mm) (50 ZAR)
                </label>
              </div>
              <div
                onClick={() =>
                  setPudoOption({
                    detailsText: 'Small (600x410x80mm) (60 ZAR)',
                    fee: 60,
                    optionName: 'PUDO',
                  })
                }
              >
                <label>
                  <input type="radio" name="color" value="green" />
                  &nbsp;&nbsp; Small (600x410x80mm) (60 ZAR)
                </label>
              </div>
              <div
                onClick={() =>
                  setPudoOption({
                    detailsText: 'Medium (600x410x190mm) (60 ZAR)',
                    fee: 60,
                    optionName: 'PUDO',
                  })
                }
              >
                <label>
                  <input type="radio" name="color" value="green" />
                  &nbsp;&nbsp; Medium (600x410x190mm) (60 ZAR)
                </label>
              </div>
              <div
                onClick={() =>
                  setPudoOption({
                    detailsText: 'Large (600x410x410mm) (60 ZAR)',
                    fee: 60,
                    optionName: 'PUDO',
                  })
                }
              >
                <label>
                  <input type="radio" name="color" value="green" />
                  &nbsp;&nbsp; Large (600x410x410mm) (60 ZAR)
                </label>
              </div>
              <div
                onClick={() =>
                  setPudoOption({
                    detailsText: 'Extra-Large (600x410x690mm)(60 ZAR)',
                    fee: 60,
                    optionName: 'PUDO',
                  })
                }
              >
                <label>
                  <input type="radio" name="color" value="green" />
                  &nbsp;&nbsp; Extra-Large (600x410x690mm)(60 ZAR)
                </label>
              </div>
            </form>
          )}

          <br></br>
        </div>
      </div>
      <button
        onClick={handleAddProduct}
        className="btn btn-outline-primary mb-5 mt-3"
      >
        {' '}
        Save{' '}
      </button>

      <LoadingModal show={loading || imgUpLoading}></LoadingModal>
    </div>
  );
};

export default AddRegularItem;
