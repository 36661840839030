import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import './CommonDrawer.css';
import { getDatabase, onValue, ref } from 'firebase/database';
import EditProfile from './EditProfile';
function Profile() {
  const { currentUser } = useContext(AuthContext);
  const [shopDetails, setShopDetails] = useState({});
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    const db = getDatabase();
    onValue(ref(db, 'shop/' + currentUser.shopTag), async (snapshot) => {
      const data = await snapshot.val();
      console.log(data);
      if (data !== null) {
        console.log(data);
        await setShopDetails(data);
      }
    });
  }, []);

  return (
    <div className="container">
      <h2 className="my-3">Profile Info</h2>
      <div className="text-center">
        <img
          className="profile-img-control"
          src={currentUser.photoUrl}
          alt="Sakib1"
        />

        <h3 className="mt-2">{currentUser.name}</h3>
        <div className="mt-2 row">
          <div className="col-md-6">
            <h5>Shop Name</h5>
            <p>My Shop</p>
          </div>
          <div className="col-md-6">
            <h5>Shop Tag</h5>
            <p>{currentUser.shopTag}</p>
          </div>
        </div>

        <h5 className="mt-4">Shop Name</h5>
        <p>
          {' '}
          {shopDetails.shopName ? shopDetails.shopName : 'No Shop Added Yet'}
        </p>
        <h5 className="mt-4">Shop Description</h5>
        <p>
          {' '}
          {shopDetails.shopDescription
            ? shopDetails.shopDescription
            : 'No Description Available'}
        </p>
        <h5 className="mt-4">Email</h5>
        <p>{currentUser.email}</p>
        <EditProfile
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          spName={shopDetails.shopName}
          spDescription={shopDetails.shopDescription}
          shopTag={shopDetails.shopTag}
          currentUser={currentUser}
        />
        <button
          className="mt-4 btn btn-outline-primary"
          onClick={() => setShowEdit(!showEdit)}
        >
          Edit Info
        </button>
      </div>
    </div>
  );
}

export default Profile;

// <h2 className="my-3">Profile</h2>
//       <div className="text-center">
//         <img
//           className="profile-img-control"
//           src={currentUser.photoUrl}
//           alt="Sakib1"
//         />
//         {updateStatus && (
//           <input
//             className="btn btn-outline-primary img-inout-control"
//             type="file"
//           />
//         )}
//       </div>
//       <h5>Your Name</h5>
//       <input
//         name="name"
//         type="text"
//         className={customClass + ''}
//         onChange={postUserData}
//         value={updatedUserData.name}
//       />
//       {updatedUserData.shopTag != '' && (
//         <>
//           <h5>Shop Name</h5>
//           <input
//             name="shopName"
//             type="text"
//             className={customClass + ''}
//             onChange={postUserData}
//             value={updatedUserData.shopName}
//           />
//         </>
//       )}
//       {updatedUserData.shopTag != '' && (
//         <>
//           <h5>Shop Tag</h5>
//           <input
//             name="shopTag"
//             type="text"
//             className={customClass + ''}
//             onChange={postUserData}
//             value={updatedUserData.shopTag}
//           />
//         </>
//       )}

//       <h5>Email</h5>
//       <h4>{currentUser.email}</h4>

//       <div className="mt-5">
//         {updateStatus ? (
//           <button
//             className="btn btn-outline-primary"
//             onClick={() => handleProfileUpdate()}
//           >
//             Update
//           </button>
//         ) : (
//           <button
//             className="btn btn-outline-primary"
//             onClick={() => {
//               setUpdateStatus(true);
//               setCustomClass('onEdit');
//             }}
//           >
//             Edit
//           </button>
//         )}
//       </div>
