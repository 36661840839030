import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../Navigation/NavBar';
import './auth.css';
import { signInWithEmailAndPassword, updateCurrentUser } from 'firebase/auth';
import { auth, fs, storage } from '../../Config/Config';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';
import { getDatabase, ref as dbRef, get, onValue } from 'firebase/database';
import { handleLogin } from './handleLogin';
import LoadingModal from '../Common/LoadingModal';

function Login() {
  const navigate = useNavigate();

  //States=====================================================================
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const { dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  //Handle Login===============================================================
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(!loading);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const userID = await userCredential.user.uid;
      const db = await getDatabase();
      const dref = await dbRef(db, 'user/' + userID);
      await onValue(dref, (snapshot) => {
        const data = snapshot.val();
        console.log(data);
        if (data && data.dactive === false) {
          dispatch({ type: 'LOGIN', payload: data });
          setSuccessMsg('Login Successful');
          // alert('Login Successful');
          setLoading(!loading);

          navigate('/');
        } else {
          setErrorMsg('Login Failed');
          setLoading(false);
          alert('Login Failed');
        }
      });
    } catch (err) {
      console.log(err);
      setErrorMsg(err.message);
      alert('Login Failed');
      setLoading(false);
    }
  };
  // Login Page=================================================================
  return (
    <>
      <div className="container">
        <h1 className="my-4">Login</h1>
        <hr />
        <div className="row">
          <div className="col-md-6 pt-5">
            <h1 className="mt-4 pt-5">Enter Your Credentials.</h1>
            <form
              className="form-group mt-5"
              autoComplete="off"
              onSubmit={handleLogin}
            >
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                required
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address."
                value={email}
              ></input>
              <br></br>
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                required
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password."
                value={password}
              ></input>
              <br></br>
              <div className="btn-box">
                <span>
                  Don't have an account SignUp
                  <Link to="/signup" className="link">
                    {' '}
                    Here
                  </Link>
                </span>
                <button type="submit" className="btn btn-primary btn-md">
                  LOGIN
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-6">
            <img
              src="https://img.freepik.com/free-vector/mobile-login-concept-illustration_114360-83.jpg?w=826&t=st=1670783412~exp=1670784012~hmac=1d6afbe7887b5eb9b28afacbe6074c7a839c56930e43b964050c5b2bda870487"
              alt="login"
              className="img-fluid"
            />
          </div>
        </div>
        <LoadingModal show={loading}></LoadingModal>
        {/* <button onClick={() => setLoading(!loading)}>set</button> */}
        {errorMsg && (
          <>
            <br></br>
            <div className="error-msg">{errorMsg}</div>
          </>
        )}
      </div>
    </>
  );
}

export default Login;
