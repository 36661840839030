import React, { useContext } from 'react';
import shop from '../../../assets/svgs/shop.svg';
import wallet from '../../../assets/svgs/wallet.svg';
import card from '../../../assets/svgs/card.svg';
import card_1 from '../../../assets/svgs/card_1.svg';
import chats from '../../../assets/svgs/chats.svg';
import settings from '../../../assets/svgs/settings.svg';
import auction from '../../../assets/svgs/auction.svg';
import bookmark from '../../../assets/svgs/bookmark.svg';
import heart from '../../../assets/svgs/heart.svg';
import user from '../../../assets/svgs/user.svg';
import cart from '../../../assets/svgs/cart.svg';
import exit from '../../../assets/images/exit.png';
import adminUser from '../../../assets/images/user-gear.png';
import { AuthContext } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';

import './Drawer.css';
import { Link } from 'react-router-dom';
function Drawer() {
  const navigate = useNavigate();

  const { currentUser, dispatch } = useContext(AuthContext);
  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    navigate('/');
  };
  const closeDrawer = () => {
    document.getElementById('close-drawer').click();
  };
  return (
    <>
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Manage
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            id="close-drawer"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul>
            <Link
              onClick={closeDrawer}
              className="link-style-control"
              to="/auctions"
            >
              <li>
                <span>
                  <img className="drawer-icon-control" src={auction} />
                  Auction
                </span>{' '}
              </li>
            </Link>
            {currentUser && (
              <>
                <Link
                  onClick={closeDrawer}
                  className="link-style-control"
                  to="/profile"
                >
                  <li>
                    <span>
                      <img className="drawer-icon-control" src={user} />
                      Profile
                    </span>
                  </li>
                </Link>
                <Link
                  onClick={closeDrawer}
                  className="link-style-control"
                  to="/chats"
                >
                  <li>
                    <span>
                      <img className="drawer-icon-control" src={chats} />
                      Chats
                    </span>
                  </li>
                </Link>
                <Link
                  onClick={closeDrawer}
                  className="link-style-control"
                  to="/myshop"
                >
                  <li>
                    <span>
                      <img className="drawer-icon-control" src={shop} />
                      My Shop
                    </span>
                  </li>
                </Link>
                <Link
                  onClick={closeDrawer}
                  className="link-style-control"
                  to="/myorders"
                >
                  <li>
                    <span>
                      <img className="drawer-icon-control" src={cart} />
                      My Orders{' '}
                    </span>
                  </li>
                </Link>
                <Link
                  onClick={closeDrawer}
                  className="link-style-control"
                  to="/wallet"
                >
                  <li>
                    <span>
                      <img className="drawer-icon-control" src={wallet} />
                      My Wallet{' '}
                    </span>
                  </li>
                </Link>
                {/* <Link className="link-style-control" to="/mycards">
                  <li>
                    <span>
                      <img className="drawer-icon-control" src={card} />
                      My Cards{' '}
                    </span>
                  </li>
                </Link> */}
                <Link
                  onClick={closeDrawer}
                  className="link-style-control"
                  to="/favourites"
                >
                  <li>
                    <span>
                      <img className="drawer-icon-control" src={bookmark} />
                      Favourites
                    </span>
                  </li>
                </Link>
                <Link
                  onClick={closeDrawer}
                  className="link-style-control"
                  to="/followings"
                >
                  <li>
                    <span>
                      <img className="drawer-icon-control" src={heart} />
                      Followings
                    </span>
                  </li>
                </Link>
                <Link
                  onClick={closeDrawer}
                  className="link-style-control"
                  to="/settings"
                >
                  <li>
                    <span>
                      <img className="drawer-icon-control" src={settings} />
                      Settings
                    </span>
                  </li>
                </Link>
                <li onClick={handleLogout}>
                  <span>
                    <img className="drawer-icon-control" src={exit} />
                    Logout
                  </span>
                </li>
                {currentUser?.email === 'rumon@gmail.com' && (
                  <Link
                    onClick={closeDrawer}
                    className="link-style-control"
                    to="/admin-xy1"
                  >
                    <li>
                      <span style={{ color: '#239AF8' }}>
                        <img className="drawer-icon-control" src={adminUser} />
                        Admin
                      </span>
                    </li>
                  </Link>
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Drawer;
