import {
  getDatabase,
  onValue,
  orderByChild,
  query,
  ref,
  set,
} from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { Dropdown, Table } from 'react-bootstrap';
import AlertModal from './AlertModal';
import RestrictAlertModal from './RestrictAlertModal';

const Admin = () => {
  const [withdrawRequests, setWithdrawRequests] = useState([]);
  const statusOptions = ['Pending', 'In Progress', 'Completed'];
  const [alertText, setAlertText] = useState('');
  const [workingOn, setWorkingOn] = useState({});

  const [allOrders, setAllOrders] = useState([]);
  const [objectsByMonthYear, setObjectsByMonthYear] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showRestrictionAlert, setShowRestrictionAlert] = useState(false);
  const [state, setState] = useState('withdraw');
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const db = getDatabase();
    const wRef = ref(db, 'withdraw/');
    onValue(wRef, async (snapshot) => {
      const data = await snapshot.val();
      const itArr = [];
      if (data) {
        await Object.values(data).map((it) => {
          itArr.push(it);
        });
        await setWithdrawRequests(itArr);
      }
    });
    gettingOrders();

    const usersRef = ref(db, 'user/');
    onValue(usersRef, async (snapshot) => {
      const data = await snapshot.val();
      const itArr = [];
      if (data) {
        await Object.values(data).map((it) => {
          itArr.push(it);
        });
        await setUsers(itArr);
      }
    });
  }, []);
  const gettingOrders = async () => {
    const db = getDatabase();
    const orderRef = await query(ref(db, 'order/'), orderByChild('timeStamp'));
    await onValue(orderRef, async (snapshot) => {
      const data = await snapshot.val();
      const itArr = [];
      if (data) {
        await Object.values(data).map((it) => {
          itArr.push(it);
        });
        setAllOrders(itArr);
        await setObjectsByMonthYear(divideObjectsByMonth(itArr));
      }
    });
  };
  const handleStatusChange = () => {};
  async function divideObjectsByMonth(objects) {
    const objectsByMonthYear = {};
    await console.log('ok 5');
    await objects.forEach((obj) => {
      const timestamp = new Date(obj.timeStamp);
      const month = timestamp.getMonth() + 1; // Adding 1 to get 1-based month
      const year = timestamp.getFullYear();
      const key = `${month}-${year}`;
      if (objectsByMonthYear[key]) {
        objectsByMonthYear[key].push(obj);
        console.log('ok 6--');
      } else {
        objectsByMonthYear[key] = [obj];
      }
    });

    await setObjectsByMonthYear(objectsByMonthYear);
  }
  return (
    <div className="container">
      <div className="mt-3">
        <button
          className={
            state === 'stats'
              ? 'btn btn-primary me-4'
              : 'btn btn-outline-primary me-4'
          }
          onClick={() => setState('stats')}
        >
          Statistics
        </button>
        <button
          className={
            state === 'withdraw'
              ? 'btn btn-primary me-4'
              : 'btn btn-outline-primary me-4'
          }
          onClick={() => setState('withdraw')}
        >
          Withdraw Requests
        </button>
        <button
          className={
            state === 'restriction'
              ? 'btn btn-primary me-4'
              : 'btn btn-outline-primary me-4'
          }
          onClick={() => setState('restriction')}
        >
          User Restriction
        </button>
      </div>
      {state === 'stats' && (
        <div className="mt-4 ">
          <div>
            <div
              className=""
              style={{ marginLeft: 'auto', textAlign: 'right' }}
            >
              <h4>
                Total Release:{' '}
                {withdrawRequests.length &&
                  withdrawRequests.reduce((ac, cv) => {
                    if (cv.status === 1) return ac + cv.amount;
                    else return ac + 0;
                  }, 0)}{' '}
                R
              </h4>
              <h4>
                Total Income:{' '}
                {withdrawRequests.length &&
                  withdrawRequests
                    .reduce((ac, cv) => {
                      if (cv.status === 1)
                        return ac + (cv.amount / 0.98) * 0.08;
                      else return ac + 0;
                    }, 0)
                    .toFixed(2)}{' '}
                R
              </h4>
            </div>
            <hr></hr>
            <h4>Number of Orders Till Now: {allOrders.length}</h4>
            {/* <h4>Number of Orders Till Now: {objectsByMonth.timeStamp}</h4> */}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Month-Year</th>
                  <th>Number Of Orders</th>
                </tr>
              </thead>
              <tbody>
                {objectsByMonthYear['5-2023'] &&
                  Object.keys(objectsByMonthYear).map((key) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{objectsByMonthYear[key].length}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {state == 'withdraw' && (
        <div className="mt-4">
          <div style={{ overflow: 'scroll' }} className="mt-4">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Shop Tag</th>
                  <th>Account Holder Name</th>
                  <th>Account Number</th>
                  <th>Bank Name</th>
                  <th>Amount</th>
                  <th>Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {withdrawRequests.length > 0 &&
                  withdrawRequests.map((w) => (
                    <tr key={w?.id}>
                      <td>{w?.shopTag}</td>
                      <td>{w?.account?.accountHolderName}</td>
                      <td>{w?.account?.accountNumber}</td>
                      <td>{w?.account?.bankName}</td>
                      <td>{w?.amount}</td>
                      <td>{new Date(w?.timeStamp).toDateString()}</td>
                      <td>
                        {w?.status === 0 && (
                          <div>
                            <button
                              onClick={() => {
                                setAlertText(
                                  'Are you sure to accept the payment?'
                                );
                                setWorkingOn(w);
                                setShowAlert(true);
                              }}
                              className="btn btn-success"
                            >
                              Accept
                            </button>
                            <button
                              onClick={() => {
                                setAlertText(
                                  'Are you sure to cancel the payment?'
                                );
                                setWorkingOn(w);
                                setShowAlert(true);
                              }}
                              className="btn btn-danger ms-3"
                            >
                              Reject
                            </button>
                          </div>
                        )}
                        {w?.status === 1 && (
                          <div>
                            <h5>Delivered</h5>
                          </div>
                        )}
                        {w?.status === 2 && (
                          <div>
                            <h5>Canceled</h5>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {state === 'restriction' && (
        <div className="mt-4">
          <h3>Restrict Users From Here.</h3>
          <div style={{ overflow: 'scroll' }} className="mt-4">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Shop Tag</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Update Restriction</th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 &&
                  users.map((u) => (
                    <tr key={u?.id}>
                      <td>{u?.name}</td>

                      <td>{u.shopTag}</td>
                      <td>{u.email}</td>
                      <td>{u.dactive ? 'Restricted' : 'Active'}</td>

                      <td>
                        {!u.dactive ? (
                          <button
                            onClick={() => {
                              setAlertText(
                                'Are you sure to restrict the user?'
                              );
                              setWorkingOn(u);
                              setShowRestrictionAlert(true);
                            }}
                            className="btn btn-warning"
                          >
                            Restrict User
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setAlertText(
                                'Are you sure to remove the restriction?'
                              );
                              setWorkingOn(u);
                              setShowRestrictionAlert(true);
                            }}
                            className="btn btn-danger"
                          >
                            Remove Restriction
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <AlertModal
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        alertText={alertText}
        workingOn={workingOn}
      ></AlertModal>
      <RestrictAlertModal
        showRestrictionAlert={showRestrictionAlert}
        setShowRestrictionAlert={setShowRestrictionAlert}
        alertText={alertText}
        workingOn={workingOn}
      ></RestrictAlertModal>
    </div>
  );
};

export default Admin;
