import React, { useContext } from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom';
import Drawer from '../Pages/Home/Drawer';
import { AuthContext } from '../Context/AuthContext';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
function NavBar() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  
  return (
    <>
      <nav id="nav-id" className="custom-nav navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
          <img
            className="nav-logo mx-2"
            src={require('../../assets/images/icon/icon.png')}
            alt="Im"
          />

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/items">
                  Items
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/shops">
                  Shops
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/auctions">
                  Auctions
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                >
                  Manage
                </a>
              </li>
            </ul>
            <form className="d-flex" role="search">
              <button
                onClick={() => {
                  navigate('/search');
                }}
                className="me-2 btn btn-outline-info"
                type="btn"
                style={{
                  backgroundColor: '#ffffff',
                  cursor: 'pointer',
                  width: '180px',
                  color: '#0388fc',
                  border: '1px solid #0388fc',
                }}
              >
                Search
              </button>
            </form>
            {currentUser ? (
              <img
                src={currentUser.photoUrl}
                alt="user"
                className="nav-user-img"
              />
            ) : (
              <>
                <Link
                  className="btn btn-outline-primary"
                  to="/login"
                  type="submit"
                >
                  Login
                </Link>
                <Link
                  className="btn btn-outline-primary mx-2"
                  to="/signup"
                  type="submit"
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
