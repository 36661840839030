import logo from './logo.svg';
import Home from './Components/Pages/Home/Home';
import NavBar from './Components/Navigation/NavBar';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
  BrowserRouter,
} from 'react-router-dom';
import Login from './Components/Authentication/Login';
import Signup from './Components/Authentication/Signup';
import NotFound from './Components/Pages/NotFound';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useContext, createContext, useState } from 'react';
import { AuthContext } from './Components/Context/AuthContext';
import Drawer from './Components/Pages/Home/Drawer';
import Profile from './Components/Pages/DrawerPages/Profile';
import Auction from './Components/Pages/DrawerPages/Auction';
import ViewAuction from './Components/Pages/DrawerPages/ViewAuction';
import Chats from './Components/Pages/DrawerPages/Chats';
import MyShop from './Components/Pages/DrawerPages/MyShop';
import MyWallet from './Components/Pages/DrawerPages/MyWallet';
import MyOrder from './Components/Pages/DrawerPages/MyOrder';
import Favourites from './Components/Pages/DrawerPages/Favourites';
import Followings from './Components/Pages/DrawerPages/Followings';
import Settings from './Components/Pages/DrawerPages/Settings';
import Shops from './Components/Pages/OtherPages/Shops';
import MyCards from './Components/Pages/DrawerPages/MyCards';
import Items from './Components/Pages/OtherPages/Items';
import AdminPanel from './Components/Pages/Admin/AdminPanel';
import ProductDetails from './Components/Pages/ProductDetails';
import { ProductDetailsContextProvider } from './Components/Context/ProductDetailsContext';
import OrderProductPage from './Components/Pages/OrderProductPage';
import AuctionDetails from './Components/Pages/DrawerPages/AuctionDetails';
import Search from './Components/Pages/OtherPages/Search';
import AddRegularItem from './Components/Pages/DrawerPages/AddRegularItem';
import AddAuctionItem from './Components/Pages/DrawerPages/AddAuctionItem';
import ShopDetails from './Components/Pages/ShopDetails';
import SnipItPage from './Components/Pages/SnipItPage';
import OrderAuctionItem from './Components/Pages/OrderAuctionItem';
import SingleChat from './Components/Pages/SingleChat';
import Admin from './Components/Pages/DrawerPages/Admin';
import SingleCategory from './Components/Pages/Home/SingleCategory';
import useWindowDimensions from './Components/useWindowDimension';
import Nav2Bar from './Components/Navigation/Nav2Bar';
import RequireAuth from './RequireAuth';
import Footer from './Components/Navigation/Footer';
import TermsConditions from './Components/Pages/TermsConditions';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy';

function App() {
  const { currentUser } = useContext(AuthContext);
  const { height, width } = useWindowDimensions();

  return (
    <BrowserRouter>
      <div>
        {width > 700 ? (
          <div className="container">
            <NavBar />
          </div>
        ) : (
          <Nav2Bar />
        )}
      </div>
      <Drawer />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/shops" element={<Shops />} />
        <Route path="/search" element={<Search />} />

        {/*-----Routes Under Drawer------------------ */}

        <Route path="/profile" element={<Profile />} />
        <Route path="/auctions" element={<Auction />} />
        <Route
          path="/auctions/viewauction"
          element={
            <RequireAuth>
              <AuctionDetails />
            </RequireAuth>
          }
        />
        <Route path="/chats" element={<Chats />} />
        <Route path="/myshop" element={<MyShop />} />
        <Route
          path="/add-product"
          element={
            <RequireAuth>
              <AddRegularItem />
            </RequireAuth>
          }
        />
        <Route
          path="/add-auction"
          element={
            <RequireAuth>
              <AddAuctionItem />
            </RequireAuth>
          }
        />
        <Route
          path="/myorders"
          element={
            <RequireAuth>
              <MyOrder />
            </RequireAuth>
          }
        />
        <Route
          path="/wallet"
          element={
            <RequireAuth>
              <MyWallet />
            </RequireAuth>
          }
        />
        <Route
          path="/mycards"
          element={
            <RequireAuth>
              <MyCards />
            </RequireAuth>
          }
        />
        <Route
          path="/favourites"
          element={
            <RequireAuth>
              <Favourites />
            </RequireAuth>
          }
        />
        <Route
          path="/followings"
          element={
            <RequireAuth>
              <Followings />
            </RequireAuth>
          }
        />
        <Route
          path="/settings"
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        />
        <Route
          path="/admin"
          element={
            <RequireAuth>
              <AdminPanel />
            </RequireAuth>
          }
        />

        {/* Single Item Page */}
        <Route path="/item-details" element={<ProductDetails />} />

        <Route path="/shop-details" element={<ShopDetails />} />
        <Route
          path="/buy-now"
          element={
            <RequireAuth>
              <OrderProductPage />
            </RequireAuth>
          }
        />
        <Route
          path="/auction-buy-now"
          element={
            <RequireAuth>
              <OrderAuctionItem />
            </RequireAuth>
          }
        />
        <Route
          path="/snip-it-now"
          element={
            <RequireAuth>
              <SnipItPage />
            </RequireAuth>
          }
        />
        <Route
          path="/items"
          element={
            <RequireAuth>
              <Items />
            </RequireAuth>
          }
        />
        <Route
          path="/single-chat"
          element={
            <RequireAuth>
              <SingleChat></SingleChat>
            </RequireAuth>
          }
        />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/admin-xy1"
          element={
            <RequireAuth>
              <Admin></Admin>
            </RequireAuth>
          }
        />
        <Route path="/category-wise" element={<SingleCategory />} />
        {/* <Route
          path="/items/cloths"
          element={
            <div className="container mt-5">
              <Cloths />
            </div>
          }
        /> */}

        <Route element={<NotFound />} />
      </Routes>
      <div className="container">
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
