import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './common.css';
function LoadingModal(props) {
  let show = props.show;
  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Body>
          <img
            className="loadingModal"
            src="https://cdn.dribbble.com/users/2973561/screenshots/5757826/media/221d6bfc1960ab98a7585fcc2a4d0181.gif"
            alt="loading..."
          ></img>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoadingModal;
