import {
  get,
  getDatabase,
  onValue,
  ref,
  serverTimestamp,
  set,
} from 'firebase/database';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ChatIdContext } from '../Context/ChatIdConText';
import ShopDetails from './ShopDetails';
import { AuthContext } from '../Context/AuthContext';
import './ChatStyle.css';
const SingleChat = ({}) => {
  const [chatId, setChatId] = useContext(ChatIdContext);
  const [chatDetails, setChatDetails] = useState({});
  const [chats, setChats] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [message, setMessage] = useState('');
  const sendMessage = async (e) => {
    e.preventDefault();
    const db = await getDatabase();
    // await alert('wor1');
    const currentTime = Date.now();
    console.log(Date.now());
    const id = currentTime.toString();
    // await alert(id);
    const chatBoxRef = ref(db, `chat/${chatId}/messages/` + id);
    // await alert('wor2');
    if (!message) {
      alert('Write your message first !');
      return;
    }
    await set(chatBoxRef, {
      isImage: false,
      message: message,
      senderId: currentUser.id,
      timeStamp: currentTime,
    });
    // await alert('wor3');
    await setMessage('');
    var access = document.getElementById('bottomId');
    access.scrollIntoView({ behavior: 'smooth' }, true);
  };
  const db = getDatabase();
  const chatRef = ref(db, 'chat/' + chatId);
  useEffect(() => {
    onValue(chatRef, async (snapshot) => {
      const data = await snapshot.val();
      const arr = [];
      if (data) {
        console.log(data);
        console.log(data.messages);
        await setChats(data.messages);
        await setChatDetails(data);
        await console.log(chats, 'ct');
        await Object.values(data.messages).map((it) => {
          arr.push(it);
        });
        await setChats(arr);
        await console.log(chats);
        var access = document.getElementById('bottomId');
        access.scrollIntoView({ behavior: 'smooth' }, true);
      }
    });
    console.log('okokok');
  }, []);
  //   let chats = chatDetails.messages ? chatDetails.messages : [];

  return (
    <div className="container">
      SingleChat
      <h3>
        {chatDetails.sellerId === currentUser.id
          ? chatDetails.buyerName
          : chatDetails.sellerTag}
      </h3>
      <div className="chatBoxStyle">
        {chatDetails.messages &&
          chats.length &&
          chats.map((c) => (
            <div
              className="text-right"
              style={{
                textAlign: c.senderId === currentUser.id ? 'right' : 'left',
              }}
            >
              <h5
                className="m-1 p-2"
                style={{
                  // border: '0 px',
                  display: 'inline-block',
                  borderRadius: '8px',
                  borderTopRightRadius:
                    c.senderId === currentUser.id ? '0px' : '8px',
                  borderTopLeftRadius:
                    c.senderId === currentUser.id ? '8px' : '0px',
                  background: 'white',
                  boxShadow: ' 0px 0px 5px rgba(0, 0, 0, 0.2)',
                }}
              >
                {c.message}
              </h5>
            </div>
          ))}
        <div id="bottomId"></div>
      </div>
      <div className="d-flex justify-content-between  ">
        <input
          className="form-control "
          placeholder="Type your message here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></input>

        <a
          onClick={(e) => sendMessage(e)}
          className="btn btn-outline-success ms-2 w-25"
          href="#bottomId"
        >
          Send
        </a>
      </div>
      <br></br>
    </div>
  );
};

export default SingleChat;
