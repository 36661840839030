import React from 'react';

const BidCard = ({ theBid, ctime, text }) => {
  return (
    <div className="card  py-2 mt-3">
      <div className="row">
        <div className="col-2 text-center">
          {theBid?.userImage ? (
            <img
              src={theBid?.userImage}
              className="rounded-circle"
              style={{ width: '50px', height: '50px' }}
              alt="bidder"
            />
          ) : (
            <h1>UI</h1>
          )}
        </div>
        <div className="col-5 text-left">
          <h4>{theBid?.userName}</h4>
          {ctime && (
            <p>
              about{' '}
              {ctime - theBid?.timeStamp > 86400000
                ? Math.floor((ctime - theBid?.timeStamp) / 86400000) + ' D'
                : new Date(ctime - theBid?.timeStamp)
                    ?.toISOString()
                    .slice(11, 19)}
            </p>
          )}
        </div>
        <div className="col-5 text-right">
          <h4>Rs.{theBid?.biddingAmount}</h4>
          <h5>{text}</h5>
        </div>
      </div>
    </div>
  );
};

export default BidCard;
