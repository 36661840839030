import { getDatabase, ref, update } from 'firebase/database';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function EditProfile({
  showEdit,
  setShowEdit,
  currentUser,
  spName,
  spDescription,
  shopTag,
}) {
  const [name, setName] = useState(currentUser.name);
  const [shopName, setShopName] = useState(spName);
  const [shopDescription, setShopDescription] = useState(spDescription);

  const handleProfileUpdate = () => {
    const db = getDatabase();
    update(ref(db, 'shop/' + shopTag), {
      shopName: shopName,
      shopDescription: shopDescription,
    });
    update(ref(db, 'user/' + currentUser.id), {
      name: name,
    });
    setShowEdit(false);
  };

  return (
    <>
      <Modal show={showEdit} backdrop="static">
        <Modal.Body>
          <div>
            <h3 className="text-center">Edit Profile</h3>
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              className="form-control mb-2"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="shopName">Shop Name</label>
            <input
              type="text"
              className="form-control"
              id="shopName"
              placeholder="Enter Shop Name"
              value={shopName}
              onChange={(e) => setShopName(e.target.value)}
            />

            <label htmlFor="shopDescription">Shop Description</label>
            <textarea
              type="text"
              className="form-control"
              id="shopDescription"
              placeholder="Enter shop description..."
              value={shopDescription}
              onChange={(e) => setShopDescription(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-between mt-3">
            <button
              onClick={() => handleProfileUpdate()}
              className="btn btn-primary"
            >
              Save
            </button>
            <button
              onClick={() => setShowEdit(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditProfile;
