import {
  getDatabase,
  ref,
  serverTimestamp,
  set,
  update,
} from 'firebase/database';
import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';

function RestrictAlertModal({
  showRestrictionAlert,
  setShowRestrictionAlert,
  alertText,
  workingOn,
}) {
  const navigate = useNavigate();
  //   const { currentUser } = useContext(AuthContext);
  const updateStatus = async () => {
    const db = await getDatabase();
    const wRef = await ref(db, 'user/' + workingOn.id);
    await update(wRef, {
      dactive:
        alertText === 'Are you sure to restrict the user?' ? true : false,
    });
    await setShowRestrictionAlert(false);
  };
  return (
    <>
      <Modal show={showRestrictionAlert} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div>
            <h5>{alertText}</h5>

            <div className="d-flex justify-content-between">
              <button
                onClick={() => updateStatus()}
                className="btn btn-warning"
              >
                Continue
              </button>
              <button
                className="btn btn-danger"
                onClick={() => setShowRestrictionAlert(!showRestrictionAlert)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
//This is a comment
export default RestrictAlertModal;
