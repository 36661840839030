import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import { equalTo, getDatabase, orderByChild, query } from 'firebase/database';
import { ref, get, set, onValue } from 'firebase/database';
import { Button, Modal } from 'react-bootstrap';

function MyOrder() {
  const { currentUser } = useContext(AuthContext);
  const [ordered, setOrdered] = useState([]);
  const [show, setShow] = useState(false);
  const [clickedOrder, setClickedOrder] = useState(null);

  const handleClose = () => setShow(false);
  const handleYes = async () => {
    const db = await getDatabase();
    const orderRef = await ref(db, '/order/' + clickedOrder.id);
    try {
      await set(orderRef, {
        ...clickedOrder,
        status: 1,
      });
      console.log('ok');
    } catch (error) {
      console.error(error);
    }
    await handleClose();
  };
  const getOrderData = async () => {
    const db = getDatabase();
    const orderRef = ref(db, '/order/' + currentUser.id);

    try {
      const snapshot = await get(orderRef);
      const data = snapshot.val();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleOrderReceived = async (id) => {
    setShow(true);
    await setClickedOrder(id);
    await console.log(clickedOrder);
  };
  useEffect(() => {
    // console.log(currentUser.id);
    const db = getDatabase();
    const regAuctionItemRef = query(
      ref(db, 'order/'),
      orderByChild('userId'),
      equalTo(currentUser.id)
    );
    onValue(regAuctionItemRef, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
          setOrdered(itArr);
        });
        console.log('ok', itArr);
      }
    });
  }, []);
  return (
    <div className="container">
      <div>
        <h3>My Order</h3>
        <div className="row">
          {ordered.map((it) => {
            return (
              <div className="col-lg-6 col-md-12">
                <div className="card my-3">
                  <div className="card-body row">
                    <div className="col-6">
                      <img
                        src={it.imageUrl}
                        alt=""
                        className="img-fluid orderImageControl"
                      />
                    </div>
                    <div className="col-6">
                      <h2>{it.itemName}</h2>
                      <h5>
                        R {it.itemPrice} + {it.deliveryOption.fee}
                      </h5>
                      <h5>from @{it.shopTag}</h5>
                      <h5>id: {it.itemId}</h5>
                      <h5>{new Date(it.timeStamp).toLocaleString()}</h5>
                      {it.status === 0 && (
                        <button
                          onClick={() => handleOrderReceived(it)}
                          className="btn btn-outline-primary"
                        >
                          Order Received
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Order Received?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure to confirm that you received order successfully?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleYes}>
              Yes
            </Button>
            <Button variant="primary" onClick={handleClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  );
}

export default MyOrder;
