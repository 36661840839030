import React from 'react';
import { Modal } from 'react-bootstrap';
import './CommonDrawer.css';

const OrderDetailsModal = ({
  showOrderDetails,
  setShowOrderDetails,
  orderD,
}) => {
  return (
    <Modal show={showOrderDetails} size="lg" backdrop="static" keyboard={false}>
      <Modal.Body>
        <div>Order Details</div>

        <div className="p-2 mb-3 odCard">
          <h4 className="odInnerCard">User Info</h4>
          <h5>Name: {orderD.userName}</h5>
          <h5>Email: {orderD.userEmail}</h5>
        </div>
        <div className="p-2 mb-3 odCard">
          <h4 className="odInnerCard">Order Details</h4>
          <h5>
            Order Status:{' '}
            {orderD.status === 0 && (
              <span className="odInnerCard0">In - Transit</span>
            )}
            {orderD.status === 1 && (
              <span className="odInnerCard1">Delivered</span>
            )}
            {orderD.status === 2 && (
              <span className="odInnerCard2">Canceled</span>
            )}
          </h5>
          <br></br>
          <h5>Order Id: {orderD.id}</h5>
          <h5>
            Order Date: {new Date(orderD.timeStamp).toISOString().slice(0, 7)}
          </h5>
          <br></br>
          <h5>Item Name: {orderD.itemName}</h5>
          <h5>Price: {orderD.itemPrice}</h5>
          <h5>Delivery Fee: {orderD.deliveryOption.fee}</h5>
        </div>
        <div className="p-2 mb-3 odCard">
          <h4 className="odInnerCard">Payment Details</h4>
          <h5>Payment Method: {orderD.paymentMethod}</h5>
        </div>
        <div className="p-2 mb-3 odCard">
          <h4 className="odInnerCard">Delivery Details</h4>
          <h5>Delivery Option: {orderD.deliveryOption.optionName}</h5>
          <h5>Delivery Details: {orderD.deliveryOption.detailsText}</h5>
          {orderD.address.name && <h5>Name: {orderD.address.name}</h5>}
          {orderD.address.storeName && (
            <h5>Name: {orderD.address.storeName}</h5>
          )}
          {orderD.address.phoneNumber && (
            <h5>Phone Number: {orderD.address.phoneNumber}</h5>
          )}
          {orderD.address.optionalPhoneNumber && (
            <h5>
              Optional Phone Number:
              {orderD.optionalPhoneNumber}
            </h5>
          )}
        </div>
        <button
          className="btn btn-danger"
          style={{ textAlign: 'right', display: 'block' }}
          onClick={() => setShowOrderDetails(false)}
        >
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default OrderDetailsModal;
