import React, { createContext, useEffect, useState } from 'react';

export const ShopTagContext = createContext(
  JSON.parse(localStorage.getItem('currShopTag')) || null
);

export const ShopTagContextProvider = ({ children }) => {
  const [shopTag, setShopTag] = useState(
    JSON.parse(localStorage.getItem('currShopTag')) || null
  );
  useEffect(() => {
    localStorage.setItem('currShopTag', JSON.stringify(shopTag));
  }, [shopTag]);

  return (
    <ShopTagContext.Provider value={[shopTag, setShopTag]}>
      {children}
    </ShopTagContext.Provider>
  );
};
