import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
// const firebaseConfig = {
//   apiKey: 'AIzaSyAtZi3qJZ5K7XPwlDeCa-DAFq_YlyW2-r8',
//   authDomain: 'blox-t.firebaseapp.com',
//   projectId: 'blox-t',
//   storageBucket: 'blox-t.appspot.com',
//   messagingSenderId: '900325454089',
//   appId: '1:900325454089:web:3de960873e52162cfd63d8',
//   measurementId: 'G-QFBQLNHJW4',
// };

// const firebaseConfig = {
//   apiKey: 'AIzaSyAtZi3qJZ5K7XPwlDeCa-DAFq_YlyW2-r8',
//   authDomain: 'blox-t.firebaseapp.com',
//   databaseURL: 'https://blox-t-default-rtdb.firebaseio.com',
//   projectId: 'blox-t',
//   storageBucket: 'blox-t.appspot.com',
//   messagingSenderId: '900325454089',
//   appId: '1:900325454089:web:3de960873e52162cfd63d8',
//   measurementId: 'G-QFBQLNHJW4',
// };
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDqelI70vfccp3bxg71EWJGpWv9CfKA8Zw',
  authDomain: 'blox-904b4.firebaseapp.com',
  databaseURL: 'https://blox-904b4-default-rtdb.firebaseio.com',
  projectId: 'blox-904b4',
  storageBucket: 'blox-904b4.appspot.com',
  messagingSenderId: '231953860688',
  appId: '1:231953860688:web:65f94a147095160e8d6955',
  measurementId: 'G-X4SNJE3VZY',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const fs = getFirestore(app);
const storage = getStorage(app);
const db = getDatabase(app);
export { auth, fs, db, storage };
export default app;
