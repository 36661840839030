import React from 'react';
import './CommonHome.css';
// nav-pills in ul
function CategoryWiseNav() {
  return (
    <nav
      id="navbar-example2"
      className="navbar navbar-light bg-light px-3 custom-category-nav "
    >
      <a className="navbar-brand" href="#cat">
        Category-Wise
      </a>
      <ul className="nav ">
        <li className="nav-item">
          <a className="nav-link" href="#scrollspyHeading1">
            Cloths
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#scrollspyHeading2">
            Shoes
          </a>
        </li>

        <li className="nav-item">
          <a className="nav-link" href="#scrollspyHeading3">
            Electronics
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#scrollspyHeading4">
            Phones
          </a>
        </li>

        <li className="nav-item">
          <a className="nav-link" href="#scrollspyHeading6">
            Laptops
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#scrollspyHeading7">
            Tools
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#scrollspyHeading8">
            Toys
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#scrollspyHeading9">
            Collectibles
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#scrollspyHeading10">
            Garage Sale
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default CategoryWiseNav;
