import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
export default function Footer() {
  return (
    <div className="footer-container mt-3">
      <div className="footer" style={{ overflow: 'hidden' }}>
        <div className="row">
          <div className="col-lg-8  col-12">
            <h4>Platform Details:</h4>
            <p>
              Saka is a dynamic multivendor ecommerce platform in South Africa,
              revolutionizing online shopping. It offers a diverse marketplace
              with a wide range of products, supporting local businesses and
              providing a seamless shopping experience. Saka's user-friendly
              interface, secure transactions, and personalized recommendations
              enhance customer satisfaction. Join Saka's digital community to
              explore, discover, and empower local entrepreneurs in South
              Africa's thriving online shopping landscape.
            </p>
          </div>
          <div style={{ textAlign: 'right' }} className="col-lg-4 col-12">
            <h4 className="text-right">Contact Details</h4>
            <img
              src={require('../../assets/images/icon/icon.png')}
              style={{
                width: '100px',
                borderRadius: '15px',
                marginBottom: '20px',
              }}
              alt="LOGO"
            ></img>
            <h5>Email: sakasouthafrica@gmail.com</h5>
            <h5>
              <Link
                style={{ textDecoration: 'none' }}
                target="blank"
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
            </h5>
          </div>
        </div>
        <h6 className="text-center mt-4">All Rights Reserved.</h6>
      </div>
    </div>
  );
}
