import React from 'react';

function MyCards() {
  return (
    <div className="container">
      <h2 className="my-3">My Cards</h2>
    </div>
  );
}

export default MyCards;
