import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { fs } from '../../../../Config/Config';
import '../Admin.css';
function AllUser() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      let list = [];
      try {
        const querySnapshot = await getDocs(collection(fs, 'user'));
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setUsers(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchUsers();
  }, []);
  console.log(users);
  return (
    <div>
      <h1>All Users</h1>

      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Profile</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">ShopTag</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr>
              <th scope="row">1</th>
              <td>
                <img
                  className="user-profile-img-control"
                  src={user.photoUrl}
                  alt="profile"
                />
              </td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.shopTag}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AllUser;
