import React, { useContext, useEffect, useState } from 'react';
import './CommonDrawer.css';
import { Link } from 'react-router-dom';
import { getDatabase, onValue, ref, serverTimestamp } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { AuctionDetailsContext } from '../../Context/AuctionDetailsContext';

function Auction() {
  const [items, setItems] = useState([]);
  const db = getDatabase();
  const navigate = useNavigate();
  const [auctionDetails, setAuctionDetails] = useContext(AuctionDetailsContext);
  //Rem Time
  let time = new Date().getTime();
  let [ctime, setCTime] = useState(new Date().getTime());
  const updateTime = () => {
    time = new Date().getTime();
    setCTime(time);
  };
  setInterval(updateTime, 1000);

  useEffect(() => {
    const regItemRef = ref(db, 'auction_item/');
    onValue(regItemRef, (snapshot) => {
      const data = snapshot.val();
      console.log('Data', data);
      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
          setItems(itArr);
        });
      }
    });
  }, []);

  // console.log('DataOut', items);

  const handleOnClick = (item) => {
    // console.log('setProductDetails: ', setProductDetails);
    // setProductDetails(item);
    setAuctionDetails(item);
    navigate('/auctions/viewauction');
    // console.log('ProductDetails: ', productDetails);
  };

  return (
    <div className="container">
      <div className="row">
        {items
          .sort((a, b) => a.timeStamp - b.timeStamp)
          .reverse()
          .map((item) => (
            <div key={item.id} className="col-lg-6 col-md-12 col-sm-12 p-3">
              <div onClick={() => handleOnClick(item)} className="card">
                <div className="row">
                  <div className="col-5">
                    <img className="cardImage" src={item.imageUrls[0]} />
                  </div>
                  <div className="col-7 pt-4">
                    <h5>{item.itemName}</h5>
                    <h6>Starting Price: {item.price}</h6>
                    <h6>Current Price: {item.currentPrice}</h6>
                    <h6>Bids: {item.bidCount}</h6>
                    <h6>
                      Starting Time:{' '}
                      {new Date(item.timeStamp).toLocaleString('en-GB', {
                        timeZone: 'UTC',
                      })}
                    </h6>
                    <h6>
                      End Time:{' '}
                      {new Date(item.endTime).toLocaleString('en-GB', {
                        timeZone: 'UTC',
                      })}
                    </h6>
                    <h6>
                      Remaining time:{' '}
                      {item.endTime - Date.now() > 0
                        ? (item.endTime - new Date().getTime()) / 86400000 > 1
                          ? Math.floor(
                              (item.endTime - new Date().getTime()) / 86400000
                            ) + ' D'
                          : new Date(item.endTime - ctime)
                              .toISOString()
                              .slice(11, 19)
                        : 'Auction Ended'}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Auction;
