import {
  getDatabase,
  ref,
  serverTimestamp,
  set,
  update,
} from 'firebase/database';
import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AuctionDetailsContext } from '../../Context/AuctionDetailsContext';

function Bid({
  showBidModal,
  setShowBidModal,
  itemId,
  userId,
  userName,

  userImage,
  bidCount,

  setUpdatedAmount,
}) {
  //   const [name, setName] = useState(currentUser.name);
  //   const [shopName, setShopName] = useState(shopDetails.shopName);
  //   const [shopDescription, setShopDescription] = useState(
  //     shopDetails.shopDescription
  //   );
  const [auctionDetails, setAuctionDetails] = useContext(AuctionDetailsContext);
  const [selectedAmount, setSelectedAmount] = useState(
    auctionDetails.currentPrice + 50
  );
  function getRandomSixDigits() {
    return Math.floor(Math.random() * 900000) + 100000;
  }

  const handleBid = async () => {
    const db = getDatabase();
    const pdId = '124587' + new Date().getTime();
    const theBid = {
      biddingAmount: selectedAmount,
      itemId: itemId,
      userId: userId,
      userName: userName,
      userImage: userImage,
    };
    await set(ref(db, 'bid/' + pdId), {
      ...theBid,
      id: pdId,
      timeStamp: serverTimestamp(Date.now()),
    });
    await update(ref(db, 'auction_item/' + itemId), {
      bidCount: bidCount + 1,
      currentPrice: selectedAmount,
    });
    await setSelectedAmount(selectedAmount + 50);
    await setShowBidModal(false);
    await setAuctionDetails({
      ...auctionDetails,
      bidCount: bidCount + 1,
      currentPrice: selectedAmount,
    });
  };

  return (
    <>
      <Modal show={showBidModal} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div>
            <h4 className="text-bold">
              Current Bid: {auctionDetails.currentPrice}
            </h4>
            <h3>{selectedAmount}</h3>
            <h5 className="">Next Prices:</h5>
            <div className="d-flex justify-content-between mb-4">
              <button
                onClick={() =>
                  setSelectedAmount(auctionDetails.currentPrice + 50)
                }
                className={
                  selectedAmount === auctionDetails.currentPrice + 50
                    ? 'btn btn-primary'
                    : 'btn btn-outline-primary'
                }
              >
                R {auctionDetails.currentPrice + 50}
              </button>
              <button
                onClick={() =>
                  setSelectedAmount(auctionDetails.currentPrice + 100)
                }
                className={
                  selectedAmount === auctionDetails.currentPrice + 100
                    ? 'btn btn-primary'
                    : 'btn btn-outline-primary'
                }
              >
                R {auctionDetails.currentPrice + 100}
              </button>
              <button
                onClick={() =>
                  setSelectedAmount(auctionDetails.currentPrice + 150)
                }
                className={
                  selectedAmount === auctionDetails.currentPrice + 150
                    ? 'btn btn-primary'
                    : 'btn btn-outline-primary'
                }
              >
                R {auctionDetails.currentPrice + 150}
              </button>
              <button
                onClick={() =>
                  setSelectedAmount(auctionDetails.currentPrice + 200)
                }
                className={
                  selectedAmount === auctionDetails.currentPrice + 200
                    ? 'btn btn-primary'
                    : 'btn btn-outline-primary'
                }
              >
                R {auctionDetails.currentPrice + 200}
              </button>
            </div>
            <div className="d-flex justify-content-between">
              <button onClick={handleBid} className="btn btn-warning">
                Place Bid
              </button>
              <button
                className="btn btn-danger"
                onClick={() => setShowBidModal(!showBidModal)}
              >
                cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Bid;
