import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import LoadingModal from '../Common/LoadingModal';

import { idopt } from './InitialDeliveryOptions';
//Firebase Imports

import { storage } from '../../Config/Config';
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';

import {
  getDatabase,
  set,
  ref as dbRef,
  serverTimestamp,
  update,
} from 'firebase/database';
import { useNavigate } from 'react-router-dom';

function EditProductDetails({
  productDetails,
  showEditProduct,
  setShowEditProduct,
  currentUser,
}) {
  const navigate = useNavigate();

  //States
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [imgUpLoading, setImgUpLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [productImages, setProductImages] = useState(productDetails.imageUrls);
  const [selectedValue, setSelectedValue] = useState('');

  const [productName, setProductName] = useState(productDetails.itemName);
  const [productDescription, setProductDescription] = useState(
    productDetails.itemDescription
  );
  const [category, setCategory] = useState(productDetails.category);
  const [clothSize, setClothSize] = useState(productDetails.size);
  const [shoeSize, setShoeSize] = useState(productDetails.size);
  const [productQuantity, setProductQuantity] = useState(
    productDetails.quantity
  );
  const [productPrice, setProductPrice] = useState(productDetails.price);

  //Delivery Options----------------------------------------------
  const [selfCollect, setSelfCollect] = useState(false);
  const [aramex, setAramex] = useState(false);
  const [pepPaxi, setPepPaxi] = useState(false);
  const [postNet, setPostNet] = useState(false);
  const [pudo, setPudo] = useState(false);

  //Selected Options----------------------------------------------------------
  const [aramexOption, setAramexOption] = useState({});
  const [pepPaxiOption, setPepPaxiOption] = useState({});
  const [postNetOption, setPostNetOption] = useState({});
  const [pudoOption, setPudoOption] = useState({});

  const [dbOptions, setDbOptions] = useState(productDetails.deliveryOptions);

  // let mDeliveryOptions = [...idopt];
  // for (let i = 0; i < dbOptions.length; i++) {
  //   let option = dbOptions[i];
  //   let doIdx = mDeliveryOptions.findIndex(
  //     (opt) => opt.optionName === option.optionName
  //   );
  //   if (doIdx > -1) {
  //     mDeliveryOptions[doIdx].checked = true;
  //     console.log('doIdx', doIdx);
  //     let radioIdx = mDeliveryOptions[doIdx].options.findIndex(
  //       (opt) => opt.detailsText === option.detailsText
  //     );
  //     if (radioIdx > -1) {
  //       mDeliveryOptions[doIdx].options[radioIdx].checked = true;
  //       console.log('radioIdx', radioIdx);
  //     }
  //   }
  // }

  // const [deliveryOpts, setDeliveryOpts] = useState(mDeliveryOptions);
  useEffect(() => {
    dbOptions.forEach((option) => {
      if (option.optionName === 'Aramex') {
        setAramex(true);
        setAramexOption(option);
      } else if (option.optionName === 'PepPaxi') {
        setPepPaxi(true);
        setPepPaxiOption(option);
      } else if (option.optionName === 'PostNet') {
        setPostNet(true);
        setPostNetOption(option);
      } else if (option.optionName === 'PUDO') {
        setPudo(true);
        setPudoOption(option);
      } else if (option.optionName === 'Collect From Seller') {
        setSelfCollect(true);
      }
    });
  }, []);

  //Handle Select Change Functions ------------------------------------------------

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleClothSizeChange = (event) => {
    setClothSize(event.target.value);
  };
  const handleShoeSizeChange = (event) => {
    setShoeSize(event.target.value);
  };

  // Upload File
  const showUrls = () => {
    console.log(productImages);
  };
  const setProductImageUrls = async (downloadURL) => {
    try {
      const arr = [...productImages];
      arr.push(downloadURL);
      console.log(arr);
      await setProductImages(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadFile = async () => {
    if (image === null) return;
    setLoading(true);
    const name = new Date().getTime() + image.name;
    const storageRef = ref(storage, 'item_image/' + name);
    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        alert('Something Went Wrong, Failed to Upload Image');
        // setImgUpLoading(false);
        // setLoading(imgUpLoading);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          console.log('File available at', downloadURL);
          setLoading(false);
          setImgUpLoading(false);
          setProgress(0);
          await setProductImageUrls(downloadURL);
          await setImage(null);
          // setLoading(imgUpLoading);
          // setUserData({ ...userData, photoUrl: downloadURL });
        });
      }
    );
  };

  //Delete File

  const handleDelete = async (item) => {
    try {
      setLoading(true);
      const filename = item.substring(
        item.lastIndexOf('item_image%2F') + 13,
        item.lastIndexOf('?alt=media&token=')
      );
      console.log(filename);
      const storageRef = ref(storage, 'item_image/' + filename);
      await deleteObject(storageRef);
    } catch (error) {
      console.error('Error deleting image:', error);
      setLoading(false);
    } finally {
      let productIms = [...productImages];
      productIms = productIms.filter((it) => it !== item);
      setProductImages(productIms);
      setLoading(false);
    }
  };

  // Add To Database
  const addToDatabase = async (productData) => {
    const db = getDatabase();
    // const pdId = currentUser.userNumber.toString() + new Date().getTime();
    await update(dbRef(db, 'regular_item/' + productDetails.id), {
      ...productData,

      timeStamp: serverTimestamp(Date.now()),
    });

    await setLoading(false);
    await navigate('/myshop');
  };

  // Mangaing Product Data
  const manageProductData = () => {
    setLoading(true);
    let deliveryO = [];

    if (selfCollect === true)
      deliveryO.push({
        detailsText: '',
        fee: 0,
        optionName: 'Collect From Seller',
      });

    if (aramexOption.detailsText) deliveryO.push(aramexOption);
    if (pepPaxiOption.detailsText) deliveryO.push(pepPaxiOption);
    if (pudoOption.detailsText) deliveryO.push(pudoOption);
    if (postNetOption.detailsText) deliveryO.push(postNetOption);

    console.log(deliveryO);

    const productData = {
      category: category.toLowerCase(),
      size: category === 'Clothes' ? clothSize : shoeSize,
      itemName: productName,
      itemDescription: productDescription,
      quantity: parseInt(productQuantity),
      price: parseInt(productPrice),

      imageUrls: productImages,

      isPublished: true,
      deliveryOptions: deliveryO,
      userId: currentUser.id,
    };
    console.log(productData);
    addToDatabase(productData);
  };
  //Check Fields
  const checkFields = () => {
    if (productImages.length === 0) {
      alert('Please Upload Product Images');
    } else if (productName === '') {
      alert('Please Enter Product Name');
    } else if (productDescription.length < 20) {
      alert('Please Enter Product Description of at least 20 characters');
    } else if (productQuantity === 0) {
      alert('Please Enter Product Quantity');
    } else if (productPrice === 0) {
      alert('Please Enter Product Price');
    } else if (category === 'Clothes' && clothSize === '') {
      alert('Please Select Product Size');
    } else if (category === 'Shoes' && shoeSize === '') {
      alert('Please Select Product Size');
    } else if (
      selfCollect === false &&
      aramexOption.detailsText == null &&
      pepPaxiOption.detailsText == null &&
      postNetOption.detailsText == null &&
      pudoOption.detailsText == null
    ) {
      alert('Please Select Delivery Option');
    } else {
      alert('All good');

      manageProductData();
    }
  };

  // handleAddProduct
  const handleProductDetailsUpdate = async () => {
    checkFields();
  };

  //Get Data
  useEffect(() => {
    showUrls();
  }, [productImages]);

  useEffect(() => {
    uploadFile();
  }, [image]);
  useEffect(() => {
    console.log(aramexOption);
    console.log(pepPaxiOption);
    console.log(postNetOption);
    console.log(pudoOption);
    console.log(shoeSize);
    console.log(clothSize);
    console.log(productName);
    console.log(productDescription);
    console.log(category);
    console.log(productQuantity);
    console.log(productPrice);
  }, [
    aramexOption,
    pepPaxiOption,
    postNetOption,
    pudoOption,
    shoeSize,
    clothSize,
    productName,
    productDescription,
    category,
    productQuantity,
    productPrice,
  ]);

  //   const handleProductDetailsUpdate = () => {};
  return (
    <>
      <Modal
        size="xl"
        show={showEditProduct}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h3 className="text-center">Edit Product</h3>
          <div className="container">
            {/* <img src={productImages[0]} alt="igd" /> */}
            <div className="imUploadSection card px-3">
              <div className="row">
                {productImages &&
                  productImages.map((item) => {
                    return (
                      <div key={item} className="col-lg-2 col-md-3 col-sm-4">
                        <div className="card my-3 w-10 mr-2 ">
                          <img className="pdImgCrt" src={item} alt="cdi" />
                          <button
                            onClick={() => handleDelete(item)}
                            className="btn btn-outline-danger"
                          >
                            Remove Image
                          </button>
                        </div>
                      </div>
                    );
                  })}

                <div className="col-lg-2 col-md-3 col-sm-4">
                  <div className="">
                    <div className="upload-btn-wrapper ">
                      <button className="btn-product-image"></button>
                      <input
                        className="thePdImgInput"
                        onChange={(e) => setImage(e.target.files[0])}
                        type="file"
                        name="myfile"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {progress > 0 && (
                <div className="progress my-2">
                  <div
                    className="progress-bar"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              )}
            </div>

            <div className="card px-3 mt-4">
              <label className="mt-3">Name</label>
              <input
                type="text"
                placeholder="Enter Product Name"
                className="form-control"
                required
                name="name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              ></input>
              <br></br>
              <label>Product Description</label>
              <input
                type="text"
                className="form-control"
                required
                placeholder="Enter Description"
                name="description"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
              ></input>
              <br></br>
              <label htmlFor="categoryId" className="form-label">
                Category
              </label>
              <select
                className="form-select"
                id="categoryId"
                defaultValue={category}
                onChange={handleCategoryChange}
              >
                <option value="clothes">Clothes</option>
                <option value="shoes">Shoes</option>
                <option value="electronics">Electronics</option>
                <option value="phones">Phones</option>
                <option value="laptops">Laptops</option>
                <option value="toys">Toys</option>
                <option value="collectibles">Collectibles</option>
                <option value="garage Sale">Garage Sale</option>
                <option value="discounted">Discounted</option>
              </select>
              <br></br>
              {category === 'Clothes' && (
                <div>
                  <label htmlFor="clothSizeId" className="form-label">
                    Size
                  </label>
                  <select
                    className="form-select"
                    id="clothSizeId"
                    defaultValue={clothSize}
                    onChange={handleClothSizeChange}
                  >
                    <option value="XXS">XXS</option>
                    <option value="XS">XS</option>
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                    <option value="XXL">XXL</option>
                    <option value="XXXL">XXXL</option>
                  </select>
                </div>
              )}
              {category === 'Shoes' && (
                <div>
                  <label htmlFor="dropdown" className="form-label">
                    Size
                  </label>
                  <select
                    className="form-select"
                    id="shoeSizeId"
                    defaultValue={shoeSize}
                    onChange={handleShoeSizeChange}
                  >
                    <option value="4">4</option>
                    <option value="4,5">4,5</option>
                    <option value="5">5</option>
                    <option value="5,5">5,5</option>
                    <option value="6">6</option>
                    <option value="6,5">6,5</option>
                    <option value="7">7</option>
                    <option value="7,5">7,5</option>
                    <option value="8">8</option>
                    <option value="8,5">8,5</option>
                    <option value="9">9</option>
                    <option value="9,5">9,5</option>
                    <option value="10">10</option>
                    <option value="10,5">10,5</option>
                    <option value="11">11</option>
                    <option value="11,5">11,5</option>
                  </select>
                </div>
              )}
              <br></br>
              <div className="row">
                <div className="col-6">
                  <label>Quantity</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    placeholder="Quantity"
                    name="description"
                    value={productQuantity}
                    onChange={(e) => setProductQuantity(e.target.value)}
                  ></input>
                </div>

                <div className="col-6">
                  <label>Price</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    placeholder="Enter Price"
                    name="description"
                    value={productPrice}
                    onChange={(e) => setProductPrice(e.target.value)}
                  ></input>
                </div>
              </div>
              <br></br>
            </div>
            {/* <div className="card px-3 mt-4">
              <h5 className="mt-3">
                Delivery Details{' '}
                <span style={{ color: 'blue' }}>
                  [Please Select Delivery Options ]
                </span>
              </h5>
              <br></br>
              {deliveryOpts &&
                deliveryOpts.map((opt) => (
                  <>
                    <div
                      className="form-check form-switch mb-3"
                      key={opt.optionName}
                    >
                      <label
                        className="form-check-label"
                        htmlFor={opt.optionName + 'Switch'}
                      >
                        {opt.optionName}
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={opt.optionName + 'Switch'}
                        checked={
                          deliveryOpts.find(
                            (o) => o.optionName === opt.optionName
                          ).checked
                        }
                        onChange={() => {
                          console.log('switch clicked', deliveryOpts);
                          let dO = deliveryOpts;
                          dO.forEach((o) => {
                            console.log(o.optionName, opt.optionName);
                            if (o.optionName === opt.optionName) {
                              o.checked = !o.checked;
                              setDeliveryOpts(dO);
                            }
                          });
                        }}
                      />
                    </div>
                    <div className="options">
                      {opt.options.map((option) => (
                        <div
                          className="form-check form-check-inline mb-1"
                          key={opt.optionName + option.detailsText}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name={opt.optionName + 'Radio'}
                            id={opt.optionName + option.detailsText}
                            value={option.detailsText}
                            checked={option.checked}
                            onChange={() => {
                              console.log('option clicked', deliveryOpts);
                              option.checked = !option.checked;
                              // setDeliveryOpts([...deliveryOpts]);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={opt.optionName + option.detailsText}
                          >
                            &nbsp;&nbsp;{option.detailsText}
                          </label>
                        </div>
                      ))}
                    </div>
                  </>
                ))} 
              <br></br>
              
            </div> */}
            {/* ------------------------------------------------------------------------------------------------------------------- */}
            <div className="card px-3 mt-4">
              <h5 className="mt-3">Delivery Details</h5>
              <div className="form-check form-switch">
                <label className="form-check-label" htmlFor="selfCollectSwitch">
                  Self Collect
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="selfCollectSwitch"
                  checked={selfCollect}
                  onChange={() => setSelfCollect(!selfCollect)}
                />

                <br></br>
                <label className="form-check-label" htmlFor="aramexSwitch">
                  Aramex
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="aramexSwitch"
                  checked={aramex}
                  onChange={() => {
                    setAramex(!aramex);
                    setAramexOption({});
                  }}
                />

                {aramex && (
                  <form>
                    <div
                      onClick={() =>
                        setAramexOption({
                          detailsText: 'Offer free shipping (0 ZAR)',
                          fee: 0,
                          optionName: 'Aramex',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="Offer free shipping (0 ZAR)"
                          checked={
                            aramexOption.detailsText ===
                            'Offer free shipping (0 ZAR)'
                          }
                        />
                        &nbsp;&nbsp;Offer free shipping (0 ZAR)
                      </label>
                    </div>
                    <div
                      onClick={() =>
                        setAramexOption({
                          detailsText: 'Standard parcel (350x450 mm)(99 ZAR)',
                          fee: 99,
                          optionName: 'Aramex',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="Standard parcel (350x450 mm)(99 ZAR)"
                          checked={
                            aramexOption.detailsText ===
                            'Standard parcel (350x450 mm)(99 ZAR)'
                          }
                        />
                        &nbsp;&nbsp; Standard parcel (350x450 mm)(99 ZAR)
                      </label>
                    </div>
                  </form>
                )}
                <br></br>
                <label className="form-check-label" htmlFor="pepPaxiSwitch">
                  PepPaxi
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="pepPaxiSwitch"
                  checked={pepPaxi}
                  onChange={() => {
                    setPepPaxi(!pepPaxi);
                    setPepPaxiOption({});
                  }}
                />

                {pepPaxi && (
                  <form>
                    <div
                      onClick={() =>
                        setPepPaxiOption({
                          detailsText: 'Offer free shipping (0 ZAR)',
                          fee: 0,
                          optionName: 'PepPaxi',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="Offer free shipping (0 ZAR)"
                          checked={
                            pepPaxiOption.detailsText ===
                            'Offer free shipping (0 ZAR)'
                          }
                        />
                        &nbsp;&nbsp;Offer free shipping (0 ZAR)
                      </label>
                    </div>
                    <div
                      onClick={() =>
                        setPepPaxiOption({
                          detailsText: 'Standard parcel (350x450 mm)(59 ZAR)',
                          fee: 59,
                          optionName: 'PepPaxi',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="Standard parcel (350x450 mm)(59 ZAR)"
                          checked={
                            pepPaxiOption.detailsText ===
                            'Standard parcel (350x450 mm)(59 ZAR)'
                          }
                        />
                        &nbsp;&nbsp; Standard parcel (350x450 mm)(59 ZAR)
                      </label>
                    </div>
                    <div
                      onClick={() =>
                        setPepPaxiOption({
                          detailsText: 'Large parcel (640x510 mm)(99 ZAR)',
                          fee: 99,
                          optionName: 'PepPaxi',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="Large parcel (640x510 mm)(99 ZAR)"
                          checked={
                            pepPaxiOption.detailsText ===
                            'Large parcel (640x510 mm)(99 ZAR)'
                          }
                        />
                        &nbsp;&nbsp; Large parcel (640x510 mm)(99 ZAR)
                      </label>
                    </div>
                  </form>
                )}
                <br></br>
                <label className="form-check-label" htmlFor="postNetSwitch">
                  PostNet
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="postNetSwitch"
                  checked={postNet}
                  onChange={() => {
                    setPostNet(!postNet);
                    setPostNetOption({});
                  }}
                />

                {postNet && (
                  <form>
                    <div
                      onClick={() =>
                        setPostNetOption({
                          detailsText: 'Offer free shipping (0 ZAR)',
                          fee: 0,
                          optionName: 'PostNet',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="Offer free shipping (0 ZAR)"
                          checked={
                            postNetOption.detailsText ===
                            'Offer free shipping (0 ZAR)'
                          }
                        />
                        &nbsp;&nbsp; Offer free shipping (0 ZAR)
                      </label>
                    </div>
                    <div
                      onClick={() =>
                        setPostNetOption({
                          detailsText: 'Standard Parcel (up to 5kg) (109 ZAR)',
                          fee: 109,
                          optionName: 'PostNet',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="green"
                          checked={
                            postNetOption.detailsText ===
                            'Standard Parcel (up to 5kg) (109 ZAR)'
                          }
                        />
                        &nbsp;&nbsp; Standard Parcel (up to 5kg) (109 ZAR)
                      </label>
                    </div>
                  </form>
                )}

                <br></br>
                <label className="form-check-label" htmlFor="pudoSwitch">
                  PUDO
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="pudoSwitch"
                  checked={pudo}
                  onChange={() => {
                    setPudo(!pudo);
                    setPudoOption({});
                  }}
                />

                {pudo && (
                  <form>
                    <div
                      onClick={() =>
                        setPudoOption({
                          detailsText: 'Offer free shipping (0 ZAR)',
                          fee: 0,
                          optionName: 'PUDO',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="red"
                          checked={
                            pudoOption.detailsText ===
                            'Offer free shipping (0 ZAR)'
                          }
                        />
                        &nbsp;&nbsp; Offer free shipping (0 ZAR)
                      </label>
                    </div>
                    <div
                      onClick={() =>
                        setPudoOption({
                          detailsText: 'Extra-Small (600x170x80mm) (50 ZAR)',
                          fee: 50,
                          optionName: 'PUDO',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="green"
                          checked={
                            pudoOption.detailsText ===
                            'Extra-Small (600x170x80mm) (50 ZAR)'
                          }
                        />
                        &nbsp;&nbsp; Extra-Small (600x170x80mm) (50 ZAR)
                      </label>
                    </div>
                    <div
                      onClick={() =>
                        setPudoOption({
                          detailsText: 'Small (600x410x80mm) (60 ZAR)',
                          fee: 60,
                          optionName: 'PUDO',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="green"
                          checked={
                            pudoOption.detailsText ===
                            'Small (600x410x80mm) (60 ZAR)'
                          }
                        />
                        &nbsp;&nbsp; Small (600x410x80mm) (60 ZAR)
                      </label>
                    </div>
                    <div
                      onClick={() =>
                        setPudoOption({
                          detailsText: 'Medium (600x410x190mm) (60 ZAR)',
                          fee: 60,
                          optionName: 'PUDO',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="green"
                          checked={
                            pudoOption.detailsText ===
                            'Medium (600x410x190mm) (60 ZAR)'
                          }
                        />
                        &nbsp;&nbsp; Medium (600x410x190mm) (60 ZAR)
                      </label>
                    </div>
                    <div
                      onClick={() =>
                        setPudoOption({
                          detailsText: 'Large (600x410x410mm) (60 ZAR)',
                          fee: 60,
                          optionName: 'PUDO',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="green"
                          checked={
                            pudoOption.detailsText ===
                            'Large (600x410x410mm) (60 ZAR)'
                          }
                        />
                        &nbsp;&nbsp; Large (600x410x410mm) (60 ZAR)
                      </label>
                    </div>
                    <div
                      onClick={() =>
                        setPudoOption({
                          detailsText: 'Extra-Large (600x410x690mm)(60 ZAR)',
                          fee: 60,
                          optionName: 'PUDO',
                        })
                      }
                    >
                      <label>
                        <input
                          type="radio"
                          name="color"
                          value="green"
                          checked={
                            pudoOption.detailsText ===
                            'Extra-Large (600x410x690mm)(60 ZAR)'
                          }
                        />
                        &nbsp;&nbsp; Extra-Large (600x410x690mm)(60 ZAR)
                      </label>
                    </div>
                  </form>
                )}

                <br></br>
              </div>
            </div>
            {/* ======================================================================================================= */}

            <LoadingModal show={loading || imgUpLoading}></LoadingModal>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <button
              onClick={() => handleProductDetailsUpdate()}
              className="btn btn-primary"
            >
              Save
            </button>
            <button
              onClick={() => setShowEditProduct(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditProductDetails;
