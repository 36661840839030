import React, { createContext, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { AuthContextProvider } from './Components/Context/AuthContext';
import { ProductDetailsContextProvider } from './Components/Context/ProductDetailsContext';
import { AuctionDetailsContextProvider } from './Components/Context/AuctionDetailsContext';
import { ShopTagContextProvider } from './Components/Context/ShopDetailsContext';
import { ChatIdContextProvider } from './Components/Context/ChatIdConText';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      {' '}
      <ProductDetailsContextProvider>
        <AuctionDetailsContextProvider>
          <ShopTagContextProvider>
            <ChatIdContextProvider>
              <App />
            </ChatIdContextProvider>
          </ShopTagContextProvider>
        </AuctionDetailsContextProvider>
      </ProductDetailsContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
