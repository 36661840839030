import { getDatabase, ref, set, update } from 'firebase/database';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const CancelOrderModal = ({ showCancelModal, setShowCancelModal, ordD }) => {
  const handleCancel = async (ordD) => {
    const db = await getDatabase();
    const orderRef = await ref(db, '/order/' + ordD.id);
    try {
      await update(orderRef, {
        status: 2,
      });
      console.log('ok');
    } catch (error) {
      console.error(error);
    }
    await setShowCancelModal(false);
  };
  return (
    <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel The Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure to confirm that you want to cancel the order?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={() => handleCancel(ordD)}>
          Yes
        </Button>
        <Button onClick={() => setShowCancelModal(false)} variant="primary">
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelOrderModal;
