import { getDatabase, onValue, ref } from 'firebase/database';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import { ChatIdContext } from '../../Context/ChatIdConText';
import { useNavigate } from 'react-router-dom';
import './CommonDrawer.css';

const Chats = () => {
  const [allChat, setAllChat] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [chatId, setChatId] = useContext(ChatIdContext);
  const navigate = useNavigate();
  useEffect(() => {
    const db = getDatabase();
    const chatRef = ref(db, 'chat/');

    onValue(chatRef, async (snapshot) => {
      const data = await snapshot.val();
      const arr = [];
      if (data) {
        await Object.values(data).map((it) => {
          if (currentUser.id === it.buyerId || currentUser.id === it.sellerId)
            arr.push(it);
        });

        await setAllChat(arr);
      }
    });
  }, []);
  const goToChat = (id) => {
    setChatId(id);
    navigate('/single-chat');
  };
  return (
    <div className="container">
      <h4>Chats</h4>
      <div className="d-flex justify-content-between">
        {allChat.length > 0 &&
          allChat.map((sc) => (
            <div
              onClick={() => goToChat(sc.id)}
              style={{
                cursor: 'pointer',
                textAlign: 'center',

                display: 'inline-block',
                borderRadius: '10px',
                boxShadow: '0px 0px 5px rgba(0,0,0,.3)',
              }}
              key={sc.id}
              className="my-2 p-2"
            >
              {sc.buyerId === currentUser.id ? (
                <div className="">
                  <img
                    className="chImg"
                    src={sc.sellerImage}
                    alt="sellerIm"
                  ></img>
                  <h4>{sc.sellerTag}</h4>
                </div>
              ) : (
                <div className="">
                  <img
                    className="chImg"
                    src={sc.buyerImage}
                    alt="sellerIm"
                  ></img>
                  <h4>{sc.buyerName}</h4>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Chats;
