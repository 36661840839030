import {
  equalTo,
  getDatabase,
  limitToLast,
  onValue,
  orderByChild,
  query,
  ref,
} from 'firebase/database';
import React, { useEffect, useState } from 'react';
import ProductCard from '../ProductCard';

const SingleCategory = () => {
  const [items, setItems] = useState([]);
  const [category, setCategory] = useState('');
  useEffect(() => {
    const ct = localStorage.getItem('pd-category');
    const category = JSON.parse(ct);
    setCategory(category.charAt(0).toUpperCase() + category.slice(1));
    const db = getDatabase();
    const regItemRef = query(
      ref(db, 'regular_item/'),
      orderByChild('category'),
      equalTo(category),
      limitToLast(50)
    );
    onValue(regItemRef, (snapshot) => {
      const data = snapshot.val();
      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
          setItems(itArr);
        });
      }
    });
  }, []);

  return (
    <div className="container">
      {<h3>{category}</h3>}
      <div className="row">
        {items.map((item) => (
          <div
            key={item.id}
            className={
              item.isPublished
                ? 'col-6 col-md-4 col-lg-2'
                : 'col-6 col-md-4 col-lg-2 d-none'
            }
          >
            <ProductCard className="" key={item.id} item={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SingleCategory;
