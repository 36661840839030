import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../Navigation/NavBar';
import { auth, fs, storage } from '../../Config/Config';
import {
  ref as dbRef,
  getDatabase,
  set,
  onValue,
  query,
  orderByChild,
  equalTo,
  limitToFirst,
} from 'firebase/database';
import signup from '../../assets/images/signup.jpg';

import './auth.css';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { randomUserNumber } from '../Common/Methods';
import useWindowDimensions from '../useWindowDimension';
import LoadingModal from '../Common/LoadingModal';
function Signup() {
  const navigate = useNavigate();
  //--------States for Signup Form----------------------------------------------------------------------
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [file, setFile] = useState('');
  const [per, setPer] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [imgUpLoading, setImgUpLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  //--------Common User Data State----------------------------------------------------------
  const [userData, setUserData] = useState({
    fullName: '',
    email: '',
    password: '',
    photoUrl: '',
    followCount: 0,
    dactive: false,
    shopTag: '',
    userNumber: randomUserNumber(),
  });

  const { height, width } = useWindowDimensions();

  //---------File Upload---------------------------------------------------------------------------

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, 'user_image/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          setImgUpLoading(true);
          // setLoading(imgUpLoading);
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          setPer(progress);
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              break;
          }
        },
        (error) => {
          alert('Something Went Wrong, Failed to Upload Image');
          // setImgUpLoading(false);
          // setLoading(imgUpLoading);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);
            setImgUpLoading(false);
            // setLoading(imgUpLoading);
            setUserData({ ...userData, photoUrl: downloadURL });
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  //-------Check User Exist--------------------------------------------------------------------------------------------
  const checkUserAlreadyExist = async () => {
    const db = getDatabase();

    const regItemRef = await query(
      dbRef(db, 'user/'),
      orderByChild('email'),
      equalTo(userData.email)
    );
    let userExist = false;
    await onValue(regItemRef, (snapshot) => {
      const data = snapshot.val();
      // console.log(data);
      // alert('Email Already Exist');
      if (data) {
        userExist = true;
      }
    });
    return userExist;
  };
  //-------Handle Signup --------------------------------------------------------------------------------------------
  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    const cue = await checkUserAlreadyExist();
    console.log(cue);
    if (cue) {
      alert('User Already Exist');
      setLoading(false);
      return;
    }
    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        userData.email,
        userData.password
      );

      const { fullName, email, photoUrl, followCount, shopTag, userNumber } =
        userData;

      const db = getDatabase();
      await set(dbRef(db, 'user/' + res.user.uid), {
        id: res.user.uid,
        name: fullName,
        email: email,
        photoUrl: photoUrl,
        followCount: followCount,
        shopTag: shopTag,
        dactive: false,
        userNumber: userNumber,
        timeStamp: new Date(),
      });
      alert('User Created Successfully');
      setLoading(false);
      navigate('/login');
    } catch (err) {
      console.log(err.message);
    }
  };
  //----------Receiving User Data-----------------------------------------------------------------------------------------------

  const postUserData = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  //----------Component Return-----------------------------------------------------------------------------------------------
  return (
    <>
      <div className="container">
        <h1 className="mt-3">Signup</h1>
        <hr />
        {/* {successMsg && (
          <>
            <div className="success-msg">{successMsg}</div>
          </>
        )} */}
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <h1 className="mt-2">Please Provide Your Details.</h1>
            <form
              className="form-group pt-3"
              autoComplete="off"
              onSubmit={handleSignup}
            >
              {imgUpLoading && (
                <h3 className="text-warning">Image Uploading...</h3>
              )}
              <label>Profile Picture</label>
              <input
                disabled={per !== null && per < 100}
                required
                className="form-control"
                type="file"
                id="file"
                onChange={(e) => setFile(e.target.files[0])}
              ></input>
              <br></br>
              <label>Full Name</label>
              <input
                disabled={imgUpLoading}
                type="text"
                className="form-control"
                placeholder="Enter Your Full Name"
                name="fullName"
                required
                onChange={postUserData}
                value={userData.fullName}
              ></input>
              <br></br>
              <label>Email</label>
              <input
                placeholder="Enter Your Valid Email Address"
                disabled={imgUpLoading}
                type="email"
                className="form-control"
                required
                name="email"
                onChange={postUserData}
                value={userData.email}
              ></input>
              <br></br>
              <label>Password</label>
              <input
                disabled={imgUpLoading}
                type="password"
                className="form-control"
                name="password"
                required
                placeholder="Enter a Secured Password"
                onChange={postUserData}
                value={userData.password}
              ></input>
              <br></br>

              <div className="btn-box">
                <span>
                  Already have an account Login
                  <Link to="/login" className="link">
                    {' '}
                    Here
                  </Link>
                </span>
                <button
                  // disabled={per !== null && per < 100}
                  disabled={imgUpLoading}
                  type="submit"
                  className="btn btn-primary btn-md signup-button"
                >
                  SIGN UP
                </button>
              </div>
            </form>
          </div>

          {width > 1100 && (
            <div className="col-md-12 col-lg-6 mt-4">
              <img
                src={signup}
                alt="undraw-secure-login-pdn4"
                border="0"
                className="img-fluid"
              ></img>
            </div>
          )}
        </div>
        {errorMsg && (
          <>
            <br></br>
            <div className="error-msg">{errorMsg}</div>
          </>
        )}
        <LoadingModal show={loading || imgUpLoading}></LoadingModal>
      </div>
    </>
  );
}

export default Signup;

// const response = fetch(
//   'https://blox-t-default-rtdb.firebaseio.com/user.json',
//   {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       id: res.user.uid,
//       name: fullName,
//       email: email,
//       photoUrl: photoUrl,
//       followCount: followCount,
//       shopTag: shopTag,
//       userNumber: userNumber,
//       timeStamp: serverTimestamp(),
//     }),
//   }
// );
// if (response) {
//   alert('User Created Successfully');
//   setSuccessMsg('User Created Successfully');
// }

// const [fullName, setFullname] = useState('');
// const [email, setEmail] = useState('');
// const [password, setPassword] = useState('');
// const [photoUrl, setPhotoUrl] = useState('');

// await setDoc(doc(fs, 'user', res.user.uid), {
//   id: res.user.uid,
//   name: fullName,
//   email: email,
//   photoUrl: photoUrl,
//   followCount: 0,
//   shopTag: '',
//   userNumber: 0,
//   timeStamp: serverTimestamp(),
// });
