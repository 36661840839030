import React, { useContext, useEffect, useState } from 'react';
import card from '../../../assets/images/card_cvv.png';
import { AuthContext } from '../../Context/AuthContext';
import {
  ref as dbRef,
  getDatabase,
  set,
  onValue,
  query,
  orderByChild,
  equalTo,
  limitToFirst,
  limitToLast,
  update,
} from 'firebase/database';
import LoadingModal from '../../Common/LoadingModal';
function Settings() {
  const { currentUser } = useContext(AuthContext);
  const [accountInfo, setAccountInfo] = useState({});

  const [showLoading, setShowLoading] = useState(false);
  const handleSettingUpdate = async (e) => {
    e.preventDefault();
    const db = getDatabase();
    if (
      accountInfo.accountHolderName &&
      accountInfo.accountNumber &&
      accountInfo.bankName
    ) {
    } else {
      alert('Please Fill All Info');
      return;
    }
    await setShowLoading(true);
    await set(dbRef(db, 'bank_account/' + currentUser.id), {
      accountHolderName: accountInfo.accountHolderName,
      accountNumber: accountInfo.accountNumber,
      bankName: accountInfo.bankName ? accountInfo.bankName : 'Investec',
    });
    await setShowLoading(false);
  };

  useEffect(() => {
    const db = getDatabase();
    const userRef = dbRef(db, 'bank_account/' + currentUser.id);
    onValue(userRef, async (snapshot) => {
      const data = await snapshot.val();
      if (data) {
        await setAccountInfo(data);

        await console.log(accountInfo);
      } else {
      }
    });
  }, []);
  return (
    <div className="container">
      <div>
        <div
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#addAccountModal"
          style={{ cursor: 'pointer' }}
          className="card mt-5"
        >
          <div className="card-body text-center">
            <img
              style={{ height: '70px' }}
              alt="Mange Bank Account"
              src={card}
            ></img>
            <h1 className="card-title text-muted">Bank Account</h1>
          </div>
        </div>
      </div>
      {/* Account Info---------------------------------------- */}
      {accountInfo.accountHolderName && (
        <div>
          <h1>{accountInfo.accountHolderName}</h1>
          <h1>{accountInfo.accountNumber}</h1>
          <h1>{accountInfo.bankName}</h1>
        </div>
      )}
      {/*------------------- Modal for Bank Account ------------------- */}

      <div
        className="modal fade"
        id="addAccountModal"
        tabIndex="-1"
        // aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addBankModal">
                Account Information
              </h1>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>

            <div className="modal-body">
              {accountInfo && (
                <form>
                  <div className="mb-3">
                    <div className="mb-3">
                      <label htmlFor="shopName1" className="form-label">
                        Account Holder Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="shopName1"
                        placeholder="Enter the shop name"
                        value={accountInfo.accountHolderName ?? ''}
                        onChange={(e) =>
                          setAccountInfo({
                            ...accountInfo,
                            accountHolderName: e.target.value,
                          })
                        }
                        // aria-describedby="emailHelp"
                      />

                      <label htmlFor="shopTag1" className="form-label">
                        Account Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="shopTag1"
                        placeholder="Enter the Account Number"
                        value={accountInfo.accountNumber ?? ''}
                        onChange={(e) =>
                          setAccountInfo({
                            ...accountInfo,
                            accountNumber: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div></div>
                    <div className="mb-3">
                      <label htmlFor="bankNameId" className="form-label">
                        Bank Name
                      </label>
                      <select
                        className="form-select"
                        id="bankNameId"
                        value={accountInfo.bankName ?? 'Investec'}
                        onChange={(e) => {
                          setAccountInfo({
                            ...accountInfo,
                            bankName: e.target.value,
                          });
                        }}
                      >
                        <option value="Investec">Investec</option>
                        <option value="Absa">Absa</option>
                        <option value="Capitec">Capitec</option>
                        <option value="Nedbank">Nedbank</option>
                        <option value="Standard Bank">Standard Bank</option>
                        <option value="Tymbank">Tymbank</option>
                      </select>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      onClick={handleSettingUpdate}
                      className="btn btn-primary"
                      id="shopAddButton"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <LoadingModal show={showLoading}></LoadingModal>
    </div>
  );
}

export default Settings;
