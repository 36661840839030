import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import { ref, serverTimestamp } from 'firebase/database';

import {
  ref as dbRef,
  getDatabase,
  set,
  onValue,
  query,
  orderByChild,
  equalTo,
  limitToFirst,
  limitToLast,
} from 'firebase/database';
import ProductCard from '../ProductCard';
import { AuctionDetailsContext } from '../../Context/AuctionDetailsContext';
import { useNavigate } from 'react-router-dom';
import AddItemModal from '../Modals/AddItemModal';

//Import Images
import chats from '../../../assets/svgs/chats.svg';
import details from '../../../assets/images/details.png';
import conversation from '../../../assets/images/conversation.png';
import close from '../../../assets/images/close.png';
import OrderDetailsModal from './OrderDetailsModal';
import { ChatIdContext } from '../../Context/ChatIdConText';
import CancelOrderModal from './CancelOrderModal';

function MyShop() {
  const [chatId, setChatId] = useContext(ChatIdContext);
  const { currentUser } = useContext(AuthContext);
  const [innerComponent, setInnerComponent] = useState('items');
  const [tagError, setTagError] = useState(false);
  const [shopName, setShopName] = useState('');
  const [shopTag, setShopTag] = useState('');
  const [shopDesc, setShopDesc] = useState('');
  const [items, setItems] = useState([]);
  const [shop, setShop] = useState({});
  const [orders, setOrders] = useState([]);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const db = getDatabase();
  const { dispatch } = useContext(AuthContext);
  //-----From Auction-----begin-------///-------------------------------------------------------------
  const navigate = useNavigate();
  const [auctionDetails, setAuctionDetails] = useContext(AuctionDetailsContext);
  const [following, setFollowing] = useState(0);
  //Rem Time
  let time = new Date().getTime();
  let [ctime, setCTime] = useState(new Date().getTime());
  const updateTime = () => {
    time = new Date().getTime();
    setCTime(time);
  };
  setInterval(updateTime, 1000);
  const [auctionItems, setauctionItems] = useState([]);
  const handleOnClick = (item) => {
    // console.log('setProductDetails: ', setProductDetails);
    // setProductDetails(item);
    setAuctionDetails(item);
    navigate('/auctions/viewauction');
    // console.log('ProductDetails: ', productDetails);
  };

  //-----From Auction-----end-------///-------------------------------------------------------------
  useEffect(() => {
    const db = getDatabase();
    const regAuctionItemRef = query(
      dbRef(db, 'auction_item/'),
      orderByChild('shopTag'),
      equalTo(currentUser.shopTag),
      limitToLast(50)
    );
    onValue(regAuctionItemRef, (snapshot) => {
      const data = snapshot.val();
      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
          setauctionItems(itArr);
        });
      }
    });

    const userRef = dbRef(db, `shop/${currentUser.shopTag}`);
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        console.log(data);
        setShop(data);
      } else {
        // alert("Shop doesn't exist");
      }
    });

    // if (currentUser.shopTag) {
    const regItemRef = query(
      dbRef(db, 'regular_item/'),
      orderByChild('shopTag'),
      equalTo(currentUser.shopTag),
      limitToLast(50)
    );
    onValue(regItemRef, (snapshot) => {
      const data = snapshot.val();
      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
          setItems(itArr);
        });
      }
    });
    //Auction Items

    //Orders
    // if (currentUser.shopTag) {
    const orderRef = query(
      dbRef(db, 'order/'),
      orderByChild('shopTag'),
      equalTo(currentUser.shopTag),
      limitToLast(50)
    );
    onValue(orderRef, (snapshot) => {
      const data = snapshot.val();
      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
          setOrders(itArr);
        });
      }
    });

    console.log(items);

    //Following count
    const followRef = query(dbRef(db, 'follow/' + currentUser.id));
    onValue(followRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setFollowing(Object.keys(data).length);
      } else {
        setFollowing(0);
      }
    });
  }, []);

  ///----Setting Shop Name and tag
  const handleShopName = async (e) => {
    await setShopName(e.target.value);

    await setShopTag(e.target.value.replace(/\s+/g, '_'));
  };

  async function userUpdate() {
    try {
      const db = getDatabase();
      const userRef = dbRef(db, `user/${currentUser.id}`);
      await set(userRef, {
        email: currentUser.email,
        id: currentUser.id,
        name: currentUser.name,

        photoUrl: currentUser.photoUrl,
        userNumber: currentUser.userNumber,
        followCount: currentUser.followCount,
        shopTag: shopTag,
      });
      // localStorage.setItem('user', JSON.stringify({ ...currentUser, shopTag }));
      dispatch({ type: 'LOGIN', payload: { ...currentUser, shopTag } });
      console.log('Data write successful');
    } catch (error) {
      console.error('Error writing data:', error);
    }
  }

  // Updating User
  const updateUser = () => {
    userUpdate();
  };

  ///----Shop Tag Existence

  async function addShop() {
    try {
      const db = getDatabase();
      await set(dbRef(db, 'shop/' + shopTag), {
        shopName: shopName,
        shopTag: shopTag,
        shopDescription: shopDesc,
        followCount: 0,
        ownerId: currentUser.id,
        soldCount: 0,
        createdTime: serverTimestamp(Date.now()),
        imageUrl: currentUser.photoUrl,
        lastActive: serverTimestamp(Date.now()),
        shopNumber: 121123,
      });

      // await alert('Shop Created Successfully');
      await updateUser();
    } catch (error) {
      console.error('Error writing data:', error);
    }
  }

  // Adding Shop
  const handleShopAdd = async (e) => {
    try {
      e.preventDefault();

      await addShop();
    } catch (error) {
      console.log(error);
    }
  };
  //-------------------------------------------------------------- Handle Item Add ----------------------------------------------
  const handleItemAdd = async () => {
    try {
      navigate('/add-product');
      console.log('Item Added');
    } catch (error) {
      console.log(error);
    }
  };
  // -------------------------------------------------------------- Handle Auction Item Add End ----------------------------------------------
  const handleAuctionItemAdd = async () => {
    try {
      navigate('/add-auction');
      console.log('Item Added');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const db = getDatabase();
    if (currentUser.shopTag) return;
    const userRef = dbRef(
      db,
      `shop/${document.getElementById('shopTag1').value}`
    );

    onValue(userRef, async (snapshot) => {
      const data = await snapshot.val();

      if (data) {
        setTagError(true);
        document.getElementById('shopAddButton').disabled = true;
        return;
      } else {
        setTagError(false);
        document.getElementById('shopAddButton').disabled = false;
      }
    });
  }, [shopTag]);

  ////------------------------Go To Chat -------------------------------
  const gotToChat = async (ordUserId) => {
    if (currentUser.id > ordUserId) {
      await setChatId(`${currentUser.id}_${ordUserId}`);
    } else {
      await setChatId(`${ordUserId}_${currentUser.id}`);
    }
    await navigate('/single-chat');
  };

  return (
    <div className="container">
      {currentUser.shopTag ? (
        <div>
          <div>
            <div
              className="d-flex justify-content-center"
              style={{ textAlign: 'center' }}
            >
              <img
                className="mt-3"
                style={{ width: '100px', height: '100px', borderRadius: '8px' }}
                alt="sim"
                src={shop.imageUrl}
              />
            </div>
            <h2 className="d-flex justify-content-center mt-1">
              {' '}
              {currentUser.shopTag}
            </h2>
            <div className="row mt-4" style={{ textAlign: 'center' }}>
              <div className="col-md-4">
                <div className="card p-2 my-shop-card">
                  <h5>{shop.followCount}</h5>
                  <h5>Followers</h5>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="card p-2 my-shop-card bg-light">
                  <h5>{following}</h5>
                  <h5>Following</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card p-2 my-shop-card">
                  <h5>{shop.soldCount}</h5>
                  <h5>Sold</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-around mt-5">
            <button
              onClick={() => {
                setInnerComponent('items');
              }}
              className={
                innerComponent === 'items'
                  ? 'btn btn-primary mt-3'
                  : 'btn btn-outline-primary mt-3'
              }
            >
              Items
            </button>

            <button
              onClick={() => {
                setInnerComponent('auctions');
              }}
              className={
                innerComponent === 'auctions'
                  ? 'btn btn-primary mt-3'
                  : 'btn btn-outline-primary mt-3'
              }
            >
              Auctions
            </button>
            <button
              onClick={() => {
                setInnerComponent('orders');
              }}
              className={
                innerComponent === 'orders'
                  ? 'btn btn-primary mt-3'
                  : 'btn btn-outline-primary mt-3'
              }
            >
              Orders
            </button>
          </div>
          {/* ---------------------------------------------------Items ---------------------------------------------------------------------------- */}

          {innerComponent === 'items' && (
            <div>
              <h1>Items</h1>
              <div className="row">
                {items.map((item) => (
                  <div key={item.id} className="col-6 col-md-4 col-lg-2">
                    <ProductCard className="" item={item} />
                  </div>
                ))}
              </div>
              <button
                type="button"
                // data-bs-toggle="modal"
                // data-bs-target="#addItemModal1"
                className="btn-outline-primary btn myshop-innercomp-button"
                // on
                onClick={() => handleItemAdd()}
              >
                + Add Item
              </button>
            </div>
          )}
          {
            /* ---------------------------------------------------Add Item ---------------------------------------------------------------------------- */
            // <AddItemModal></AddItemModal>
          }
          {/* ---------------------------------------------------Auctions ---------------------------------------------------------------------------- */}
          {innerComponent === 'auctions' && (
            <div>
              <h1>Auctions</h1>
              <div className="row">
                {auctionItems
                  .sort((a, b) => a.timeStamp - b.timeStamp)
                  .reverse()
                  .map((item) => (
                    <div
                      key={item.id}
                      className="col-lg-6 col-md-12 col-sm-12 p-3"
                    >
                      <div onClick={() => handleOnClick(item)} className="card">
                        <div className="row">
                          <div className="col-5">
                            <img
                              className="cardImage"
                              src={item.imageUrls[0]}
                              alt="item"
                            />
                          </div>
                          <div className="col-7 pt-4">
                            <h5>{item.itemName}</h5>
                            <h6>Starting Price: {item.price}</h6>
                            <h6>Current Price: {item.currentPrice}</h6>
                            <h6>Bids: {item.bidCount}</h6>
                            <h6>
                              Starting Time:{' '}
                              {new Date(item.timeStamp).toLocaleString(
                                'en-GB',
                                {
                                  timeZone: 'UTC',
                                }
                              )}
                            </h6>
                            <h6>
                              End Time:{' '}
                              {new Date(item.endTime).toLocaleString('en-GB', {
                                timeZone: 'UTC',
                              })}
                            </h6>
                            <h6>
                              Remaining time:{' '}
                              {item.endTime - Date.now() > 0
                                ? (item.endTime - new Date().getTime()) /
                                    86400000 >
                                  1
                                  ? Math.floor(
                                      (item.endTime - new Date().getTime()) /
                                        86400000
                                    ) + ' D'
                                  : new Date(item.endTime - ctime)
                                      .toISOString()
                                      .slice(11, 19)
                                : 'Auction Ended'}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <button
                onClick={() => handleAuctionItemAdd()}
                className="btn-outline-primary btn myshop-innercomp-button"
              >
                + Add Auction
              </button>
            </div>
          )}

          {innerComponent === 'orders' && (
            <div>
              <h1>Orders</h1>
              <div className="row">
                {orders.length > 0 &&
                  orders.map((o) => (
                    <div key={o.id} className="col-12 col-md-6">
                      <div
                        style={{
                          boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
                          border: '0px solid gray',
                          borderRadius: '10px',
                          marginBottom: '20px',
                        }}
                        className="p-2"
                      >
                        <div className="row">
                          <div className="col-15 col-md-4 text-center">
                            <img
                              style={{ width: '200px', height: '170px' }}
                              src={o.imageUrl}
                              alt=""
                              borderRadius="5px"
                            ></img>
                          </div>
                          <div className="col-12 col-md-8 text-center ">
                            <h4>{o.itemName}</h4>
                            <h5>
                              R {o.itemPrice} + {o.deliveryOption.fee}
                            </h5>
                            <h5>By {o.userName}</h5>
                            <h5>ID: {o.id}</h5>
                            <h5>
                              {new Date(o.timeStamp).toISOString().slice(0, 16)}
                            </h5>
                          </div>
                        </div>
                        <div
                          style={{ position: 'relative' }}
                          className="d-flex justify-content-around  mt-2"
                        >
                          <img
                            onClick={() => setShowOrderDetails(true)}
                            style={{
                              width: '25px',
                              height: '25px',
                              cursor: 'pointer',
                            }}
                            src={details}
                            alt="chatIc"
                          ></img>
                          <img
                            onClick={() => gotToChat(o.userId)}
                            style={{
                              width: '30px',
                              height: '30px',
                              cursor: 'pointer',
                            }}
                            src={conversation}
                            alt="chatIc"
                          ></img>
                          {/* {o.status === 0 && (
                            <img
                              onClick={(o) => setShowCancelModal(true)}
                              style={{
                                width: '25px',
                                height: '25px',
                                cursor: 'pointer',
                              }}
                              src={close}
                              alt="chatIc"
                            ></img>
                          )} */}
                        </div>
                      </div>
                      <OrderDetailsModal
                        showOrderDetails={showOrderDetails}
                        setShowOrderDetails={setShowOrderDetails}
                        orderD={o}
                      ></OrderDetailsModal>
                      <CancelOrderModal
                        showCancelModal={showCancelModal}
                        setShowCancelModal={setShowCancelModal}
                        ordD={o}
                      ></CancelOrderModal>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="mt-4 row">
          <div className="col-6">
            <img
              style={{ width: '420px' }}
              className="mx-auto d-block"
              src="https://img.freepik.com/free-vector/ecommerce-campaign-concept-illustration_114360-8432.jpg?w=826&t=st=1681873539~exp=1681874139~hmac=fb503a60913ee874ed87247144edeab41b703fa4f1bd7abda260a02a29527f7d"
              alt="Add Shop"
            />
          </div>
          <div className="col-6">
            <form className="mx-auto d-block w-75 mt-5 pt-4">
              <div className="">
                <div>
                  <label htmlFor="shopName1" className="form-label">
                    Shop Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shopName1"
                    placeholder="Enter the shop name"
                    value={shopName}
                    onChange={handleShopName}
                    // aria-describedby="emailHelp"
                  />

                  <label htmlFor="shopTag1" className="form-label">
                    Shop Tag
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shopTag1"
                    placeholder="Enter the shop tag"
                    value={shopTag}
                    onChange={(e) => {
                      setShopTag(e.target.value);
                    }}
                    // aria-describedby="emailHelp"
                  />
                </div>
                <div>
                  {tagError && (
                    <p style={{ color: 'red' }}>This tag is not available.</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="shopDescription1" className="form-label">
                    Shop Description
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shopDescription1"
                    placeholder="Enter the shop description"
                    value={shopDesc}
                    onChange={(e) => setShopDesc(e.target.value)}
                    // aria-describedby="emailHelp"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <button
                  onClick={handleShopAdd}
                  className="btn btn-primary"
                  id="shopAddButton"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyShop;
