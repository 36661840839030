import React, { useContext, useEffect, useState } from 'react';
import { ProductDetailsContext } from '../Context/ProductDetailsContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import view from '../../assets/images/view.png';
import heart from '../../assets/svgs/heart.svg';
import heart_1 from '../../assets/images/heart.png';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import './ProductDetails.css';
//import firebase
import { getDatabase, ref, set, child, get, update } from 'firebase/database';

import { Navigation } from 'swiper';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';
import EditProductDetails from './EditProductDetails';
function ProductDetails() {
  const navigate = useNavigate();
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [productDetails, setProductDetails] = useContext(ProductDetailsContext);
  const { currentUser } = useContext(AuthContext);
  const [isLoved, setIsLoved] = useState(false);
  const [visible, setVisible] = useState(productDetails.isPublished);

  const increaseLoveCount = () => {
    const db = getDatabase();
    const loveRef = ref(db, '/regular_item/' + productDetails.id);
    set(loveRef, {
      ...productDetails,
      loveCount: productDetails.loveCount + 1,
    });
  };
  const decreaseLoveCount = () => {
    const db = getDatabase();
    const loveRef = ref(db, '/regular_item/' + productDetails.id);
    set(loveRef, {
      ...productDetails,
      loveCount: productDetails.loveCount - 1,
    });
  };

  const handleBuyNow = () => {
    navigate('/buy-now');
  };
  const addToLoved = async (userId, name, email, imageUrl) => {
    try {
      const db = getDatabase();
      await set(
        ref(db, `loved/${currentUser.id}/${productDetails.id}`),
        productDetails.id
      );
      setIsLoved(true);
      increaseLoveCount();
      productDetails.loveCount = productDetails.loveCount + 1;
      console.log('Data has been written successfully.');
    } catch (error) {
      console.error('Error writing data: ', error);
    }
  };
  const removeFromLoved = async () => {
    try {
      const db = getDatabase();
      await set(ref(db, `loved/${currentUser.id}/${productDetails.id}`), null);
      setIsLoved(false);
      decreaseLoveCount();
      productDetails.loveCount = productDetails.loveCount - 1;
      console.log('Data has been written successfully.');
    } catch (error) {
      console.error('Error writing data: ', error);
    }
  };

  const handleLove = () => {
    if (isLoved) {
      removeFromLoved();
    } else {
      addToLoved();
    }
  };

  //Handle Visibility
  const handleVisibility = async () => {
    const db = getDatabase();
    const vigRef = ref(db, '/regular_item/' + productDetails.id);
    try {
      await update(vigRef, {
        isPublished: !productDetails.isPublished,
      });
      await setProductDetails({
        ...productDetails,
        isPublished: !productDetails.isPublished,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const db = getDatabase();
    if (currentUser) {
      const lovedRef = ref(db, '/loved/' + currentUser.id);
      get(child(lovedRef, productDetails.id)).then((snapshot) => {
        if (snapshot.exists()) {
          setIsLoved(true);
        } else {
          setIsLoved(false);
        }
      });
    }
    // const productRef = ref(db, '/regular_item/' + productDetails.id);
    // get(productRef).then((snapshot) => {
    //   if (snapshot.exists()) {
    //     setVisible(snapshot.val().isPublished);
    //   }
    // });
    console.log(productDetails);
  }, []);
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-lg-6 ">
          <div>
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="mySwiper mt-2"
            >
              {productDetails?.imageUrls &&
                productDetails?.imageUrls.map((url) => (
                  <SwiperSlide key={url}>
                    <img className="custom-slider-style" src={url} />
                  </SwiperSlide>
                ))}
            </Swiper>{' '}
          </div>
          <h4 className="mt-2 text-center">Product Images</h4>
          <div>
            <h6 className="mt-3">Description</h6>

            <p>{productDetails.itemDescription}</p>
          </div>
        </div>
        <div style={{}} className="col-lg-6 mt-2 pt-2 ">
          <div className="row ">
            <div className="col-4">
              <span className="pd-vl-card">
                {productDetails.viewCount}
                <img className="pd-icon-control" src={view} alt="product" />
              </span>
            </div>
            <div className="col-8">
              <span onClick={handleLove} className="pd-vl-card">
                {productDetails.loveCount}
                {isLoved ? (
                  <img alt="rH" className="pd-icon-control" src={heart_1} />
                ) : (
                  <img alt="whH" className="pd-icon-control" src={heart} />
                )}
              </span>
            </div>
          </div>
          <div className=" mt-5">
            <h6 className="custom-pd-it">Item Name</h6>
            <h4 className="custom-pd-it">{productDetails.itemName}</h4>
            <h6 className="custom-pd-it">Price</h6>
            <h4 className="custom-pd-it">{productDetails.price}</h4>
            <h6 className="custom-pd-it">Category</h6>
            <h4 className="custom-pd-it">{productDetails.category}</h4>
            <h6 className="custom-pd-it">Quantity Per Order</h6>
            <h4 className="custom-pd-it">{productDetails.quantity}</h4>
          </div>

          {productDetails?.shopTag === currentUser?.shopTag ? (
            <div className="d-flex justify-content-between mt-3">
              <button
                onClick={() => setShowEditProduct(true)}
                className="btn btn-outline-primary"
              >
                Edit Product
              </button>
              <button className="btn btn-outline-success">Sold Out</button>
              <button
                onClick={handleVisibility}
                className="btn btn-outline-warning"
              >
                {productDetails.isPublished && (
                  <img
                    style={{ width: '20px' }}
                    src="https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_visibility_48px-512.png"
                    alt="visible"
                  />
                )}
                {!productDetails.isPublished && (
                  <img
                    style={{ width: '20px' }}
                    src="https://cdn.icon-icons.com/icons2/1369/PNG/512/-visibility-off_90249.png"
                    alt="not visible"
                  />
                )}
                Visibility
              </button>
              <button className="btn btn-outline-danger">Delete</button>
            </div>
          ) : (
            <div className="d-grid gap-2">
              <button
                onClick={handleBuyNow}
                className="btn btn-outline-primary"
              >
                Buy Now
              </button>
            </div>
          )}
        </div>
      </div>
      <EditProductDetails
        showEditProduct={showEditProduct}
        setShowEditProduct={setShowEditProduct}
        productDetails={productDetails}
        currentUser={currentUser}
      />
    </div>
  );
}

export default ProductDetails;
