import { getDatabase, onValue, ref } from 'firebase/database';
import React, { useState } from 'react';
import ProductCard from '../ProductCard';
import ShopWise from './ShopWise';

function Search() {
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchType, setSearchType] = useState('items');

  const handleSearch = async () => {
    if (searchType === 'items') {
      await setLoading(true);
      const db = getDatabase();
      const regItemRef = ref(db, 'regular_item/');
      await onValue(regItemRef, (snapshot) => {
        setItems([]);
        const data = snapshot.val();
        console.log('Data', data);
        let itArr = [];
        if (data !== null) {
          Object.values(data).map((it) => {
            // console.log(it.name);
            if (document.getElementById('search').value.toLowerCase() === '')
              return;
            if (
              it.itemName
                ?.toLowerCase()
                .includes(document.getElementById('search').value.toLowerCase())
            ) {
              itArr.push(it);
              console.log(it.iemtName);
            }
            setItems(itArr);
          });
        }
      });
      await setLoading(false);
    } else {
      await setLoading(true);
      // search shops
      const db = getDatabase();
      const regItemRef = ref(db, 'shop/');
      await onValue(regItemRef, (snapshot) => {
        setShops([]);
        const data = snapshot.val();
        console.log('Data', data);
        let itArr = [];
        if (data !== null) {
          Object.values(data).map((it) => {
            // console.log(it.name);
            if (document.getElementById('search').value.toLowerCase() === '')
              return;
            if (
              it.shopTag
                ?.toLowerCase()
                .includes(document.getElementById('search').value.toLowerCase())
            ) {
              itArr.push(it);
              console.log(it.iemtName);
            }
            setShops(itArr);
          });
        }
      });
      await setLoading(false);
    }
  };
  const goToShop = () => {};
  return (
    <div className="container">
      <h2 className="text-center mt-3">Searching on {searchType}</h2>
      <div className="d-flex justify-content-center mt-3">
        <input
          id="search"
          onChange={handleSearch}
          type="text"
          className="form-control w-50"
          placeholder="Search"
        />
      </div>
      <div className="d-flex justify-content-around m-3">
        <button
          onClick={() => {
            setSearchType('items');
            handleSearch();
          }}
          className="btn btn-outline-primary"
        >
          Items
        </button>
        <button
          onClick={() => {
            setSearchType('shops');
            handleSearch();
          }}
          className="btn btn-outline-primary"
        >
          Shops
        </button>
      </div>
      {loading && <h3>Loading...</h3>}
      {
        // items
        items.length > 0 && searchType === 'items' ? (
          <div className="row">
            {items.map((item) => (
              <div key={item.id} className="col-lg-2 col-md-2 mb-3">
                <ProductCard item={item} />
              </div>
            ))}
          </div>
        ) : null
      }
      {
        // items
        shops.length > 0 && searchType === 'shops' ? (
          <div className="row">
            {shops &&
              shops.map((shop) => (
                <div>
                  <div onClick={goToShop} className="mb-3 mt-5 ">
                    <div className="d-flex justify-content-between">
                      <img
                        className=""
                        style={{
                          height: '70px',
                          width: '70px',
                          borderRadius: '8px',
                        }}
                        alt="shopImage"
                        src={shop.imageUrl}
                      />

                      <div className="">
                        <h4>{shop.shopName}</h4>
                        <h5>{shop.shopTag}</h5>
                      </div>
                    </div>

                    <hr className="mt-3 mr-4"></hr>
                  </div>
                  <div>
                    <ShopWise shopTag={shop.shopTag} />
                  </div>
                </div>
              ))}
          </div>
        ) : null
      }
      {((items.length <= 0 && searchType === 'items') ||
        (shops.length <= 0 && searchType === 'shops')) && (
        <div>
          <h4 className="text-center mt-5">
            Could not find a match for the search, please try another keyword
          </h4>
          <hr></hr>
        </div>
      )}
    </div>
  );
}

export default Search;
