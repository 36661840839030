import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../../Navigation/NavBar';
import CategoryWise from './CategoryWise';
import Drawer from './Drawer';
import TopSales from './TopSales';
import './Home.css';
import Footer from '../../Navigation/Footer';
import useWindowDimensions from '../../useWindowDimension';
import { AuthContext } from '../../Context/AuthContext';
import { get, getDatabase, onValue, ref } from 'firebase/database';

function Home() {
  const { currentUser, dispatch } = useContext(AuthContext);

  useEffect(() => {
    const db = getDatabase();
    const userRef = ref(db, 'user/' + currentUser?.id);
    onValue(userRef, async (snapshot) => {
      const data = await snapshot.val();
      console.log(data);
      if (data.dactive) {
        console.log('from home', data);
        dispatch({ type: 'LOGOUT' });
      } else {
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <TopSales />
        <CategoryWise></CategoryWise>
        <a href="#nav-id" className=" float-btn-custom">
          <img
            className="top-icon-control"
            src="https://cdn-icons-png.flaticon.com/512/3272/3272638.png"
            alt="Im"
          ></img>
        </a>
      </div>
    </>
  );
}

export default Home;
