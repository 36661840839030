import React from 'react';
import './CategoryWise.css';
import CategoryWiseNav from './CategoryWiseNav';
import CatWise from './CatWise';
import { useNavigate } from 'react-router-dom';
import arrows from '../../../assets/images/arrows.png';

function CategoryWise() {
  const navigate = useNavigate();
  const handleClick = async (c) => {
    await localStorage.setItem('pd-category', JSON.stringify(c));
    await navigate('/category-wise');
  };

  const catWise = [
    {
      id: 'scrollspyHeading1',
      category: 'clothes',
    },
    {
      id: 'scrollspyHeading2',
      category: 'shoes',
    },
    {
      id: 'scrollspyHeading3',
      category: 'electronics',
    },
    {
      id: 'scrollspyHeading4',
      category: 'phones',
    },
    {
      id: 'scrollspyHeading6',
      category: 'laptops',
    },
    {
      id: 'scrollspyHeading7',
      category: 'tools',
    },
    {
      id: 'scrollspyHeading8',
      category: 'toys',
    },
    {
      id: 'scrollspyHeading9',
      category: 'collectibles',
    },
    {
      id: 'scrollspyHeading10',
      category: 'garage sale',
    },
  ];
  return (
    <div className="mt-1">
      <CategoryWiseNav />
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar-example2"
        data-bs-offset="0"
        className="scrollspy-example"
        tabIndex="1"
      >
        {catWise.map((c) => (
          <div key={c.id}>
            <div className="d-flex mt-4 justify-content-between">
              <h4 className="" id={c.id}>
                {c.category.charAt(0).toUpperCase() + c.category.slice(1)}
              </h4>
              <img
                src={arrows}
                alt="Category"
                onClick={() => handleClick(c.category)}
                style={{ cursor: 'pointer', width: '30px' }}
              ></img>
            </div>
            <CatWise category={c.category} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategoryWise;
