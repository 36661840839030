import React, { createContext, useEffect, useState } from 'react';

export const ChatIdContext = createContext(
  JSON.parse(localStorage.getItem('currentChatId')) || null
);

export const ChatIdContextProvider = ({ children }) => {
  const [chatId, setChatId] = useState(
    JSON.parse(localStorage.getItem('currentChatId')) || null
  );
  useEffect(() => {
    localStorage.setItem('currentChatId', JSON.stringify(chatId));
  }, [chatId]);

  return (
    <ChatIdContext.Provider value={[chatId, setChatId]}>
      {children}
    </ChatIdContext.Provider>
  );
};
