import { getDatabase, onValue, ref } from 'firebase/database';
import React, { useContext, useEffect, useState } from 'react';
import ShopWise from './ShopWise';
import { useNavigate } from 'react-router-dom';
import { ShopTagContext } from '../../Context/ShopDetailsContext';
import shopImg from '../../../assets/images/shop.png';

function Shops() {
  const [shopTag, setShopTag] = useContext(ShopTagContext); // [shopTag, setShopTag
  const navigate = useNavigate();
  const [shops, setShops] = useState([]);
  const db = getDatabase();
  useEffect(() => {
    const regItemRef = ref(db, 'shop/');
    onValue(regItemRef, (snapshot) => {
      const data = snapshot.val();
      console.log('Data', data);
      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
          setShops(itArr);
        });
      }
    });
  }, []);

  const goToShop = (st) => {
    setShopTag(st);
    navigate('/shop-details');
  };
  return (
    <div className="container">
      {shops &&
        shops.map((shop) => (
          <div key={shop.shopTag}>
            <div className="mb-3 mt-5 ">
              <div
                onClick={() => goToShop(shop.shopTag)}
                className="d-flex justify-content-between"
                style={{ cursor: 'pointer' }}
              >
                <img
                  className=""
                  style={{
                    height: '70px',
                    width: '70px',
                    borderRadius: '8px',
                  }}
                  alt="shopImage"
                  src={shop.imageUrl ? shop.imageUrl : shopImg}
                />

                <div className="">
                  <h4>{shop.shopName}</h4>
                  <h5>{shop.shopTag}</h5>
                </div>
              </div>

              <hr className="mt-3 mr-4"></hr>
            </div>
            <div>
              <ShopWise shopTag={shop.shopTag} />
            </div>
          </div>
        ))}
    </div>
  );
}

export default Shops;
