import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, equalTo } from 'firebase/database';
import ProductCard from '../ProductCard';
import { orderBy } from 'firebase/firestore';

function Items() {
  const [items, setItems] = useState([]);
  const db = getDatabase();
  useEffect(() => {
    const regItemRef = ref(db, 'regular_item/');
    onValue(regItemRef, async (snapshot) => {
      const data = await snapshot.val();
      await console.log('Data', data);
      let itArr = [];
      if (data !== null) {
        await Object.values(data).map((it) => {
          itArr.push(it);
        });
      }
      await setItems(itArr);
    });
  }, []);
  return (
    <div className="container">
      <h2 className="my-3">Items </h2>
      <div className="row">
        {items.map((item) => (
          <div
            // style={{ display:  'none' : '' }}
            className={
              item.isPublished
                ? 'col-6 col-md-4 col-lg-2 mb-3'
                : 'col-6 col-md-4 col-lg-2 mb-3 d-none'
            }
          >
            <ProductCard key={item.id} item={item} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Items;
