import React, { useContext, useState } from 'react';
import './Home/CommonHome.css';
import { useNavigate } from 'react-router-dom';
import { ProductDetailsContext } from '../Context/ProductDetailsContext';
import { set } from 'firebase/database';
import { getDatabase, ref } from 'firebase/database';
function ProductCard({ item }) {
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useContext(ProductDetailsContext);

  const increaseViewCount = () => {
    console.log(item);
    const db = getDatabase();
    const viewRef = ref(db, 'regular_item/' + item.id);
    set(viewRef, {
      ...item,
      viewCount: item.viewCount + 1,
    });
  };

  const handleOnClick = () => {
    setProductDetails(item);

    increaseViewCount();
    navigate('/item-details');
  };

  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={handleOnClick}
      key={item}
      className=" card mb-3"
    >
      <img
        className="card-img-control"
        alt="ProdImage"
        src={item.imageUrls[0]}
      />
      <div className="px-2 py-1 ">
        <div className="d-flex justify-content-between">
          <h6 className="d-inline ">R {item.price}</h6>
          <h6 className="d-inline "> {item.size}</h6>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
