import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 26, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              SAKA PRIVACY POLICY
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#7F7F7F' }}
            >
              Last updated&nbsp;July 16, 2023
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            This privacy notice for&nbsp;SAKA PTY(LTD)&nbsp;(doing business
            as&nbsp;SAKA)&nbsp;('<strong>we</strong>', '<strong>us</strong>', or
            '<strong>our</strong>'), describes how and why we might collect,
            store, use, and/or share ('<strong>process</strong>') your
            information when you use our services ('<strong>Services</strong>'),
            such as when you:
          </span>
        </p>
        <ul type="square" style={{ marginBottom: '0in' }}>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              Visit our website at&nbsp;
            </span>
            <span style={{ color: 'black' }}>
              <a href="http://www.saka.co.za">
                <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                  www.saka.co.za
                </span>
              </a>
            </span>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              , or any website of ours that links to this privacy notice
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              Engage with us in other related ways, including any sales,
              marketing, or events
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#7F7F7F' }}
            >
              Questions or concerns?&nbsp;
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#7F7F7F' }}
          >
            Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and
            practices, please do not use our Services. If you still have any
            questions or concerns, please contact us
            at&nbsp;sakasouthafrica23@gmail.com.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              SUMMARY OF KEY POINTS
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
              >
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our&nbsp;
              </span>
            </em>
          </strong>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#toc">
              <strong>
                <em>
                  <span
                    style={{
                      fontSize: 15,
                      fontFamily: 'Roboto',
                      textDecoration: 'none',
                    }}
                  >
                    table of contents
                  </span>
                </em>
              </strong>
            </a>
          </span>
          <strong>
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
              >
                &nbsp;below to find the section you are looking for.
              </span>
            </em>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              What personal information do we process?
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;When you visit, use, or navigate our Services, we may process
            personal information depending on how you interact with us and the
            Services, the choices you make, and the products and features you
            use. Learn more about&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#personalinfo">
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                personal information you disclose to us
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            .
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Do we process any sensitive personal information?
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;We do not process sensitive personal information.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Do we receive any information from third parties?
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;We do not receive any information from third parties.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              How do we process your information?
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;We process your information to provide, improve, and
            administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent. We process your
            information only when we have a valid legal reason to do so. Learn
            more about&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#infouse">
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                how we process your information
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            .
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              In what situations and with which&nbsp;types of&nbsp;parties do we
              share personal information?
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;We may share information in specific situations and with
            specific categories of third parties. Learn more about&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#whoshare">
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                when and with whom we share your personal information
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            .
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              How do we keep your information safe?
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;We have organisational and technical processes and procedures
            in place to protect your personal information. However, no
            electronic transmission over the internet or information storage
            technology can be guaranteed to be 100% secure, so we cannot promise
            or guarantee that hackers, cybercriminals, or other unauthorised
            third parties will not be able to defeat our security and improperly
            collect, access, steal, or modify your information. Learn more
            about&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#infosafe">
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                how we keep your information safe
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            .
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              What are your rights?
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;Depending on where you are located geographically, the
            applicable privacy law may mean you have certain rights regarding
            your personal information. Learn more about&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#privacyrights">
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                your privacy rights
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            .
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              How do you exercise your rights?
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;The easiest way to exercise your rights is by submitting
            a&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a
              href="https://app.termly.io/notify/1e9405e8-63f5-409b-9594-21b862cb6344"
              target="_blank"
            >
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                data subject access request
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            , or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            Want to learn more about what we do with any information we
            collect?&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#toc">
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                Review the privacy notice in full
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            .
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              TABLE OF CONTENTS
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#infocollect">
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                1. WHAT INFORMATION DO WE COLLECT?
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#infouse">
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                2. HOW DO WE PROCESS YOUR INFORMATION?
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#whoshare">
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#inforetain">
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                4. HOW LONG DO WE KEEP YOUR INFORMATION?
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#infosafe">
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                5. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#privacyrights">
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                6. WHAT ARE YOUR PRIVACY RIGHTS?
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#DNT">
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                7. CONTROLS FOR DO-NOT-TRACK FEATURES
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#policyupdates">
              <span
                style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
              >
                8. DO WE MAKE UPDATES TO THIS NOTICE?
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#contact">
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#request">
              <span
                style={{ fontSize: 14, fontFamily: 'Roboto', color: '#595959' }}
              >
                10. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              1. WHAT INFORMATION DO WE COLLECT?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              Personal information you disclose to us
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                In Short:&nbsp;
              </span>
            </em>
          </strong>
          <em>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              We collect personal information that you provide to us.
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            We collect personal information that you voluntarily provide to us
            when you&nbsp;register on the Services,&nbsp;express an interest in
            obtaining information about us or our products and Services, when
            you participate in activities on the Services, or otherwise when you
            contact us.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              Personal Information Provided by You.
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            &nbsp;The personal information that we collect depends on the
            context of your interactions with us and the Services, the choices
            you make, and the products and features you use. The personal
            information we collect may include the following:
          </span>
        </p>
        <ul type="square" style={{ marginBottom: '0in' }}>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              names
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              phone numbers
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              email addresses
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              usernames
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              passwords
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              debit/credit card numbers
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Sensitive Information.
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;We do not process sensitive information.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              Payment Data.
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            &nbsp;We may collect data necessary to process your payment if you
            make purchases, such as your payment instrument number, and the
            security code associated with your payment instrument. All payment
            data is stored by Yoco. You may find their privacy notice link(s)
            here:&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a
              href="https://a.storyblok.com/f/111633/x/b654f2dfd2/privacy-website-app-cookie-policies-document.pdf"
              target="_blank"
            >
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                https://a.storyblok.com/f/111633/x/b654f2dfd2/privacy-website-app-cookie-policies-document.pdf
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            .
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              Information automatically collected
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                In Short:&nbsp;
              </span>
            </em>
          </strong>
          <em>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              Some information — such as your Internet Protocol (IP) address
              and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            The information we collect includes:
          </span>
        </p>
        <ul type="square" style={{ marginBottom: '0in' }}>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                Log and Usage Data.
              </span>
            </em>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              &nbsp;Log and usage data is service-related, diagnostic, usage,
              and performance information our servers automatically collect when
              you access or use our Services and which we record in log files.
              Depending on how you interact with us, this log data may include
              your IP address, device information, browser type, and settings
              and information about your activity in the Services (such as the
              date/time stamps associated with your usage, pages and files
              viewed, searches, and other actions you take such as which
              features you use), device event information (such as system
              activity, error reports (sometimes called 'crash dumps'), and
              hardware settings).
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                Device Data.
              </span>
            </em>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              &nbsp;We collect device data such as information about your
              computer, phone, tablet, or other device you use to access the
              Services. Depending on the device used, this device data may
              include information such as your IP address (or proxy server),
              device and application identification numbers, location, browser
              type, hardware model, Internet service provider and/or mobile
              carrier, operating system, and system configuration information.
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                Location Data.
              </span>
            </em>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              &nbsp;We collect location data such as information about your
              device's location, which can be either precise or imprecise. How
              much information we collect depends on the type and settings of
              the device you use to access the Services. For example, we may use
              GPS and other technologies to collect geolocation data that tells
              us your current location (based on your IP address). You can opt
              out of allowing us to collect this information either by refusing
              access to the information or by disabling your Location setting on
              your device. However, if you choose to opt out, you may not be
              able to use certain aspects of the Services.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                In Short:&nbsp;
              </span>
            </em>
          </strong>
          <em>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </span>
          </strong>
        </p>
        <ul type="square" style={{ marginBottom: '0in' }}>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                To facilitate account creation and authentication and otherwise
                manage user accounts.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                To deliver and facilitate delivery of services to the
                user.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              We may process your information to provide you with the requested
              service.
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                To enable user-to-user communications.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              We may process your information if you choose to use any of our
              offerings that allow for communication with another user.
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                To request feedback.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              We may process your information when necessary to request feedback
              and to contact you about your use of our Services.
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                To send you marketing and promotional communications.&nbsp;
              </span>
            </strong>
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              We may process the personal information you send to us for our
              marketing purposes, if this is in accordance with your marketing
              preferences. You can opt out of our marketing emails at any time.
              For more information, see&nbsp;'
            </span>
            <span style={{ color: 'black' }}>
              <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#privacyrights">
                <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                  WHAT ARE YOUR PRIVACY RIGHTS?
                </span>
              </a>
            </span>
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              '&nbsp;below.
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                To deliver targeted advertising to you.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              We may process your information to develop and
              display&nbsp;personalised&nbsp;content and advertising tailored to
              your interests, location, and more.
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                To protect our Services.
              </span>
            </strong>
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              &nbsp;We may process your information as part of our efforts to
              keep our Services safe and secure, including fraud monitoring and
              prevention.
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                To evaluate and improve our Services, products, marketing, and
                your experience.
              </span>
            </strong>
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              &nbsp;We may process your information when we believe it is
              necessary to identify usage trends, determine the effectiveness of
              our promotional campaigns, and to evaluate and improve our
              Services, products, marketing, and your experience.
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                To determine the effectiveness of our marketing and promotional
                campaigns.
              </span>
            </strong>
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              &nbsp;We may process your information to better understand how to
              provide marketing and promotional campaigns that are most relevant
              to you.
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                To comply with our legal obligations.
              </span>
            </strong>
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              &nbsp;We may process your information to comply with our legal
              obligations, respond to legal requests, and exercise, establish,
              or defend our legal rights.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </span>
          </strong>
          <span style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}>
            &nbsp;
          </span>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            &nbsp; &nbsp;
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#7F7F7F' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                In Short:
              </span>
            </em>
          </strong>
          <em>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              &nbsp;We may share information in specific situations described in
              this section and/or with the following categories of third
              parties.
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Vendors, Consultants, and Other Third-Party Service Providers.
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;We may share your data with third-party vendors, service
            providers, contractors, or agents ('<strong>third parties</strong>')
            who perform services for us or on our behalf and require access to
            such information to do that work.&nbsp;The&nbsp;categories
            of&nbsp;third parties we may share personal information with are as
            follows:
          </span>
        </p>
        <ul type="square" style={{ marginBottom: '0in' }}>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              Data Analytics Services
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            We&nbsp;also&nbsp;may need to share your personal information in the
            following situations:
          </span>
        </p>
        <ul type="square" style={{ marginBottom: '0in' }}>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                Business Transfers.
              </span>
            </strong>
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              &nbsp;We may share or transfer your information in connection
              with, or during negotiations of, any merger, sale of company
              assets, financing, or acquisition of all or a portion of our
              business to another company.
            </span>
          </li>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <strong>
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                Business Partners.
              </span>
            </strong>
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              &nbsp;We may share your information with our business partners to
              offer you certain products, services, or promotions.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              4. HOW LONG DO WE KEEP YOUR INFORMATION?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                In Short:&nbsp;
              </span>
            </em>
          </strong>
          <em>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              We keep your information for as long as necessary
              to&nbsp;fulfil&nbsp;the purposes outlined in this privacy notice
              unless otherwise required by law.
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements).&nbsp;No purpose in
            this notice will require us keeping your personal information for
            longer than&nbsp;three (3)&nbsp;months past the termination of the
            user's account&nbsp;.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete
            or&nbsp;anonymise&nbsp;such information, or, if this is not possible
            (for example, because your personal information has been stored in
            backup archives), then we will securely store your personal
            information and isolate it from any further processing until
            deletion is possible.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              5. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                In Short:&nbsp;
              </span>
            </em>
          </strong>
          <em>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              We aim to protect your personal information through a system
              of&nbsp;organisational&nbsp;and technical security measures.
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            We have implemented appropriate and reasonable technical
            and&nbsp;organisational&nbsp;security measures designed to protect
            the security of any personal information we process. However,
            despite our safeguards and efforts to secure your information, no
            electronic transmission over the Internet or information storage
            technology can be guaranteed to be 100% secure, so we cannot promise
            or guarantee that hackers, cybercriminals, or
            other&nbsp;unauthorised&nbsp;third parties will not be able to
            defeat our security and improperly collect, access, steal, or modify
            your information. Although we will do our best to protect your
            personal information, transmission of personal information to and
            from our Services is at your own risk. You should only access the
            Services within a secure environment.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              6. WHAT ARE YOUR PRIVACY RIGHTS?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                In Short:
              </span>
            </em>
          </strong>
          <em>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              &nbsp; You may review, change, or terminate your account at any
              time.
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              Withdrawing your consent:
            </span>
          </strong>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            &nbsp;If we are relying on your consent to process your personal
            information, which may be express and/or implied consent depending
            on the applicable law, you have the right to withdraw your consent
            at any time. You can withdraw your consent at any time by contacting
            us by using the contact details provided in the section '
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://app.termly.io/dashboard/website/b1bfe6a5-fd37-4d4d-b330-d1290bbeaf47/privacy-policy#contact">
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            '&nbsp;below.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor,&nbsp;when applicable law
            allows,&nbsp;will it affect the processing of your personal
            information conducted in reliance on lawful processing grounds other
            than consent.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
            >
              Account Information
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </span>
        </p>
        <ul type="square" style={{ marginBottom: '0in' }}>
          <li
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              lineHeight: 'normal',
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              color: '#6C7794',
              background: 'white',
            }}
          >
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
              Log in to your account settings and update your user account.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            If you have questions or comments about your privacy rights, you may
            email us at&nbsp;sakasouthafrica23@gmail.com.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              7. CONTROLS FOR DO-NOT-TRACK FEATURES
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ('DNT') feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard
            for&nbsp;recognising&nbsp;and implementing DNT signals has
            been&nbsp;finalised. As such, we do not currently respond to DNT
            browser signals or any other mechanism that automatically
            communicates your choice not to be tracked online. If a standard for
            online tracking is adopted that we must follow in the future, we
            will inform you about that practice in a revised version of this
            privacy notice.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              8. DO WE MAKE UPDATES TO THIS NOTICE?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <em>
              <span
                style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
              >
                In Short:&nbsp;
              </span>
            </em>
          </strong>
          <em>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
            >
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated&nbsp;'Revised'&nbsp;date and
            the updated version will be effective as soon as it is accessible.
            If we make material changes to this privacy notice, we may notify
            you either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            If you have questions or comments about this notice, you
            may&nbsp;email us at&nbsp;sakasouthafrica23@gmail.com&nbsp;or
            contact us by post at:
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            SAKA PTY(LTD)
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            20 Churchill Avenue
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            Germiston
          </span>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            ,&nbsp;Gauteng&nbsp;1401
          </span>
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            South Africa
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <strong>
            <span
              style={{ fontSize: 15, fontFamily: 'Roboto', color: 'black' }}
            >
              10. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: 15, fontFamily: 'Roboto', color: '#595959' }}
          >
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please fill out and submit
            a&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a
              href="https://app.termly.io/notify/1e9405e8-63f5-409b-9594-21b862cb6344"
              target="_blank"
            >
              <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                data subject access request
              </span>
            </a>
          </span>
          <span
            style={{ fontSize: 14, fontFamily: 'Roboto', color: '#6C7794' }}
          >
            .
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '8.0pt',
            marginLeft: '0in',
            lineHeight: '107%',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
      </>
    </div>
  );
};

export default PrivacyPolicy;
