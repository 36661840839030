import { get, getDatabase, onValue, ref } from 'firebase/database';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ShopTagContext } from '../../Context/ShopDetailsContext';
import ShopWise from '../OtherPages/ShopWise';
import shopImg from '../../../assets/images/shop.png';

function Followings() {
  const [shopTag, setShopTag] = useContext(ShopTagContext);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [shops, setShops] = useState([]);
  useEffect(() => {
    const db = getDatabase();
    const lovedRef = ref(db, '/follow/' + currentUser.id);
    onValue(lovedRef, (snapshot) => {
      const data = snapshot.val();
      let itArr = [];
      if (data) {
        Object.values(data).map(async (it) => {
          const itemRef = ref(db, '/shop/' + it);
          const snapshot = await get(itemRef);
          const itemData = snapshot.val();
          itArr = [...itArr, itemData];
          setShops(itArr);
        });
      }
    });
  }, []);
  const goToShop = (st) => {
    setShopTag(st);
    navigate('/shop-details');
  };
  return (
    <div className="container">
      <h4 className="mt-3">Followings</h4>
      {shops &&
        shops.map((shop) => (
          <div key={shop.shopTag}>
            <div className="mb-3 mt-5 ">
              <div
                onClick={() => goToShop(shop.shopTag)}
                className="d-flex justify-content-between"
                style={{ cursor: 'pointer' }}
              >
                <img
                  className=""
                  style={{
                    height: '70px',
                    width: '70px',
                    borderRadius: '8px',
                  }}
                  alt="shopImage"
                  src={shop.imageUrl ? shop.imageUrl : shopImg}
                />

                <div className="">
                  <h4>{shop.shopName}</h4>
                  <h5>{shop.shopTag}</h5>
                </div>
              </div>

              <hr className="mt-3 mr-4"></hr>
            </div>
            <div>
              <ShopWise shopTag={shop.shopTag} />
            </div>
          </div>
        ))}
    </div>
  );
}

export default Followings;
