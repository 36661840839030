import React, { useEffect, useState } from 'react';
import {
  getDatabase,
  ref,
  onValue,
  equalTo,
  query,
  orderByChild,
  limitToLast,
} from 'firebase/database';
import { orderBy } from 'firebase/firestore';
import './CategoryWise.css';
import ProductCard from '../ProductCard';
function CatWise({ category }) {
  const [items, setItems] = useState([]);
  const db = getDatabase();
  useEffect(() => {
    const regItemRef = query(
      ref(db, 'regular_item/'),
      orderByChild('category'),
      equalTo(category),
      limitToLast(50)
    );
    onValue(regItemRef, (snapshot) => {
      const data = snapshot.val();
      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
          setItems(itArr);
        });
      }
    });
  }, []);
  return (
    <>
      <div className="" style={{ minHeight: '265px', overflow: 'hidden' }}>
        <div className="row">
          {items.length === 0 && (
            <div className="d-flex justify-content-around">
              <img
                style={{ width: '264px', height: '264px' }}
                src="https://cdn.dribbble.com/users/1186261/screenshots/3718681/_______.gif"
                alt="img"
              />
              <img
                style={{ width: '264px', height: '264px' }}
                src="https://cdn.dribbble.com/users/1186261/screenshots/3718681/_______.gif"
                alt="img"
              />
              <img
                style={{ width: '264px', height: '264px' }}
                src="https://cdn.dribbble.com/users/1186261/screenshots/3718681/_______.gif"
                alt="img"
              />
              <img
                style={{ width: '264px', height: '264px' }}
                src="https://cdn.dribbble.com/users/1186261/screenshots/3718681/_______.gif"
                alt="img"
              />
              <img
                style={{ width: '264px', height: '264px' }}
                src="https://cdn.dribbble.com/users/1186261/screenshots/3718681/_______.gif"
                alt="img"
              />
              {/* <img
                style={{ width: '264px', height: '264px' }}
                src="https://i.pinimg.com/originals/25/ef/28/25ef280441ad6d3a5ccf89960b4e95eb.gif"
                alt="img"
              /> */}
            </div>
          )}
          {items.map((item) => (
            <div
              key={item.id}
              className={
                item.isPublished
                  ? 'col-6 col-md-4 col-lg-2'
                  : 'col-6 col-md-4 col-lg-2 d-none'
              }
            >
              <ProductCard className="" key={item.id} item={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CatWise;
