import React, { useContext, useEffect, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AuctionDetailsContext } from '../../Context/AuctionDetailsContext';
import './AuctionDetails.css';
import {
  equalTo,
  getDatabase,
  off,
  onValue,
  orderByChild,
  query,
  ref,
  limitToLast,
  get,
  set,
} from 'firebase/database';
import { AuthContext } from '../../Context/AuthContext';
import BidCard from './BidCard';
import Bid from './Bid';
import SnipItAlert from './SnipItAlert';
import SnipItPage from '../SnipItPage';
import { useNavigate } from 'react-router-dom';

function AuctionDetails() {
  const [allBids, setAllBids] = useState([]);
  const [auctionDetails, setAuctionDetails] = useContext(AuctionDetailsContext);
  const { currentUser } = useContext(AuthContext);
  const [letestUserBid, setLetestUserBid] = useState(0);
  const [showBidModal, setShowBidModal] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showSnipItAlert, setShowSnipItAlert] = useState(false);
  const [showSnipItPage, setShowSnipItPage] = useState(false);

  const [updatedAmount, setUpdatedAmount] = useState(0);
  const navigate = useNavigate();
  //Rem Time
  let time = new Date().getTime();
  let [ctime, setCTime] = useState(new Date().getTime());
  const updateTime = () => {
    time = new Date().getTime();
    setCTime(time);
  };
  setInterval(updateTime, 1000);

  // ----------------All Bids-----------------------
  const db = getDatabase();
  useEffect(() => {
    const regItemRef = query(
      ref(db, 'bid/'),
      orderByChild('itemId'),
      equalTo(auctionDetails.id),

      limitToLast(5)
    );
    onValue(regItemRef, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
        });
        setAllBids(
          itArr.sort((a, b) => b.biddingAmount - a.biddingAmount).slice(0, 3)
        );
        setShowButtons(true);
      } else {
        setAllBids([]);
        setShowButtons(true);
      }
    });
    console.log(ctime);
  }, []);
  useEffect(() => {
    console.log(allBids);
    if (allBids[0]) {
      let theBid = allBids.find((element) => element.userId === currentUser.id);
      setLetestUserBid(theBid ? theBid.biddingAmount : 0);
    }
  }, [allBids]);

  const getMyBiddingAmount = async () => {
    let myBid = allBids.findIndex((obj) => obj.userId === currentUser.id);
    console.log(myBid);
    return myBid > -1 ? allBids[myBid].biddingAmount : 0;
  };
  //Update Auction Details--------------------------bid Count-------------------

  //Place Bid---------------------------------------------------------------

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mySwiper mt-4"
          >
            {auctionDetails.imageUrls &&
              auctionDetails.imageUrls.map((url) => (
                <SwiperSlide key={url}>
                  <img
                    className="custom-slider-style"
                    src={url}
                    alt="slideImage"
                  />
                </SwiperSlide>
              ))}
          </Swiper>{' '}
          <h4 className="mt-2 text-center">Product Images</h4>
          <h6>Description</h6>
          <p>{auctionDetails.description}</p>
        </div>
        <div className="col-lg-6 mt-5 pt-3 ">
          <div className=" mt-5 row ">
            <div className="col-lg-6 text-left">
              <h6 className="">Item Name</h6>
              <h4 className="">{auctionDetails.itemName}</h4>

              <h6 className="">Price</h6>
              <h4 className="">{auctionDetails.price}</h4>
            </div>
            <div className="col-lg-6">
              <h6 className="custom-pd-it">Category</h6>
              <h4 className="custom-pd-it">{auctionDetails.category}</h4>
              <h6 className="custom-pd-it">Available</h6>
              <h4 className="custom-pd-it">{auctionDetails.quantity}</h4>
            </div>
          </div>

          <div className="row">
            <div className=" mb-3">
              <h4>Remaining Time</h4>
              <div className="card  py-2">
                {!auctionDetails.forceOver ? (
                  <h1 className="text-center">
                    {auctionDetails.endTime - Date.now() > 0
                      ? (auctionDetails.endTime - new Date().getTime()) /
                          86400000 >
                        1
                        ? Math.floor(
                            (auctionDetails.endTime - new Date().getTime()) /
                              86400000
                          ) + ' D'
                        : new Date(auctionDetails.endTime - ctime)
                            ?.toISOString()
                            .slice(11, 19)
                      : 'Auction Ended'}
                  </h1>
                ) : (
                  <h1 className="text-center">Auction Ended</h1>
                )}

                <div
                  style={{ border: '1px solid #6f94e8', borderRadius: '5px' }}
                  className="d-flex justify-content-between p-2 mx-2"
                >
                  <div className="text-left">
                    <h5>Top Bid</h5>
                    <h6>R {allBids[0] ? allBids[0].biddingAmount : 0}</h6>
                  </div>
                  <div className="text-right">
                    <h5>Your Latest Bid</h5>
                    <h6>
                      R{' '}
                      {/* {allBids[0] &&
                        currentUser &&
                        allBids.find((obj) => obj.userId === currentUser.id)
                          .biddingAmount}{' '} */}
                      {/* {() => getMyBiddingAmount()} */}
                      {letestUserBid}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <h3>
                Bids{' '}
                <span
                  style={{
                    border: '1px solid #12A5DC',
                    borderRadius: '5px',
                    padding: '1px',
                  }}
                >
                  {auctionDetails.bidCount}
                </span>
              </h3>
              <h3>Top Bids</h3>
            </div>
            <div className="mb-3">
              {!showButtons && <h3>Loading...</h3>}
              {allBids[0] && (
                <>
                  {allBids[0] && (
                    <BidCard
                      theBid={allBids[0]}
                      ctime={ctime}
                      text="Top Bid"
                    ></BidCard>
                  )}
                  {allBids[1] && (
                    <BidCard
                      theBid={allBids[1]}
                      ctime={ctime}
                      text="Almost get it"
                    ></BidCard>
                  )}
                  {allBids[2] && (
                    <BidCard
                      theBid={allBids[2]}
                      ctime={ctime}
                      text="So Close"
                    ></BidCard>
                  )}
                </>
              )}
            </div>

            {showButtons &&
              auctionDetails.endTime - Date.now() > 0 &&
              !auctionDetails.forceOver &&
              auctionDetails.userId !== currentUser.id && (
                <>
                  <div className="col-6 d-grid gap-2">
                    <button
                      onClick={() => setShowBidModal(!showBidModal)}
                      className="btn btn-outline-primary "
                    >
                      Place Bid
                    </button>
                  </div>
                  <div className="col-6 d-grid gap-2">
                    <button
                      onClick={() => setShowSnipItAlert(!showSnipItAlert)}
                      className="btn btn-outline-primary "
                    >
                      Snip it now
                    </button>
                  </div>
                </>
              )}
            {auctionDetails.endTime - Date.now() < 0 &&
              allBids[0] &&
              letestUserBid == allBids[0]?.biddingAmount &&
              !auctionDetails.forceOver && (
                <button
                  onClick={() => navigate('/auction-buy-now')}
                  className="btn btn-success"
                >
                  Buy Now
                </button>
              )}
          </div>
        </div>
      </div>

      <Bid
        showBidModal={showBidModal}
        setShowBidModal={setShowBidModal}
        itemId={auctionDetails.id}
        userId={currentUser.id}
        userImage={currentUser.photoUrl}
        userName={currentUser.name}
        currentPrice={
          allBids[0] ? allBids[0].biddingAmount : auctionDetails.price
        }
        bidCount={auctionDetails.bidCount}
        setUpdatedAmount={setUpdatedAmount}
      ></Bid>

      <SnipItAlert
        showSnipItAlert={showSnipItAlert}
        setShowSnipItAlert={setShowSnipItAlert}
        currentPrice={
          allBids[0] ? allBids[0].biddingAmount : auctionDetails.price
        }
        showSnipItPage={showSnipItPage}
        setShowSnipItPage={setShowSnipItPage}
      ></SnipItAlert>
    </div>
  );
}

export default AuctionDetails;
