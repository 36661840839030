import React, { createContext, useEffect, useState } from 'react';

export const AuctionDetailsContext = createContext(
  JSON.parse(localStorage.getItem('currentAuction')) || null
);

export const AuctionDetailsContextProvider = ({ children }) => {
  const [auctionDetails, setAuctionDetails] = useState(
    JSON.parse(localStorage.getItem('currentAuction')) || null
  );
  useEffect(() => {
    localStorage.setItem('currentAuction', JSON.stringify(auctionDetails));
  }, [auctionDetails]);

  return (
    <AuctionDetailsContext.Provider value={[auctionDetails, setAuctionDetails]}>
      {children}
    </AuctionDetailsContext.Provider>
  );
};
