import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import {
  equalTo,
  getDatabase,
  limitToLast,
  onValue,
  orderByChild,
  query,
  ref,
} from 'firebase/database';
import './CommonDrawer.css';
import PayToBankModal from './PayToBankModal';
function MyWallet() {
  const { currentUser } = useContext(AuthContext);
  const [myShopOrders, setMyShopOrders] = useState([]);
  const [previousPayments, setPreviousPayments] = useState([]);
  const [showPayToBankModal, setShowPayToBankModal] = useState(false);
  useEffect(() => {
    const db = getDatabase();
    const orderRef = query(
      ref(db, 'order/'),
      orderByChild('shopTag'),
      equalTo(currentUser.shopTag),
      limitToLast(50)
    );
    onValue(orderRef, (snapshot) => {
      const data = snapshot.val();
      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
          setMyShopOrders(itArr);
        });
      }
    });
    const withdrawRef = query(
      ref(db, 'withdraw/'),
      orderByChild('shopTag'),
      equalTo(currentUser.shopTag),
      limitToLast(50)
    );
    onValue(withdrawRef, async (snapshot) => {
      const data = await snapshot.val();
      let iArr = [];
      if (data !== null) {
        await Object.values(data).map((it) => {
          iArr.push(it);
        });
        console.log(iArr);
        await setPreviousPayments(iArr);
      }
    });
  }, []);
  const checkAccountExist = async () => {
    const db = getDatabase();
    const bancAccountRef = ref(db, 'bank_account/' + currentUser.id);
    await onValue(bancAccountRef, (snapshot) => {
      const data = snapshot.val();

      if (data === null) {
        alert('Please a bank account first from settings page.');
      } else {
        setShowPayToBankModal(true);
      }
    });
  };
  const handlePayToBankAccount = () => {
    setShowPayToBankModal(true);
  };
  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-6">
          <div className="card p-3">
            <h2>
              R:
              {myShopOrders.reduce((ac, cv) => {
                if (cv.status === 1 || cv.status === 0)
                  return ac + cv.itemPrice * 0.92;
                else return ac + 0;
              }, 0)}{' '}
            </h2>
            <h4>Balance</h4>
          </div>
        </div>
        <div className="col-6">
          <div className="card p-3">
            <h2>
              R:
              {myShopOrders.reduce((ac, cv) => {
                if (cv.status === 0) {
                  return ac + cv.itemPrice * 0.92;
                } else return ac + 0;
              }, 0)}
            </h2>
            <h4>Pending Order</h4>
          </div>
        </div>
      </div>
      <div className="card mt-3 p-3">
        <h2>
          R:{' '}
          {myShopOrders.reduce((ac, cv) => {
            if (cv.status === 1) {
              return ac + cv.itemPrice * 0.92;
            } else return ac + 0;
          }, 0)}
        </h2>
        <h4>Complete orders</h4>
        <h6>Available for shopping or payment</h6>
      </div>
      <div className="d-grid gap-2 mt-3">
        <button
          onClick={() => checkAccountExist()}
          className="btn btn-outline-primary "
          type="button"
        >
          Payout to bank account
        </button>
      </div>
      <div className="mt-3">
        <div>
          <h5>
            Remained Amount After Withdraw:{' '}
            {myShopOrders.reduce((ac, cv) => {
              if (cv.status === 1) {
                return ac + cv.itemPrice * 0.92;
              } else return ac + 0;
            }, 0) - previousPayments.reduce((ac, cv) => ac + cv.amount, 0)}
          </h5>
        </div>
        <h3>Payment History</h3>
        <div className="row mb-5">
          {previousPayments.length &&
            previousPayments.map((p) => (
              <div className="col-lg-6 col-md-12 mb-3" key={p.id}>
                <div className="row odCard p-3 m-1">
                  <div className="col-7">
                    <h4>{p.account.bankName}</h4>
                    <h6>Bank Name</h6>
                    <h4>{p.amount}</h4>
                    <h6>Payment to Bank Amount</h6>
                  </div>
                  <div className="col-5">
                    <h4>{new Date(p.timeStamp).toISOString().slice(0, 10)}</h4>
                    <h6>Time</h6>
                    {p.status === 0 && (
                      <h4 className="odInnerCard0">In-Transit</h4>
                    )}
                    {p.status === 1 && <h4 className="odInnerCard1">Sent</h4>}
                    {p.status === 2 && (
                      <div>
                        <h4 className="odInnerCard2">Hold</h4>{' '}
                        <h6>Please Contact to Admin</h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <PayToBankModal
        showPayToBankModal={showPayToBankModal}
        setShowPayToBankModal={setShowPayToBankModal}
        availableBalance={
          myShopOrders.reduce((ac, cv) => {
            if (cv.status === 1) {
              return ac + cv.itemPrice * 0.92;
            } else return ac + 0;
          }, 0) - previousPayments.reduce((ac, cv) => ac + cv.amount, 0)
        }
      ></PayToBankModal>
    </div>
  );
}

export default MyWallet;
