import {
  getDatabase,
  ref,
  serverTimestamp,
  set,
  update,
} from 'firebase/database';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

function SnipItAlert({
  showSnipItAlert,
  setShowSnipItAlert,
  currentPrice,
  showSnipItPage,
  setShowSnipItPage,
}) {
  const navigate = useNavigate();
  return (
    <>
      <Modal show={showSnipItAlert} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div>
            <h5>
              Want to buy it now? 15% extra from current bid will be charged.
            </h5>
            <h5>
              Current bidding price is R {currentPrice}. Adding 15% the price
              will be R {currentPrice + Math.floor((currentPrice * 15) / 100)}
            </h5>
            <div className="d-flex justify-content-between">
              <button
                onClick={() => {
                  setShowSnipItAlert(false);
                  navigate('/snip-it-now');
                }}
                className="btn btn-warning"
              >
                Continue
              </button>
              <button
                className="btn btn-danger"
                onClick={() => setShowSnipItAlert(!showSnipItAlert)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SnipItAlert;
