import React, { useContext, useEffect, useState } from 'react';
import { ShopTagContext } from '../Context/ShopDetailsContext';
import ShopWise from './OtherPages/ShopWise';
import {
  child,
  get,
  getDatabase,
  onValue,
  query,
  set,
  update,
  serverTimestamp,
} from 'firebase/database';
import { ref } from 'firebase/database';
import follow from '../../assets/images/icon/follow.png';
import unfollow from '../../assets/images/icon/unfollow.png';
import chat from '../../assets/images/icon/chat.png';
import { AuthContext } from '../Context/AuthContext';
import shopImg from '../../assets/images/shop.png';

import { useNavigate } from 'react-router-dom';
import SingleChat from './SingleChat';
import { ChatIdContext } from '../Context/ChatIdConText';

const ShopDetails = () => {
  const [shopTag, setShopTag] = useContext(ShopTagContext);
  const [chatId, setChatId] = useContext(ChatIdContext);
  const { currentUser } = useContext(AuthContext);
  const [shop, setShop] = useState({});
  const [followed, setFollowed] = useState(false);
  const [following, setFollowing] = useState(0);

  // const [showChat, setShowChat] = useState(false);
  // const [chatId, setChatId] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const db = getDatabase();
    onValue(ref(db, 'shop/' + shopTag), async (snapshot) => {
      const data = snapshot.val();
      // console.log(data);
      if (data !== null) {
        setShop(data);

        // getFollowingCount(data.ownerId);
        onValue(ref(db, 'follow/' + data.ownerId), (snapshot) => {
          const data = snapshot.val();
          if (data) {
            console.log(data);
            setFollowing(Object.keys(data).length);
          }
        });
        if (currentUser) {
          const followRef = ref(db, '/follow/' + currentUser.id);
          get(child(followRef, data.shopTag)).then((snapshot) => {
            console.log(snapshot.val());
            if (snapshot.exists()) {
              setFollowed(true);
            } else {
              setFollowed(false);
            }
          });
        }
      }
    });

    //Following count
  }, []);
  const increaseShopFollowingCount = () => {
    const db = getDatabase();
    const shopRef = ref(db, '/shop/' + shop.shopTag);
    set(shopRef, {
      ...shop,
      followCount: shop.followCount + 1,
    });
  };
  const decreaseShopFollowingCount = () => {
    const db = getDatabase();
    const shopRef = ref(db, '/shop/' + shop.shopTag);
    set(shopRef, {
      ...shop,
      followCount: shop.followCount - 1,
    });
  };

  const handleRemoveFromFollow = async () => {
    try {
      const db = getDatabase();
      await set(ref(db, `follow/${currentUser.id}/${shop.shopTag}`), null);
      setFollowed(false);
      decreaseShopFollowingCount();
      // setFollowing(following - 1);
      console.log('Data has been written successfully.');
    } catch (error) {
      console.error('Error writing data: ', error);
    }
  };
  const handleAddToFollow = async (userId, name, email, imageUrl) => {
    try {
      const db = getDatabase();
      await set(
        ref(db, `follow/${currentUser.id}/${shop.shopTag}`),
        shop.shopTag
      );
      setFollowed(true);
      increaseShopFollowingCount();
      // setFollowing(following + 1);
      console.log('Data has been written successfully.');
    } catch (error) {
      console.error('Error writing data: ', error);
    }
  };

  const handleFollow = () => {
    if (!followed) {
      handleAddToFollow();
    } else {
      handleRemoveFromFollow();
    }
  };

  //============================================================================================================
  // -------------------------------------------Handle Chat-------------------------------------------------------
  const handleChat = async () => {
    let id = '';
    const db = getDatabase();
    if (currentUser.id > shop.ownerId) {
      // alert(`${currentUser.id}_${shop.ownerId}`);
      id = currentUser.id + '_' + shop.ownerId;
    } else {
      // alert(`${shop.ownerId}_${currentUser.id}`);
      id = shop.ownerId + '_' + currentUser.id;
    }
    // await alert('working');
    await update(ref(db, 'chat/' + id), {
      buyerId: currentUser.id,
      buyerImage: currentUser.photoUrl,
      buyerName: currentUser.name,
      id: id,
      latestActivity: serverTimestamp(Date.now()),
      sellerId: shop.ownerId,
      sellerImage: shop.imageUrl,
      sellerTag: shop.shopTag,
    });
    await setChatId(id);
    // await alert('working2');
    // await setShowChat(true);
    navigate('/single-chat');
  };

  //=============================================================================================================
  return (
    <div className="container mt-3">
      <h3>Shop Details</h3>
      <div className="row mt-5">
        <div className="col-12 col-md-6" style={{ textAlign: 'center' }}>
          <img
            style={{ width: '130px', height: '130px', borderRadius: '20px' }}
            src={shop.imageUrl ? shop.imageUrl : shopImg}
            alt=""
          />
          <h2>{shop.shopName}</h2>
          <h5>{shop.shopDescription}</h5>
        </div>
        <div className="col-12 col-md-6">
          <div
            style={{ border: '1px solid #4298f5', borderRadius: '20px' }}
            className="p-4"
          >
            <div className=" d-flex justify-content-around">
              <div className="text-center">
                <h3>{shop.followCount}</h3>
                <h4>FOLLOWERS</h4>
              </div>
              <div className="text-center">
                <h3>{following ? following : 0}</h3>
                <h4>FOLLOWING</h4>
              </div>
              <div className="text-center">
                <h3>{shop.soldCount}</h3>
                <h4>SOLD</h4>
              </div>
            </div>
            <div className="d-flex justify-content-around mt-4">
              <span
                style={{ cursor: 'pointer' }}
                onClick={handleFollow}
                className=""
              >
                {!followed ? (
                  <img alt="rH" style={{ width: '50px' }} src={follow} />
                ) : (
                  <img alt="whH" style={{ width: '50px' }} src={unfollow} />
                )}
              </span>
              <img
                onClick={handleChat}
                src={chat}
                alt=""
                style={{ width: '50px', cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>
      </div>
      <h3>ITEMS</h3>
      <hr className="mb-4"></hr>
      <div>
        <ShopWise shopTag={shopTag} />
      </div>
    </div>
  );
};

export default ShopDetails;
