import React, { useEffect, useState, useContext } from 'react';
import {
  getDatabase,
  ref,
  onValue,
  equalTo,
  query,
  orderByChild,
  limitToLast,
  set,
} from 'firebase/database';
import { orderBy } from 'firebase/firestore';
import { ProductDetailsContext } from '../../Context/ProductDetailsContext';
import { useNavigate } from 'react-router-dom';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Pagination } from 'swiper';
import './TopSales.css';
import useWindowDimensions from '../../useWindowDimension';
import ProductCard from '../ProductCard';
function TopSales() {
  const [items, setItems] = useState([]);
  const db = getDatabase();
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useContext(ProductDetailsContext);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    const regItemRef = query(
      ref(db, 'regular_item/'),
      orderByChild('soldCount'),
      limitToLast(10)
    );
    onValue(regItemRef, (snapshot) => {
      const data = snapshot.val();

      let itArr = [];
      if (data !== null) {
        Object.values(data).map((it) => {
          itArr.push(it);
          setItems(itArr);
        });
      }
    });
  }, []);

  const handleOnClick = (item) => {
    setProductDetails(item);
    increaseViewCount(item);
    navigate('/item-details');
  };

  const increaseViewCount = (item) => {
    console.log(item);
    const db = getDatabase();
    const viewRef = ref(db, 'regular_item/' + item.id);
    set(viewRef, {
      ...item,
      viewCount: item.viewCount + 1,
    });
  };
  return (
    <div className="mt-2">
      <h2>
        <span>
          <img
            className="top-sales-icon-control"
            src="https://media.istockphoto.com/id/1352266812/vector/top-sales-stamp-imprint-seal-template-grunge-effect-vector-stock-illustration.jpg?b=1&s=170667a&w=0&k=20&c=fY24isQGkrW_BrHWK2ilhIdqCh_GrGx53SP4NTx-LdA="
            alt="Top Sales"
          />{' '}
          Top Sales
        </span>
      </h2>
      <Swiper
        slidesPerView={width > 1000 ? 6 : 2}
        spaceBetween={20}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        {items &&
          items.map((item) => (
            <SwiperSlide
              onClick={() => handleOnClick(item)}
              key={item.id}
              className={
                item.isPublished
                  ? 'pb-5 swiper-slide-custom'
                  : 'pb-5 swiper-slide-custom d-none'
              }
            >
              <div className="card custom-product-card">
                <img
                  className="card-img-control"
                  src={item.imageUrls[0]}
                  alt={item.itemName}
                />
                <div className="card-body">
                  {/* <h5 className="card-title">{item.itemName}</h5> */}
                  <h5 className="d-inline mr-4">R {item.price}</h5>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default TopSales;
